import { useMemo, useState } from 'react'
import { EnhancedPaper, useAssetsContext } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Flex, Image, Paper, Progress, Stack, Text, Title, Button, Modal, Box, Center } from 'summon-ui/mantine'
import { useAccount, useReadContract } from 'wagmi'
import { graphqlClient } from '@api/config'
import { SocialSharingModal } from '@components'
import { TENANT_CONFIG, TENANT_CONTRACTS, TENANT_NETWORK } from '@config'
import { LEVEL_ABI } from '@constants/Abi/levelAbi'
import LevelUpModal from '@features/home/components/levelUp/LevelUpModal'
import { useGetUserQuery } from '@generated/generates'
import { TutorialIds } from '@hocs/Tutorial/helpers.tutorial'

interface Props {
  onChain: boolean
}

const LevelInfo = ({ onChain = false }: Props) => {
  const t = useTranslation()
  const { address } = useAccount()
  const [openLevel, setOpenLevel] = useState(false)
  const { data: user, isLoading } = useGetUserQuery(graphqlClient())
  const { getTenantImage } = useAssetsContext()
  const [isSocialSharingModalOpen, setIsSocialSharingModalOpen] = useState(false)

  const currentLevelInfo = user?.viewer?.currentLevelInfo
  const totalLevelXp = currentLevelInfo?.totalLevelRequiredXp
  const userXp = user?.viewer?.xp
  const totalLevelQuests = currentLevelInfo?.totalLevelRequiredQuests
  const userLevelQuests = currentLevelInfo?.completedRequiredQuests

  const { data: level, refetch: refetchReadOnchainLevel } = useReadContract({
    address: TENANT_CONTRACTS.level,
    abi: LEVEL_ABI,
    chainId: TENANT_NETWORK.id,
    functionName: 'getMyLevel',
    account: address as `0x${string}`,
    query: {
      enabled: onChain
    }
  })

  const actualLevel = Number(level)
  const levelImage = getTenantImage(`level-${actualLevel}`)

  const isAbleToLevel = actualLevel < (user?.viewer?.level as number)

  const isAbleToShareAvatar = TENANT_CONFIG.features.enableShareAvatar
  const QuestPercentage = Math.min(((userLevelQuests as number) / (totalLevelQuests as number)) * 100, 100)

  const currentXpPercentage = useMemo(
    () =>
      currentLevelInfo?.userLevelXp != null && currentLevelInfo?.minXpToNextLevel != null
        ? Math.min((currentLevelInfo.userLevelXp / currentLevelInfo.minXpToNextLevel) * 100, 100)
        : 0,
    [currentLevelInfo]
  )

  const progressPercentage = Math.min((currentXpPercentage + QuestPercentage) / 2 || 0, 100)

  const noMoreLevels = !totalLevelXp && !isAbleToLevel
  const showLevelUp = () => {
    setOpenLevel(true)
  }

  const handleCloseLevelUpModal = () => {
    refetchReadOnchainLevel()
    setOpenLevel(false)
    isAbleToShareAvatar && setIsSocialSharingModalOpen(true)
  }

  return (
    <Stack p={{ xs: 'md', md: '0' }} gap='sm'>
      <Title data-testid='level-info-title-label' fz='md' visibleFrom='sm'>
        {t('Level Info')}
      </Title>
      {isLoading ? (
        <Paper withBorder h={224} radius='md' bg='gray.3' className='animate-pulse' />
      ) : (
        <Box id={TutorialIds.ProgressCard}>
          <EnhancedPaper withBorder p='md' radius='md' w='100%' withBg>
            <Flex justify='space-between' align='end'>
              <Flex gap='sm'>
                {levelImage ? (
                  <Image data-testid='level-info-level-image' alt='level icon' h='48' src={levelImage} />
                ) : (
                  <Box w={48} h={48} bg='brand' className='rounded-full'>
                    <Center w='100%' h='100%'>
                      <Text c='white' size='40' mt='xs' className='font-gibson'>
                        {actualLevel}
                      </Text>
                    </Center>
                  </Box>
                )}
                <Stack justify='center' gap='0'>
                  <Title data-testid='level-info-actual-level-label' fz='md'>
                    {t('Level')} {actualLevel}
                  </Title>
                  <Text data-testid='level-info-progress-label' fz='sm'>
                    {isAbleToLevel ? 100 : progressPercentage.toFixed(0)}% complete
                  </Text>
                </Stack>
              </Flex>
            </Flex>
            {!noMoreLevels ? (
              <>
                <Flex my='md' direction={{ md: 'column' }}>
                  <Flex className='flex justify-between flex-col md:flex-row w-1/2 gap-2 md:w-full md:pb-4'>
                    <Text>{t('Total XP')}</Text>
                    <Flex gap='sm' align='center' justify='space-between' w={150}>
                      <Progress color='green' value={isAbleToLevel ? 100 : currentXpPercentage} size='md' w={100} />
                      {!isAbleToLevel && (
                        <Text data-testid='level-info-xp-label'>
                          {userXp}/{totalLevelXp}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                  <Flex className='flex justify-between md:pt-5 flex-col md:flex-row w-1/2 gap-2 md:w-full'>
                    <Text>{t('Required Actions')}</Text>
                    <Flex gap='sm' align='center' justify='space-between' w={150}>
                      <Progress color='green' value={isAbleToLevel ? 100 : QuestPercentage} size='md' w={100} />
                      {!isAbleToLevel && (
                        <Text>
                          {userLevelQuests}/{totalLevelQuests}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>

                {!!isAbleToLevel && (
                  <>
                    <Button fullWidth mt='sm' onClick={showLevelUp}>
                      {t('Level Up & Claim')}
                    </Button>
                    <Modal
                      opened={openLevel}
                      onClose={handleCloseLevelUpModal}
                      withCloseButton={false}
                      radius='md'
                      size='xl'
                    >
                      <LevelUpModal open={openLevel} close={handleCloseLevelUpModal} currentLevel={actualLevel} />
                    </Modal>
                  </>
                )}
              </>
            ) : (
              <div className='flex w-full items-center h-[70px]'>{t('New levels coming soon')}...</div>
            )}
          </EnhancedPaper>
        </Box>
      )}
      {isSocialSharingModalOpen && <SocialSharingModal variant='avatar' isOpened asButton={false} />}
    </Stack>
  )
}

export default LevelInfo
