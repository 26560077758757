import { TENANT_API } from '@config'

const TWITTER_AUTH = `${TENANT_API.rest}/integration/twitter/login`

export const handleTwitterAuth = (userId: string) => {
  const page = window?.location?.href
  const twitterAuthUrl = `${TWITTER_AUTH}?userId=${userId}&returnUrl=${page}`

  window.open(twitterAuthUrl, '_self')
}
