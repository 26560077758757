import { useMediaScreen } from 'summon-ui'
import { Flex, Stack } from 'summon-ui/mantine'
import { Breadcrumbs } from '@components'
import StakeInfoStepper from '../components/StakeInfoStepper/StakeInfoStepper'
import StakingCard from '../components/StakingCard/StakingCard'
import stakingCardsData from '../components/StakingCard/data'

const STAKING_CARD_HEIGHT = 500
const STEPS = [
  {
    title: 'Lockup or Stake',
    image: '/images/staking/stepper/red-g7-coin.png',
    description:
      'You can either stake your tokens for minimal Diamond rewards or lock them up for 12 or 24 months for big multipliers on your Diamonds.'
  },
  {
    title: 'Earn Diamonds',
    image: '/images/staking/stepper/blue-g7-diamond.png',
    description:
      'Diamonds are a new in-app currency that will allow you to buy G7 Boxes filled with prizes worth over $1 million in aggregate.'
  },
  {
    title: 'Redeem Diamonds',
    image: '/images/staking/stepper/red-g7-coin-and-diamonds.png',
    description: 'Use Diamonds to purchase G7 Boxes packed with special rewards.'
  }
]

const StakingOptionsView = () => {
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const [stakingAndLookupData, lpStakingData] = stakingCardsData

  return (
    <>
      <Breadcrumbs />
      <Stack gap='xl' mt='lg'>
        <Flex gap='xl' direction={isMobile ? 'column' : 'row'}>
          <StakingCard {...stakingAndLookupData} h={STAKING_CARD_HEIGHT} />
          <StakingCard {...lpStakingData} h={STAKING_CARD_HEIGHT} />
        </Flex>
        <StakeInfoStepper steps={STEPS} />
      </Stack>
    </>
  )
}
export default StakingOptionsView
