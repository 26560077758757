import { TENANT_CONFIG, TENANT_API } from '@config'

export const handleDiscordAuth = (userId: string) => {
  const redirectUri = `${TENANT_API.rest}/integration/discord/login`
  const page = window?.location?.pathname
  const state = JSON.stringify({ page, userId })
  const discordAuthUrl = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${TENANT_CONFIG.discordClientId}&scope=identify%20guilds.members.read&redirect_uri=${encodeURIComponent(redirectUri)}&state=${encodeURIComponent(state)}&prompt=consent&integration_type=0`

  window.open(discordAuthUrl, '_self')
}
