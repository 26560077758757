import { EnhancedPaper, Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, Flex, Title, Text } from 'summon-ui/mantine'
import LeaderboardTable from './LeaderboardTable'

export const TITLE = 'Top Players'

const Leaderboard = () => {
  const t = useTranslation()

  return (
    <Box className='h-full overflow-hidden'>
      <EnhancedPaper p='lg'>
        <Flex align='center' justify='space-between' h='100%'>
          <Title data-testid='history-title-label' order={4}>
            {t(TITLE)}
          </Title>
          <Flex align='center'>
            <Icon c='warning' name='AnnotationAlert' />
            <Text fw='bold' c='warning' hiddenFrom='mini' size='xs'>
              {t('Updates every 30 minutes')}
            </Text>
            <Text fw='bold' c='warning' visibleFrom='mini'>
              {t('Updates every 30 minutes')}
            </Text>
          </Flex>
        </Flex>
      </EnhancedPaper>
      <LeaderboardTable />
    </Box>
  )
}

export default Leaderboard
