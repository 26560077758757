import { useMediaScreen } from 'summon-ui'
import { Box, Flex } from 'summon-ui/mantine'
import { Breadcrumbs } from '@components'
import StakeInfoCardsList from '../components/StakeInfoCards'
import UnstakeTokensForm from '../components/UnstakeTokensForm/UnstakeTokensForm'

const UnstakeView = () => {
  const isTablet = useMediaScreen({ type: 'max', breakpoint: 'laptop' })
  const isMaxTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })

  return (
    <>
      <Breadcrumbs />
      <Flex mt='lg' gap='md' direction={isMaxTablet ? 'column' : 'row'}>
        <Box miw={isTablet ? 400 : '59%'}>
          <UnstakeTokensForm />
        </Box>
        <Box miw={isTablet ? 'auto' : '40%'}>
          <StakeInfoCardsList />
        </Box>
      </Flex>
    </>
  )
}
export default UnstakeView
