import { useEffect } from 'react'
import { showNotification } from 'summon-ui'
import { useOs } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'

export const DESCRIPTION =
  "This platform is not yet optimized for mobile, we'd highly recommend using the site on a desktop browser for the best experience."

const useDisplayMobileSupportWarning = () => {
  const os = useOs()

  useEffect(() => {
    if ((os === 'android' || os === 'ios') && TENANT_CONFIG.features.warningMobileSupport) {
      showNotification({
        autoClose: 50000,
        message: DESCRIPTION,
        variant: 'alert'
      })
    }
  }, [os])

  return null
}

export default useDisplayMobileSupportWarning
