import { useState } from 'react'
import { useMediaScreen } from 'summon-ui'
import { Box, Carousel, Flex } from 'summon-ui/mantine'
import type { BoxSlot as SlotBoxType } from '@generated/generates'
import type { SlotBoxes as SlotBoxesType } from '../../types'
import SlotBox from './SlotBox'
import { getSlotBoxUIDisplayVariant } from './helpers'

interface SlotBoxesProps {
  slotBoxes: SlotBoxesType
  selectedSlotIndex: number | null
  onSelectSlotBox?: (index: number) => void // This defines if selection feature is active
  isPlaceholder?: boolean
}

const SlotBoxes = ({ slotBoxes, selectedSlotIndex, isPlaceholder = false, onSelectSlotBox }: SlotBoxesProps) => {
  const isMiniTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  // This allows us to re-render the slot boxes, and so update in the front unlocked to ready to claim
  // The magic here is that other slot boxes are NOT re-rendered. So if an open box or a purchase box flow are on going
  // the fact that a locked box changes to locked anymore, will not affect other boxes rendering
  const [, unlockedBoxesSinceRefetch] = useState(0)
  const isSelectionEnabled = !!onSelectSlotBox

  const renderSlotBox = (slotBox: SlotBoxType | null, slotBoxIndex: number) => {
    const displayVariant = slotBox ? getSlotBoxUIDisplayVariant(slotBox) : 'coming-soon'

    return slotBox ? (
      <SlotBox
        slotBox={slotBox}
        isPlaceholder={isPlaceholder}
        displayVariant={displayVariant}
        isSelected={slotBoxIndex === selectedSlotIndex}
        canBeHovered={isSelectionEnabled}
        {...(onSelectSlotBox
          ? {
              onSelect: () => {
                onSelectSlotBox(slotBoxIndex)
              }
            }
          : {})}
        {...(displayVariant === 'unlocking'
          ? {
              onUnlockAtOver: () => {
                unlockedBoxesSinceRefetch((prev) => prev + 1)
              }
            }
          : {})}
      />
    ) : (
      <SlotBox
        slotBox={null}
        isPlaceholder={isPlaceholder}
        isSelected={false}
        displayVariant={displayVariant}
        canBeHovered={isSelectionEnabled}
      />
    )
  }

  const SlotBoxElements = slotBoxes.map((slotBox, index) => {
    const id = slotBox?.boxDetails ? slotBox.boxDetails.id : slotBox?.id
    const key = slotBox ? id : `coming-soon-${index}`

    // Rendering Carousel Slide if on tablet, otherwise return Box layout
    return isMiniTablet ? (
      <Carousel.Slide key={key} p='xs'>
        {renderSlotBox(slotBox, index)}
      </Carousel.Slide>
    ) : (
      <Box key={key}>{renderSlotBox(slotBox, index)}</Box>
    )
  })

  return isMiniTablet ? (
    <Box w='100%'>
      <Carousel
        controlsOffset='xs'
        controlSize={14}
        loop
        dragFree
        slideSize={{ xs: '35%' }}
        slideGap='xs'
        align='start'
        slidesToScroll={1}
      >
        {SlotBoxElements}
      </Carousel>
    </Box>
  ) : (
    <Flex gap='sm'>{SlotBoxElements}</Flex>
  )
}

export default SlotBoxes
