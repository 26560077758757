import { type Chain } from 'viem'
import { arbitrum, mainnet, mantle, zksync, zksyncSepoliaTestnet, mantleTestnet, linea, base } from 'wagmi/chains'
import type { TenantType } from '@config/types'
import { GAME7_CHAIN, GAME7_TESTNET_CHAIN } from '@constants/chains'
import { isDev } from '@utils/checkEnvironment'
import { Environment, Hosts, Tenant } from './enum'
import Game7Config from './game7'

// eslint-disable @typescript-eslint/no-non-null-assertion

interface TenantConfig {
  tenant: Tenant
  env: Environment
  network: Chain
  supportedChains: readonly [Chain, ...Chain[]]
}
interface HostDictionary {
  [key: string]: TenantConfig
}

const TENANTS_DICTIONARY: HostDictionary = {
  [Hosts.G7]: {
    tenant: Tenant.G7,
    env: Environment.Production,
    network: zksync,
    supportedChains: [mantle, zksync, arbitrum, mainnet, linea, base]
  },
  [Hosts.G7Staging]: {
    tenant: Tenant.G7,
    env: Environment.Staging,
    network: GAME7_CHAIN,
    supportedChains: [GAME7_CHAIN, mantle, zksync, arbitrum, mainnet, linea, base]
  },
  [Hosts.G7Develop]: {
    tenant: Tenant.G7,
    env: Environment.Develop,
    network: GAME7_TESTNET_CHAIN,
    supportedChains: [
      GAME7_TESTNET_CHAIN,
      zksyncSepoliaTestnet,
      mantleTestnet,
      mantle,
      zksync,
      arbitrum,
      mainnet,
      linea,
      base
    ]
  }
}

export const TENANT_CONFIG_OPTIONS: TenantType = {
  [Tenant.G7]: Game7Config
}

// This is to set a default tenant for developers
const DEFAULT_TENANT: TenantConfig = TENANTS_DICTIONARY[Hosts.G7Develop]

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const devHost =
  ((window.localStorage.getItem('tenant') && JSON.parse(window.localStorage.getItem('tenant') as string)) as string) ||
  null
const host = window.location.host

export const getTenantHost = () => {
  // if we are in dev mode we return the host for the current tenant
  return isDev ? devHost : host
}

// select tenant
export const getTenantByHost = (): TenantConfig => {
  return TENANTS_DICTIONARY[host] || (devHost && TENANTS_DICTIONARY[devHost]) || DEFAULT_TENANT
}

class MULTI_TENANT_CONFIG {
  tenant: TenantType[Tenant]
  constructor(name: Tenant) {
    this.tenant = TENANT_CONFIG_OPTIONS[name]
  }
}

const TENANT = new MULTI_TENANT_CONFIG(getTenantByHost().tenant).tenant

export const TENANT_CONFIG = TENANT.config
export const TENANT_MARKETPLACE = TENANT.marketplace
export const TENANT_ONBOARDING = TENANT.onboarding
export const TENANT_CONTRACTS = TENANT.contracts
export const TENANT_API = TENANT_CONFIG.api[getTenantByHost().env]
export const TENANT_NETWORK = getTenantByHost().network
export const TENANT_SUPPORTED_CHAINS = getTenantByHost().supportedChains
