import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Loader, Center } from 'summon-ui/mantine'
import { useViewer, useUpdateViewerSettings } from '@hooks'
import type { UpdateUserSettingsData } from '@hooks/viewer/useUpdateViewerSettings'
import { SettingItem } from '../components/SettingItem'
import { SettingsHeader } from '../components/SettingsHeader'
import EmailForm, { type EmailFormValues } from './EmailForm'
import NameForm, { type NameFormValues } from './NameForm'

// Type guard to check if data is NameFormValues
const isNameFormValues = (data: NameFormValues | EmailFormValues): data is NameFormValues => {
  return 'firstName' in data || 'lastName' in data || 'username' in data
}

const ProfileView = () => {
  const t = useTranslation()
  const { viewer, refetch } = useViewer()
  const [isUpdatingName, setIsUpdatingName] = useState(false)
  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false)
  const { updateViewerSettings } = useUpdateViewerSettings()

  const handleSubmit = async (data: NameFormValues | EmailFormValues) => {
    let updateData: UpdateUserSettingsData = {}

    if (isNameFormValues(data)) {
      updateData = {
        ...(data.firstName && { firstName: data.firstName }),
        ...(data.lastName && { lastName: data.lastName }),
        ...(data.username && { username: data.username })
      }
      setIsUpdatingName(true)
    } else {
      updateData = {
        ...(data.emailAddress && { emailAddress: data.emailAddress })
      }
      setIsUpdatingEmail(true)
    }

    await updateViewerSettings(updateData, {
      onSuccess: () => {
        refetch()
        showNotification({
          variant: 'success',
          message: 'Profile updated successfully'
        })
      },
      onError: () => {
        showNotification({
          variant: 'danger',
          message: 'Unable to update profile'
        })
      },
      onFinally: () => {
        setIsUpdatingName(false)
        setIsUpdatingEmail(false)
      }
    })
  }

  return (
    <>
      <SettingsHeader title={t('User profile')} subTitle={t('Update your profile details')} />
      {viewer ? (
        <>
          <SettingItem name='Name' description={t('Update your profile name')}>
            <NameForm
              firstName={viewer.firstName || ''}
              lastName={viewer.lastName || ''}
              username={viewer.username || ''}
              onSubmit={handleSubmit}
              isUpdating={isUpdatingName}
            />
          </SettingItem>
          <SettingItem name='Email' description={t('Update your email')}>
            <EmailForm emailAddress={viewer.emailAddress || ''} onSubmit={handleSubmit} isUpdating={isUpdatingEmail} />
          </SettingItem>
        </>
      ) : (
        <Center className='h-32'>
          <Loader type='bars' />
        </Center>
      )}
    </>
  )
}

export default ProfileView
