import type { ReactElement } from 'react'
import { Button } from 'summon-ui/mantine'

interface Props {
  icon?: ReactElement
  url: string
}

const ShareButton = ({ icon, url }: Props) => {
  return (
    <Button fullWidth className='w-full' component='a' target='_blank' href={url} rightSection={icon}>
      Share
    </Button>
  )
}

export default ShareButton
