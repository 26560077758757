import { Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from '@components'
import DiamondsView from './views/Diamonds.view'

export const DiamondsViews = () => {
  return (
    <>
      <Routes>
        <Route path='/' index element={<DiamondsView />} errorElement={<ErrorBoundary />} />
      </Routes>
    </>
  )
}
