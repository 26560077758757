import type { Reward } from '@generated/generates'
import { THE_LEGENDARY_LOOTBOX } from './constants'

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000

const roundOdds = (odds: number) => parseFloat(odds.toFixed(8)).toString()

const formatOdds = (odds: number | string): string => {
  const oddsNumber = parseFloat(odds.toString())
  if (oddsNumber < 0.001) return '< 0.001'
  return `~ ${oddsNumber.toFixed(3)}`
}

const isXDaysBeforeDate = (xDays: number, date: Date) => {
  return new Date(new Date(date).getTime() - xDays * ONE_DAY_IN_MS)
}

const weAreBetweenDates = (startDate: string, endDate: string) => {
  const now = new Date()
  return new Date(startDate) <= now && new Date(endDate) > now
}

// 1 - ((totalMultiplier - multiplier) / totalMultiplier) ^ maxUnitDistributionLimit
const calculateRewardOdds = (maxUnitDistributionLimit: number, totalMultiplier: number, multiplier: number): number => {
  return 1 - ((totalMultiplier - multiplier) / totalMultiplier) ** maxUnitDistributionLimit
}

const calculateTotalRewardsUnits = (rewards: Reward[]) => {
  return rewards.reduce((sum, reward) => {
    return sum + (reward.rewardIntegration?.constraints?.maxUnitDistributionLimit || 0)
  }, 0)
}

const calculateAllRewardsOdds = (
  rewards: Reward[],
  totalMultiplier: number,
  userMultiplier: number | null
): Record<string, number> => {
  if (rewards.length === 0) {
    return {} // Handle the empty array case
  }
  // Use 1 as default value for userMultiplier if it's null
  userMultiplier = userMultiplier || 1
  // Calculate odds for all rewards
  const odds: Record<string, number> = {}

  rewards.forEach((reward) => {
    const oddsForReward = calculateRewardOdds(
      reward.rewardIntegration?.constraints?.maxUnitDistributionLimit || 0,
      totalMultiplier,
      userMultiplier
    )
    odds[reward.id] = oddsForReward
  })

  return odds
}

const calculateLootdropDuration = (startTime: string, endTime: string): string => {
  const start = new Date(startTime)
  const end = new Date(endTime)
  const msInDay = 1000 * 60 * 60 * 24

  const diffInMs = end.getTime() - start.getTime()
  const diffInDays = Math.floor(diffInMs / msInDay)

  if (diffInDays >= 7) {
    const weeks = Math.floor(diffInDays / 7)
    const days = diffInDays % 7
    if (days === 0) {
      return weeks === 1 ? `${weeks} week` : `${weeks} weeks`
    } else {
      const weekStr = weeks === 1 ? `${weeks} week` : `${weeks} weeks`
      const dayStr = days === 1 ? `${days} day` : `${days} days`
      return `${weekStr} and ${dayStr}`
    }
  } else {
    return diffInDays === 1 ? `${diffInDays} day` : `${diffInDays} days`
  }
}

const roundProbabilities = (probabilities: number[]): string[] => {
  // Round each probability to 10 decimal places
  const roundedProbs = probabilities.map((prob) => parseFloat(roundOdds(prob)))

  // Adjust the last element to ensure the sum is exactly 1
  const sumProbs = roundedProbs.reduce((acc, prob) => acc + prob, 0)
  const difference = 1 - sumProbs

  // Add the difference to the last element and round it to 10 decimal places again
  roundedProbs[roundedProbs.length - 1] = parseFloat((roundedProbs[roundedProbs.length - 1] + difference).toFixed(10))

  // Format all numbers to 10 decimal places as strings
  const formattedProbs = roundedProbs.map((prob) => roundOdds(prob))

  return formattedProbs
}

const roundOddsObject = (oddsObject: { [key: string]: number }): { [key: string]: number | string } => {
  // Extract the probabilities
  const probabilities = Object.values(oddsObject)

  // Round the probabilities
  const roundedProbs = roundProbabilities(probabilities)

  // Create a new object with the rounded probabilities, replacing 0 with '≈0'
  const roundedOddsObject: { [key: string]: number | string } = {}
  Object.keys(oddsObject).forEach((key, index) => {
    roundedOddsObject[key] = roundedProbs[index] === '0' ? '≈0' : roundedProbs[index]
  })

  return roundedOddsObject
}

const findRewardByName = (rewards: Reward[], name: string) => {
  return rewards.find((reward) => reward.title === name)
}

const calculateLegendaryOdds = (rewards: Reward[], totalMultiplier: number, multiplier: number) => {
  const legendaryReward = findRewardByName(rewards, THE_LEGENDARY_LOOTBOX)
  const legendaryOdds =
    legendaryReward &&
    calculateRewardOdds(
      legendaryReward?.rewardIntegration?.constraints?.maxUnitDistributionLimit || 0,
      totalMultiplier,
      multiplier
    )

  return legendaryOdds ?? null
}

export {
  calculateAllRewardsOdds,
  calculateRewardOdds,
  calculateLootdropDuration,
  calculateTotalRewardsUnits,
  isXDaysBeforeDate,
  roundProbabilities,
  roundOddsObject,
  weAreBetweenDates,
  roundOdds,
  findRewardByName,
  calculateLegendaryOdds,
  formatOdds
}
