import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { G7Icon, Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Card, Flex, Space, Stack, Text, NumberInput, Title, useDisclosure, Box } from 'summon-ui/mantine'
import { ConnectButton } from '@components'
import { useViewer } from '@hooks'
import { useUserTokensBalance } from '@hooks/balances'
import UnstakingSuccessModal from './UnstakingSuccessfulModal'
import useUnstake from './useUnstake'

const UnstakeTokensForm = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { isAuthenticated } = useViewer()
  const { unstake, isUnstaking } = useUnstake()
  const [stakeAmount, setStakeAmount] = useState<number | undefined>()
  const { balance, isLoading } = useUserTokensBalance()
  const [unstakingSuccessModalOpened, { open: unstakingSuccessModalOpen }] = useDisclosure(false)

  const handleUnstake = () => {
    unstake({ onSuccess: unstakingSuccessModalOpen })
  }

  const handleCloseUnstakeSuccessfulModal = () => {
    navigate('/staking')
  }

  return (
    <>
      <Card withBorder radius='md' bg='black' p='30'>
        <Stack ta='center' gap='xs'>
          <Title>{t('Unstake Tokens')}</Title>
          <Text>{t('Unstake your G7 tokens and reclaim your credits instantly.')}</Text>
        </Stack>
        <Space h='lg' />
        <Stack gap='lg'>
          <Stack gap='4'>
            <Flex justify='space-between' align='center'>
              <Text size='sm'>{t('Tokens to unstake')}</Text>
              {isAuthenticated && (
                <Flex gap='xs'>
                  <Text size='sm'>{t('Balance')}:</Text>
                  {isLoading ? <Spinner size='xs' /> : `${balance} G7`}
                </Flex>
              )}
            </Flex>
            <NumberInput
              size='lg'
              hideControls
              labelProps={{ mb: 'xs' }}
              leftSection={<G7Icon name='g7-token-mark-4' pfx='fak' color='red' size='xl' />}
              value={stakeAmount}
              onChange={(value) => setStakeAmount(Number(value))}
              rightSection={
                <Box mr='md'>
                  {balance && balance > 0 ? (
                    <Button variant='transparent' fw='bold' p='0' c='red.5' onClick={() => setStakeAmount(balance)}>
                      MAX
                    </Button>
                  ) : null}
                </Box>
              }
              placeholder={t('Token amount')}
            />
          </Stack>
          {isAuthenticated && stakeAmount ? (
            <Button
              mt='lg'
              fullWidth
              disabled={stakeAmount > 0 || isUnstaking}
              onClick={handleUnstake}
              loading={isUnstaking}
            >
              {t('Unstake')}
            </Button>
          ) : (
            <ConnectButton />
          )}
        </Stack>
      </Card>
      <UnstakingSuccessModal open={unstakingSuccessModalOpened} onClose={handleCloseUnstakeSuccessfulModal} />
    </>
  )
}

export default UnstakeTokensForm
