import { Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from '@components'
import LeaderboardView from './views/Leaderboard.view'

export const LeaderboardViews = () => {
  return (
    <Routes>
      <Route path='/' element={<LeaderboardView />} errorElement={<ErrorBoundary />} />
    </Routes>
  )
}
