import { useMutation } from '@tanstack/react-query'
import { shopifyClient } from '@api/modules/shopify'

async function createCartMutation() {
  const MUTATION = `
  mutation CreateCart {
    cartCreate {
      cart {
        checkoutUrl
        id
      }
    }
  }`
  const data = (await shopifyClient({
    query: MUTATION
  })) as { cartCreate: { cart: { checkoutUrl: string; id: string } } }

  return data.cartCreate.cart
}

export const useCreateCartMutation = () => {
  return useMutation({ mutationKey: ['cart'], mutationFn: createCartMutation })
}
