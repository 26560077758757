import { useState } from 'react'
import { useMediaScreen } from 'summon-ui'
import { Box, useWindowEvent } from 'summon-ui/mantine'
import { LeaderboardViews } from '@features/leaderboard'

const LEADERBOARD_PAGE_TOP_SPACE = 50
const LEADERBOARD_PAGE_TOP_SPACE_TABLET = 100

const LeaderboardPage = () => {
  const isMaxTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleResize = () => {
    setWindowHeight(window.innerHeight)
  }
  useWindowEvent('resize', handleResize)

  return (
    <Box
      h={windowHeight - 80 - (isMaxTablet ? LEADERBOARD_PAGE_TOP_SPACE_TABLET : LEADERBOARD_PAGE_TOP_SPACE)}
      className='overflow-hidden'
    >
      <LeaderboardViews />
    </Box>
  )
}

export default LeaderboardPage
