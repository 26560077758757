import { Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from '@components'
import AvatarView from './views/avatar.view'

export const HomeViews = () => {
  return (
    <Routes>
      <Route path='/:id?' index element={<AvatarView />} errorElement={<ErrorBoundary />} />
    </Routes>
  )
}
