import type { DiamondsInfoData } from './types'

const infoCardsData: DiamondsInfoData[] = [
  {
    title: 'Diamond Pouch',
    description:
      'Grow your pouch by staking more Game7 and LP tokens. Some say diamonds are forever, so don’t spend them all at once.',
    bgImgUrl: '/images/staking/diamond-pouch.png'
  },
  {
    title: 'Diamond Market',
    description: 'Buy LootBoxes with your Diamonds. You never know what you may win. Over $1mm in prizes await.',
    bgImgUrl: '/images/staking/diamond-marketplace.png'
  }
]

export default infoCardsData
