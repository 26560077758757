import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  Modal,
  Button,
  Flex,
  Box,
  BackgroundImage,
  Text,
  Stack,
  Paper,
  Center,
  NumberFormatter,
  alpha
} from 'summon-ui/mantine'
import { BorderedTitle } from '@components'
import InfoCard from './InfoCard'
import learnDiamondsData from './data'

type Props = {
  totalDiamondsEarned: number
  dailyDiamonds: number
  opened?: boolean
  onClose: () => void
}

const LearnDiamondsModal = ({ totalDiamondsEarned, dailyDiamonds, opened = false, onClose }: Props) => {
  const t = useTranslation()
  const isSmallerThanMiniTablet = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const [diamondPouch, diamondMarket] = learnDiamondsData

  return (
    <Modal
      padding={0}
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      radius='md'
      size={isSmallerThanMiniTablet ? 'md' : 'xl'}
    >
      <Stack bg='black' gap='lg'>
        <BackgroundImage src='/images/staking/diamonds-rain.png' w='100%'>
          <Stack h='100%' mt={20}>
            <Center>
              <BorderedTitle ta='center' w={450} size={36} borderColor='skyblue.6' bordeWidth={2}>
                {t('Welcome to the world of diamonds!')}
              </BorderedTitle>
            </Center>
            <Center>
              <Text ta='center' w={isSmallerThanMiniTablet ? '90%' : '60%'}>
                {t('Diamonds are an off-chain, in-app rewards currency that you can use to buy Loot Boxes.')}
              </Text>
            </Center>
          </Stack>
        </BackgroundImage>
        <Stack px={isSmallerThanMiniTablet ? 'xl' : 40} gap='lg' mb={40}>
          <Center>
            <Paper
              withBorder
              py='xs'
              px='md'
              radius='md'
              bg={alpha('var(--mantine-color-skyblue-8)', 0.3)}
              style={{ border: '1px solid var(--mantine-color-skyblue-8' }}
              {...(isSmallerThanMiniTablet ? { w: '100%' } : {})}
            >
              <Flex gap='xs' direction={isSmallerThanMiniTablet ? 'column' : 'row'}>
                <Flex ta='center' {...(isSmallerThanMiniTablet ? { justify: 'center' } : {})}>
                  <Text tt='capitalize'>{t('Total diamonds earned')}:</Text>
                  &nbsp;
                  <Text fw='bold'>
                    <NumberFormatter thousandSeparator value={totalDiamondsEarned} />
                  </Text>
                </Flex>

                <Flex ta='center' {...(isSmallerThanMiniTablet ? { justify: 'center' } : {})}>
                  <Text tt='capitalize'>{t('Daily diamonds')}:</Text>
                  &nbsp;
                  <Text fw='bold'>
                    <NumberFormatter thousandSeparator value={dailyDiamonds} />
                  </Text>
                </Flex>
              </Flex>
            </Paper>
          </Center>
          <Flex gap='md' direction={isSmallerThanMiniTablet ? 'column' : 'row'}>
            <Box w='100%'>
              <InfoCard {...diamondPouch} />
            </Box>
            <Box w='100%'>
              <InfoCard {...diamondMarket} />
            </Box>
          </Flex>
          <Button variant='outline' bg='transparent' onClick={onClose} fullWidth>
            {t('Close')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default LearnDiamondsModal
