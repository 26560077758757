import { Text, Stack, Image } from 'summon-ui/mantine'

const TITLE = 'Thank you Citizen!'
const DESCRIPTION =
  'You just helped Game7’s community take an important step towards decentralization. Go ahead and share with your friends. Our gaming nation is ready to grow.'

const ShareTweet = () => {
  return (
    <Stack gap='xs'>
      <Image src='/images/share-post.jpg' />
      <Stack gap='xs' className='p-4'>
        <Text ta='center' fw='bold' size='xl'>
          {TITLE}
        </Text>
        <Text ta='center'>{DESCRIPTION}</Text>
      </Stack>
    </Stack>
  )
}

ShareTweet.displayName = 'ShareTweet'
export default ShareTweet
