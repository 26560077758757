import { useState } from 'react'
import { G7Icon, Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  Modal,
  Button,
  Title,
  Text,
  Stack,
  Group,
  Checkbox,
  Image,
  Card as MantineCard,
  NumberFormatter,
  useDisclosure,
  Paper,
  BackgroundImage
} from 'summon-ui/mantine'
import { useCreditsConversion, useUserCreditsBalance } from '@hooks/balances'
import useConvertCredits from '../../hooks/useConvertCredits'
import ConversionSuccessModal from '../ConversionSuccessModal'
import styles from './claimG7Modal.module.css'

interface Props {
  open: boolean
  onClose: () => void
}

const ClaimG7Modal = ({ open, onClose }: Props) => {
  const t = useTranslation()
  const [checked, setChecked] = useState(false)
  const { balance: userCreditsBalance } = useUserCreditsBalance()
  const [stakingSuccessModalOpened, { close: stakingSuccessModalClose, open: stakingSuccessModalOpen }] =
    useDisclosure(false)

  const handleSuccessConversion = () => {
    onClose()
    stakingSuccessModalOpen()
  }

  const { convertCreditsMutation, isConvertCreditsMutationLoading } = useConvertCredits({
    onConversionSuccess: handleSuccessConversion
  })

  const { tokensReceive, isTokensReceiveLoading } = useCreditsConversion()

  // TODO: Add the amount as a new argument here when we have real data
  const renderAdditionalData = (title: string) => (
    <Group justify='space-between'>
      <Text>{t(title)}</Text>
      <Text c='error.3' fw='900'>
        <Group gap='4'>
          <G7Icon name={'g7-token-mark-3'} pfx={'fak'} color={'error.3'} />
          100 $G7
        </Group>
      </Text>
    </Group>
  )

  return (
    <>
      <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md' size='480'>
        <Stack p='xs'>
          <Stack gap='xs' align='center'>
            <Title order={1}>{t('Claim $G7 tokens')}</Title>
            <BackgroundImage src='/images/staking/conversion-bg.jpg' radius='md'>
              <Stack justify='center' align='center' py='lg' gap='xs'>
                <Image src='/images/staking/claim-g7-token.png' alt='claim g7 token' w={157} />
                <Text ta='center'>{t('Tokens to receive from credits')}</Text>
                {isTokensReceiveLoading ? (
                  <Spinner size='sm' />
                ) : (
                  <Title order={2}>
                    <NumberFormatter thousandSeparator value={tokensReceive || ''} /> $G7
                  </Title>
                )}
              </Stack>
            </BackgroundImage>
          </Stack>
          <Text size='lg' fw='600' mb='-xs'>
            {t('Additional Bonuses')}
          </Text>
          <MantineCard withBorder radius='md' className={styles.dataBorderGradient}>
            <Stack gap='4'>
              {renderAdditionalData('Leaderboard Climber')}
              {renderAdditionalData('Question Completionist')}
              {renderAdditionalData('LootDrop Loyalist')}
            </Stack>
          </MantineCard>

          <Stack gap='lg' align='center'>
            <Paper withBorder radius='md' px='sm' pb='lg' pt='xs'>
              <Checkbox
                data-autofocus
                label={
                  <Text>
                    {t(
                      'By clicking Claim, I agree to convert my {creditsToConvert} credits to {tokensReceive} $G7 tokens, including any bonuses. This is a one-time, non-reversible action.',
                      {
                        creditsToConvert: userCreditsBalance || '~',
                        tokensReceive: tokensReceive || '~'
                      }
                    )}
                  </Text>
                }
                checked={checked}
                onChange={(event) => setChecked(event.currentTarget.checked)}
                size='md'
                mt='md'
              />
            </Paper>
          </Stack>
          <Group justify='space-between' wrap='nowrap' pt='xs'>
            <Button
              variant='outline'
              onClick={onClose}
              fullWidth
              loading={isConvertCreditsMutationLoading}
              disabled={isConvertCreditsMutationLoading}
            >
              {t('Close')}
            </Button>
            <Button
              fullWidth
              onClick={convertCreditsMutation}
              loading={isConvertCreditsMutationLoading}
              disabled={!checked || isConvertCreditsMutationLoading}
            >
              {t('Claim my $G7')}
            </Button>
          </Group>
        </Stack>
      </Modal>
      <ConversionSuccessModal open={stakingSuccessModalOpened} onClose={stakingSuccessModalClose} />
    </>
  )
}

export default ClaimG7Modal
