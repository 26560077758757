import { useGqlQuery } from '@api/gql/gqlServices'
import { GetRewardsRedeemedDocument, type GetRewardsRedeemedQuery, type RewardRedeemed } from '@generated/generates'
import useViewer from '@hooks/viewer/useViewer'

interface Props {
  redeemed?: boolean
  active?: boolean
}

const useRedeemables = ({ redeemed, active }: Props = {}) => {
  const { viewer } = useViewer()
  // Dynamically create the input object, excluding undefined properties
  const input: { redeemed?: boolean; active?: boolean } = {}

  if (redeemed !== undefined) {
    input.redeemed = redeemed
  }

  if (active !== undefined) {
    input.active = active
  }

  const {
    data: rewardsRedeemData,
    isPending: isRewardsRedeemablesLoading,
    status: rewardsRedeemablesStatus
  } = useGqlQuery<GetRewardsRedeemedQuery>(
    GetRewardsRedeemedDocument,
    {
      input // Always pass input, which will only include defined properties
    },
    { enabled: !!viewer }
  )

  const redeemablesArray =
    rewardsRedeemablesStatus === 'success' ? (rewardsRedeemData?.rewardsRedeemed as RewardRedeemed[]) : null

  return {
    rewardsRedeemables: redeemablesArray,
    isRewardsRedeemablesLoading,
    rewardsRedeemablesStatus
  }
}

export default useRedeemables
