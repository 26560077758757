import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { Countdown, Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Stack, Title, Text, Flex, BackgroundImage, Badge, Paper, Overlay } from 'summon-ui/mantine'
import useFeaturedCampaign from '../hooks/useFeaturedCampaign'
import styles from './featuredCampaign.module.css'

interface Props {
  displayCountdown?: boolean
}

const FeaturedCampaign = ({ displayCountdown = true }: Props) => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { campaign, isLoading } = useFeaturedCampaign()

  if (!campaign) return null

  return (
    <Stack gap='sm' p={{ xs: 'md', md: '0' }}>
      <Flex w='100%' justify='space-between' align='center'>
        <Title fz='md' visibleFrom='sm'>
          {/* TODO: Not adding translations for hardcoded solution*/}
          Featured Campaigns
        </Title>
        {displayCountdown && (
          <Badge variant='outline' c='dark.1' className='border-green' py='xs'>
            <Countdown
              from={new Date(campaign.startsOn)}
              render={(humanFormattedCountdown) => (
                <Text size='xs' fw='bold'>
                  {humanFormattedCountdown}
                </Text>
              )}
            />
          </Badge>
        )}
      </Flex>

      <Paper className={clsx(styles.card, 'overflow-hidden relative')} radius='md'>
        <BackgroundImage src={campaign.imageUrl} h={170}>
          <Overlay zIndex={0} pos='absolute' h='100%' />

          {isLoading ? (
            <Spinner />
          ) : (
            <Flex align='center' justify='space-between' gap='sm' pos='absolute' w='100%' p='md' py='xl'>
              <Stack gap='lg' w='100%'>
                <Stack gap='sm'>
                  <Title tt='uppercase' fz='md' lineClamp={1}>
                    {campaign.title}
                  </Title>
                  <Flex gap='xs'>{campaign.Badges}</Flex>
                </Stack>
                <Button fullWidth onClick={() => navigate(`/lootdrop/${campaign.id}`)}>
                  {t('Get Started')}
                </Button>
              </Stack>
            </Flex>
          )}
        </BackgroundImage>
      </Paper>
    </Stack>
  )
}

export default FeaturedCampaign
