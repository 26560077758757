import { useTranslation } from 'summon-ui/intl'
import { Modal, Stack, Text, TextInput, Group, Button, useForm } from 'summon-ui/mantine'
import { useViewer, useUpdateTelegramId } from '@hooks'

interface TelegramFormValues {
  telegramId?: string
}

interface Props {
  isOpen: boolean
  onSuccess: () => void
  onFinish: () => void
}

const TelegramFormModal = ({ isOpen, onSuccess, onFinish }: Props) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const { updateTelegramId, isUpdatingTelegramId } = useUpdateTelegramId()

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<TelegramFormValues>({
    initialValues: { telegramId: viewer?.customData?.telegram?.id || '' }
  })

  const handleSubmit = async (data: typeof values) => {
    if (data?.telegramId) {
      await updateTelegramId({ id: data.telegramId, onSuccess })
      onFinish()
    }
  }

  return (
    <Modal title={t('Enter Telegram ID')} opened={isOpen} onClose={onFinish} maw={430}>
      <form onSubmit={onSubmitForm(handleSubmit)}>
        <Stack>
          <Text>{t('Save your Telegram ID to complete actions on Telegram-based games')}</Text>
          <TextInput
            {...getInputProps('telegramId')}
            label={t('Enter Telegram ID')}
            placeholder={t('Enter Telegram ID')}
          />
          <Group justify='space-between' w='100%' mt='sm'>
            <Button onClick={onFinish} variant='outline' miw={115}>
              {t('Cancel')}
            </Button>
            <Button type='submit' loading={isUpdatingTelegramId} miw={115}>
              {t('Submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default TelegramFormModal
