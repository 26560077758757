import { useReadContract } from 'wagmi'
import { useGqlQuery } from '@api/gql/gqlServices'
import { TENANT_CONTRACTS, TENANT_NETWORK } from '@config'
import { ITEMS_BOUND_ABI } from '@constants/Abi/itemsBoundAbi'
import { GetUserCreditsConversionDataDocument, type GetUserCreditsConversionDataQuery } from '@generated/generates'
import { useViewer } from '@hooks'

const useCreditsConversion = () => {
  const { viewer } = useViewer()

  const { data: userCreditsConversionData, isPending } = useGqlQuery<GetUserCreditsConversionDataQuery>(
    GetUserCreditsConversionDataDocument,
    {
      enabled: !!viewer
    }
  )

  const { data: accessTokenBalance } = useReadContract({
    address: TENANT_CONTRACTS.accessToken,
    abi: ITEMS_BOUND_ABI,
    chainId: TENANT_NETWORK.id,
    functionName: 'balanceOf',
    args: [viewer?.walletAddress, viewer?.tokenId],
    query: { enabled: !!userCreditsConversionData }
  })

  // Flow: https://www.figma.com/board/vsD4DuiiJl9vB7qvAeAXXD/G7-Claim?node-id=0-1&node-type=canvas&t=RjwAvWHDc1dZhSoh-0
  const hasTokensToClaim =
    userCreditsConversionData?.getUserCreditsConversionData?.tokensAmount &&
    Number(userCreditsConversionData?.getUserCreditsConversionData?.tokensAmount) > 0
  const tokensAlreadyClaimed =
    userCreditsConversionData?.getUserCreditsConversionData?.accessTokenCreatedAt === null ||
    Number(accessTokenBalance) > 0

  return {
    tokensReceive: userCreditsConversionData?.getUserCreditsConversionData?.tokensAmount,
    isTokensReceiveLoading: !viewer ? false : isPending,
    canClaimTokens: (hasTokensToClaim && !tokensAlreadyClaimed) || false,
    hasAccessTokenCreatedAt: userCreditsConversionData?.getUserCreditsConversionData?.accessTokenCreatedAt != null
  }
}

export default useCreditsConversion
