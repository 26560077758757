import type { ReactNode } from 'react'
import { Portal } from 'summon-ui/mantine'
import VideoOverlay from '@components/VideoOverlay'
import { useViewer } from '@hooks'
import { useUserDiamondsBalance } from '@hooks/balances'
import useG7SlotBoxes from '../../../hooks/useG7SlotBoxes'
import RewardsModal from '../../RewardsModal'
import { useFlow } from '../FlowProvider'
import useOpenG7Box from './useOpenG7Box'
import useOpenG7BoxSteps from './useOpenG7BoxSteps'

interface Props {
  boxId: string
  render: (open: () => void, isOpening: boolean, canOpen: boolean) => ReactNode
  onFlowSuccess?: () => void
}

const OpenG7BoxFlow = ({ boxId, render, onFlowSuccess }: Props) => {
  const { isAuthenticated } = useViewer()
  const { open, isOpening, rewards } = useOpenG7Box()
  const { canStartFlow, startFlow, endFlow } = useFlow()
  const { refetch: refetchDiamondsBalance } = useUserDiamondsBalance()
  const { refetch: refetchSlotBoxes } = useG7SlotBoxes({ enabled: isAuthenticated })
  const { isAnimationStep, isRewardsStep, goToAnimationStep, goToRewardsStep, resetStep } = useOpenG7BoxSteps()

  const handleOpen = () => {
    if (canStartFlow) {
      startFlow()
      open(boxId, goToAnimationStep, resetStep)
    }
  }

  const handleFlowSuccess = () => {
    onFlowSuccess && onFlowSuccess()
    resetStep()
    refetchSlotBoxes()
    refetchDiamondsBalance()
    endFlow()
  }

  return (
    <>
      {render(handleOpen, isOpening || isAnimationStep || isRewardsStep, canStartFlow)}
      {isRewardsStep && rewards && <RewardsModal open={isRewardsStep} onClose={handleFlowSuccess} rewards={rewards} />}
      {isAnimationStep && (
        <>
          <Portal>
            <VideoOverlay
              onClose={() => {
                goToRewardsStep()
              }}
              sourceUrl='/lootboxes/legendary.webm'
            />
          </Portal>
        </>
      )}
    </>
  )
}

export default OpenG7BoxFlow
