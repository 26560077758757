import { useMemo, useState } from 'react'
import { Center, Paper, Badge as MantineBadge, Text, Divider, Stack } from 'summon-ui/mantine'

type BadgeProps = {
  name: string
  imageUrl: string
  createdAt: string
}

const Badge = ({ name, imageUrl, createdAt }: BadgeProps) => {
  const [isNameHovered, setIsNameHovered] = useState(false)

  const date = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  }).format(new Date(createdAt))

  const badgeImage = useMemo(() => {
    return (
      <div className=''>
        <img src={imageUrl} alt={name} className='w-full h-full' />
      </div>
    )
  }, [imageUrl])

  return (
    <>
      <Paper withBorder radius='md' p='md' maw={150}>
        <Center mt='xs' h={75} className='cursor-pointer'>
          {badgeImage}
        </Center>
        <Stack mt={32}>
          <Divider />
          <MantineBadge
            w='100%'
            h='30'
            onMouseEnter={() => setIsNameHovered(true)}
            onMouseLeave={() => setIsNameHovered(false)}
            className='cursor-pointer'
          >
            <Text size='xs'>{isNameHovered ? date : name}</Text>
          </MantineBadge>
        </Stack>
      </Paper>
    </>
  )
}

export default Badge
