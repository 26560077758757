import { useMemo } from 'react'
import { useReadContract } from 'wagmi'
import { useQuery } from '@tanstack/react-query'
import { type EnrichedMetadata, getBaseSkinsMetadataList } from '@api/client'
import { TENANT_NETWORK, TENANT_CONTRACTS } from '@config'
import { SlotType } from '@config/enum'
import { AVATAR_BOUND_ABI } from '@constants/Abi/avatarBoundAbi'
import type { InventoryItem } from '@hooks/inventory/useAvatarMetadata'

export interface BaseSkinContractData {
  baseSkinId: string
  tokenUri: string
}

const useBaseSkins = ({ enabled = false }: { enabled?: boolean }) => {
  const { data: metadataUris }: { data: BaseSkinContractData[] | undefined } = useReadContract({
    address: TENANT_CONTRACTS.avatar,
    abi: AVATAR_BOUND_ABI,
    chainId: TENANT_NETWORK.id,
    functionName: 'getAllBaseSkins',
    query: {
      enabled
    }
  })

  const filteredContractBaseSkins: BaseSkinContractData[] | [] | undefined = metadataUris
    ?.filter((record: BaseSkinContractData) => record?.tokenUri?.includes('https'))
    .map((record) => ({
      tokenUri: record.tokenUri,
      baseSkinId: record.baseSkinId.toString()
    }))

  const { data: metadataList, isLoading } = useQuery({
    queryKey: ['metadata', { baseSkinsContractData: filteredContractBaseSkins }],
    queryFn: getBaseSkinsMetadataList,
    enabled: !!filteredContractBaseSkins
  })

  const onChainBaseSkins = useMemo(
    (): Partial<InventoryItem>[] | undefined =>
      enabled
        ? metadataList
            ?.filter((record) => record?.id)
            .map(
              (record: EnrichedMetadata): Partial<InventoryItem> => ({
                tokenId: Number(record.id),
                imageUrl: record.image,
                glbUrl: record.animation_url,
                slotType: SlotType.Body,
                isEquipped: false,
                count: 1
              })
            )
        : [],
    [metadataList, enabled]
  )

  return {
    baseSkins: onChainBaseSkins || [],
    isLoading
  }
}

export default useBaseSkins
