import type { BoomlandFactionLeaderboardItem, LeaderboardUser } from '@generated/generates'
import { sliceAddress } from '@utils/strings'
import type { LeaderboardTableElement, FactionLeaderboardTableElement } from './types'

const buildUpLeaderboardTableData = (
  leaderboard: Array<LeaderboardUser>,
  currentUserInfo: LeaderboardUser
): Array<LeaderboardTableElement> => {
  const updatedLeaderboard = [
    ...(currentUserInfo.walletAddress
      ? [
          {
            rank: currentUserInfo.position,
            member: {
              avatarUrl: currentUserInfo.avatarUrl || '', // Convert null to empty string
              wallet: currentUserInfo.username || sliceAddress(currentUserInfo.walletAddress as string, 5)
            },
            points: currentUserInfo.points,
            multiplier: currentUserInfo.lootDropMultiplier ?? undefined // Convert null to undefined
          }
        ]
      : []),
    ...leaderboard.map((user) => ({
      rank: user.position,
      member: {
        avatarUrl: user.avatarUrl || '', // Convert null to empty string
        wallet: user.username || sliceAddress(user.walletAddress as string, 5)
      },
      points: user.points,
      multiplier: user.lootDropMultiplier ?? undefined // Convert null to undefined
    }))
  ]

  return updatedLeaderboard
}

const buildUpFactionLeaderboardTableData = (
  leaderboard: Array<BoomlandFactionLeaderboardItem>
): Array<FactionLeaderboardTableElement> => {
  return leaderboard.map((user) => ({
    rank: user.position,
    name: user.name,
    points: user.totalXp,
    members: user.totalMembers
  }))
}

export { buildUpLeaderboardTableData, buildUpFactionLeaderboardTableData }
