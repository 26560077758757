import { createContext, useContext } from 'react'
import {
  alpha,
  Badge,
  Flex,
  getGradient,
  Image,
  NumberFormatter,
  Paper,
  Progress,
  Stack,
  Text,
  Title,
  useMantineTheme,
  type PaperProps
} from 'summon-ui/mantine'

type CreditsCardProps = {
  children: React.ReactNode
  from: string
  to: string
  reverse?: boolean
  elipses?: { color: string; right?: string; top?: string }[]
} & PaperProps

type CreditsCardBodyProps = {
  children: React.ReactNode
  participants?: string
  name: string
  description: string
  image: string
  noSpacing?: boolean
}
type CreditsProgressProps = {
  size?: string
  value: {
    max: number
    current: number
  }
}

export const CreditsContext = createContext<{ color: string }>({
  color: ''
})

const CreditsCard = ({ children, from, to, reverse, elipses, ...props }: CreditsCardProps) => {
  const theme = useMantineTheme()
  const fromColor = alpha(from, 0.5)
  const toColor = alpha(to, 0.5)
  const gradient = getGradient({ deg: reverse ? 90 : 0, from: fromColor, to: toColor }, theme)
  return (
    <CreditsContext.Provider
      value={{
        color: to
      }}
    >
      <Paper
        bg={gradient}
        radius='md'
        pos='relative'
        {...props}
        withBorder
        style={{
          border: `1px solid ${to}`
        }}
        className='overflow-hidden'
      >
        {elipses?.map((elipse) => <Elipse key={elipse.color} {...elipse} />)}
        {children}
      </Paper>
    </CreditsContext.Provider>
  )
}

const CreditsProgress = ({ size = 'sm', value, ...props }: CreditsProgressProps) => {
  const { color } = useContext(CreditsContext)
  const percentage = (value.current / value.max) * 100
  return (
    <Stack gap='xs' mt='md'>
      <Progress.Root size={size} className='flex items-center  !overflow-visible' w='100%'>
        <Progress.Section
          aria-label='completion progress'
          value={percentage}
          style={{
            boxShadow: `0px 0px 12px 2px ${color}`,
            background: color
          }}
          {...props}
        />
      </Progress.Root>
      <Flex justify='space-between'>
        <Text size='xs'>Credits Earned</Text>
        <Text size='xs'>
          <NumberFormatter thousandSeparator value={value.current} /> /{' '}
          <NumberFormatter thousandSeparator value={value.max} />
        </Text>
      </Flex>
    </Stack>
  )
}

const CreditsCardBody = ({
  noSpacing = false,
  children,
  participants,
  name,
  description,
  image
}: CreditsCardBodyProps) => {
  const { color } = useContext(CreditsContext)
  return (
    <Flex direction={{ xs: 'column', lg: 'row' }} gap='lg' align='center' pos='relative'>
      <Image src={image} radius='md' maw='150' />
      <Stack gap='4' w='100%'>
        <Flex justify='space-between' align='center'>
          <Title order={3}>{name}</Title>
          {participants && (
            <Badge variant='outline' tt='capitalize' color={color} c='white'>
              {participants} Participants
            </Badge>
          )}
        </Flex>
        <Text lineClamp={3} mih={noSpacing ? '0' : '80'}>
          {description}
        </Text>
        {children}
      </Stack>
    </Flex>
  )
}

const Elipse = ({ color, right, top }: { color: string; right?: string; top?: string }) => {
  return (
    <svg
      className='absolute'
      style={{
        right,
        top
      }}
      width='384'
      height='384'
      viewBox='0 0 384 384'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_f_233_2157)'>
        <circle cx='192' cy='192' r='117' fill={color} />
      </g>
      <defs>
        <filter
          id='filter0_f_233_2157'
          x='0'
          y='0'
          width='384'
          height='384'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='37.5' result='effect1_foregroundBlur_233_2157' />
        </filter>
      </defs>
    </svg>
  )
}
CreditsCard.Body = CreditsCardBody
CreditsCard.Progress = CreditsProgress
export default CreditsCard
