import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'

// Singleton-like state management
let notificationShownForCurrentSession = false

const useErrorCode = () => {
  const t = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const errorCode = params.get('errorCode')

  useEffect(() => {
    if (errorCode && !notificationShownForCurrentSession) {
      if (errorCode === 'alreadyConnected') {
        showNotification({
          title: t('Error adding social network'),
          message: t('This account is already connected to another user'),
          variant: 'danger'
        })
      } else if (errorCode === 'unknownError') {
        showNotification({
          title: t('Error adding social network'),
          message: t('Something was wrong, please try again'),
          variant: 'danger'
        })
      }

      // Set the session-wide flag to true
      notificationShownForCurrentSession = true

      // Clean up URL
      params.delete('errorCode')
      navigate({ search: params.toString() }, { replace: true })
    }
  }, [errorCode, t, navigate])
}

export default useErrorCode
