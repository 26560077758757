import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Text, Box, Image, Stack } from 'summon-ui/mantine'

interface Props {
  open: boolean
  onClose: () => void
}

const UnstakingSuccessModal = ({ open, onClose }: Props) => {
  const t = useTranslation()
  const navigate = useNavigate()

  const handleGoToStake = () => {
    navigate('/staking', { replace: true })
  }

  return (
    <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md'>
      <Box p='xs'>
        <Stack justify='center' align='center' py='lg' gap='xs'>
          <Image src='/images/staking/lockup-successful.jpg' alt='success' mb='md' />
          <Text ta='center'>
            {t(
              'Your tokens and rewards are on their way. You can withdraw your tokens after the 72 hr cool-down period.'
            )}
          </Text>
        </Stack>

        <Button fullWidth onClick={handleGoToStake}>
          {t('Go to dashboard')}
        </Button>
      </Box>
    </Modal>
  )
}

export default UnstakingSuccessModal
