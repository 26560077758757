import { useState } from 'react'
import { useMediaScreen } from 'summon-ui'
import { Center, Flex, Stack } from 'summon-ui/mantine'
import { PageCenteredWrapper } from '@components'
import { useViewer } from '@hooks'
import ActionCards from '../components/ActionCards'
import G7Box from '../components/G7Box/G7Box'
import { FlowProvider } from '../components/G7BoxFlows'
import OnboardingModal from '../components/OnboardingModal'
import SlotBoxes from '../components/SlotBoxes/SlotBoxes'
import useG7SlotBoxes from '../hooks/useG7SlotBoxes'
import type { SlotBoxes as SlotBoxesType } from '../types'

const IS_SLOTBOX_SELECTION_ENABLED = false

const slotBoxesPlaceholder: SlotBoxesType = [{ id: '0', userId: '?', boxDetails: null }, null, null, null]

const DiamondsView = () => {
  const { isLoading: isLoadingViewer, isAuthenticated } = useViewer()

  const [selectedSlotIndex, setSelectedSlotIndex] = useState<number | null>(null)
  const isTablet = useMediaScreen({ type: 'max', breakpoint: 'laptop' })
  const isMiniTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const { slotBoxes, isLoading: isLoadingSlotBoxes } = useG7SlotBoxes({ enabled: isAuthenticated })

  const handleSelectSlotBox = (index: number) => {
    setSelectedSlotIndex(index)
  }

  return isLoadingSlotBoxes || isLoadingViewer ? (
    <PageCenteredWrapper isLoader />
  ) : (
    <>
      <Stack
        pos='relative'
        align='center'
        gap={isMiniTablet && isAuthenticated ? 20 : 40}
        pt={isMiniTablet ? 0 : isTablet ? 110 : 60}
      >
        {isAuthenticated && !isMiniTablet && (
          <Flex justify='space-between' pos='absolute' top={0} w='100%'>
            <ActionCards />
          </Flex>
        )}
        <Center w='100%'>
          <G7Box isUserAuthenticated={isAuthenticated} />
        </Center>
        <Stack align='center' w='100%'>
          {isMiniTablet && isAuthenticated && <ActionCards isSmallVariant />}
          <FlowProvider>
            <SlotBoxes
              selectedSlotIndex={selectedSlotIndex}
              slotBoxes={isAuthenticated && slotBoxes ? slotBoxes : slotBoxesPlaceholder}
              isPlaceholder={isAuthenticated ? false : true}
              {...(IS_SLOTBOX_SELECTION_ENABLED ? { onSelectSlotBox: handleSelectSlotBox } : {})}
            />
          </FlowProvider>
        </Stack>
      </Stack>
      <OnboardingModal />
    </>
  )
}

export default DiamondsView
