import { useState } from 'react'
import { SocialSharingModal } from '@components'
import CompletedButton from '../components/CompletedButton'
import StartButton from '../components/StartButton'
import TypeformModal from './TypeformModal'

// TODO: This entire feature must be refactored. The tweet thing is custom for a specific form id
const hardcodedTypeformId = 'F2rIVKJP'

interface Props {
  typeformId: string
  userId: string
  isCompleted: boolean
  isDisabled?: boolean
}

const Typeform = ({ typeformId, userId, isCompleted, isDisabled = false }: Props) => {
  const [isCurrentlyCompleted, setIsCurrentlyCompleted] = useState(isCompleted)
  const [isTypeformModalOpen, setIsTypeformModalOpen] = useState(false)
  const [isShareTweetOpen, setIsShareTweetOpen] = useState(false)

  const handleSuccess = () => {
    setIsCurrentlyCompleted(true)
    const mustShareTweet = typeformId === hardcodedTypeformId
    if (mustShareTweet) {
      setIsShareTweetOpen(true)
    }
  }

  const handleStartTask = () => {
    setIsTypeformModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsTypeformModalOpen(false)
  }

  return (
    <>
      {isCurrentlyCompleted ? (
        <CompletedButton />
      ) : (
        <StartButton onStartTask={handleStartTask} isDisabled={isDisabled} />
      )}
      {isShareTweetOpen && <SocialSharingModal isOpened={isShareTweetOpen} variant='tweet' asButton={false} />}
      <TypeformModal
        typeformId={typeformId}
        isOpened={isTypeformModalOpen}
        userId={userId}
        onSuccess={handleSuccess}
        onFinish={handleCloseModal}
      />
    </>
  )
}

export default Typeform
