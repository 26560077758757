export enum FileExtension {
  Json = 'json',
  Png = 'png',
  Svg = 'svg',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Gif = 'gif',
  Webp = 'webp',
  Mp4 = 'mp4',
  Glb = 'glb',
  Gltf = 'gltf'
}

export enum CameraMode {
  Free = 'FREE',
  Head = 'HEAD',
  Face = 'FACE',
  Top = 'TOP',
  Bottom = 'BOTTOM',
  Feet = 'FEET',
  Companion = 'COMPANION'
}
