import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Badge, Box, Button, Flex, Image, Stack, Text, Title } from 'summon-ui/mantine'
import { ConnectButton } from '@components'
import { TENANT_CONFIG } from '@config'
import LoginError from '@pages/auth/LoginError'

const GuestHomePage = () => {
  const t = useTranslation()
  const [searchParams] = useSearchParams()
  const hasNoAccess = searchParams.get('access') === 'false'
  const navigate = useNavigate()
  const isNotMobile = useMediaScreen({ type: 'min', breakpoint: 'mini' })

  if (hasNoAccess) {
    return <LoginError />
  }

  return (
    <Stack justify='center' align='center' className='h-[calc(100vh-100px)]'>
      <Stack justify='center' gap='0'>
        <Image src={`${TENANT_CONFIG.assetsPrefix}/connect.png`} alt='' className='max-w-[500px] mx-auto' />
        <Box mb='sm' ta='center' mt='-40'>
          <Title c='white' fz={{ default: 'h2', md: '58' }} fw='bolder'>
            {TENANT_CONFIG.content?.login.title}
          </Title>
          <Text c='white' mx='auto' maw={592}>
            {TENANT_CONFIG.content?.login.description}
          </Text>
        </Box>
      </Stack>
      <Flex gap='xs'>
        <Button
          {...(isNotMobile ? { size: 'xl', w: 250 } : { w: 170 })}
          variant='outline'
          onClick={() => navigate('/quests')}
        >
          {t('Explore Quests')}
        </Button>
        <Box {...(isNotMobile ? { size: 'xl', w: 250 } : { w: 170 })}>
          <ConnectButton isXL={isNotMobile} />
        </Box>
      </Flex>
      <Box className='fixed bottom-10'>
        <Link to='https://summon.xyz' target='_blank'>
          <Badge variant='outline' color='white' size='lg' fw={200}>
            {t('Powered by Summon')}
          </Badge>
        </Link>
      </Box>
    </Stack>
  )
}

export default GuestHomePage
