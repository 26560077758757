import { type ReactNode, useState } from 'react'
import { Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  Box,
  Center,
  Text,
  Flex,
  Image,
  Modal,
  Button,
  Title,
  Stack,
  useMantineColorScheme,
  BackgroundImage
} from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { TENANT_CONFIG } from '@config'
import { useLogout, useViewer } from '@hooks'
import { sliceAddress } from '@utils/strings'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import AvatarEditor from './AvatarEditor'

const CTA_WIDTH = 128
const IMAGE_SIZE = 180
const TOP_PADDING_FOR_MENU = 85
const BOTTOM_PADDING_FOR_MENU = 20
const TOP_SPACE_FOR_AVATAR = 105

const {
  features: { enableEditAvatar },
  urlLinks: { wiki: wikiUrl, support: supportUrl }
} = TENANT_CONFIG

interface Props {
  isOpen: boolean
  close: () => void
}

const ProfileMenu = ({ isOpen, close }: Props) => {
  const t = useTranslation()
  const { logout } = useLogout()
  const { address, chain } = useAccount()
  const { colorScheme } = useMantineColorScheme()
  const openEditor = () => setIsEditorOpen(true)
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const { viewer } = useViewer()

  const { open: openWallet } = useWeb3Modal()
  const handleLogout = () => {
    logout()
    close()
  }

  const renderMenuItem = ({
    icon,
    onClick,
    label,
    testId
  }: {
    icon: ReactNode
    onClick?: () => void
    label: string
    testId: string
  }) => (
    <Flex data-testid={testId} gap='xs' onClick={onClick} className='cursor-pointer'>
      {icon} {label}
    </Flex>
  )

  const isLightColorScheme = colorScheme === 'light'
  return (
    <>
      <Modal
        opened={isOpen}
        onClose={() => {
          close()
          setIsEditorOpen(false)
        }}
        withCloseButton={false}
        radius='lg'
        padding={0}
        centered
      >
        <Box>
          {isEditorOpen && enableEditAvatar ? (
            <AvatarEditor
              isOpen={isEditorOpen}
              close={() => {
                setIsEditorOpen(false)
              }}
            />
          ) : (
            <Stack gap={0} h='100%' pb='lg'>
              <Center pos='absolute' w='100%' top={TOP_SPACE_FOR_AVATAR}>
                <Box bg={isLightColorScheme ? 'white' : 'gray'} className='rounded-full shadow-xl overflow-hidden'>
                  <BackgroundImage h={148} w={148} src={viewer?.avatarUrl || ''} />
                </Box>
              </Center>
              <Box>
                <Image h={IMAGE_SIZE} src='/images/user-bg.png' />
              </Box>
              <Stack pt={TOP_PADDING_FOR_MENU} pb={BOTTOM_PADDING_FOR_MENU} gap='lg' h='100%'>
                <Stack w='100%' align='center' gap={0}>
                  <Title order={5}>{(viewer?.firstName || '') + ' ' + (viewer?.lastName || '')}</Title>
                  <Text c='gray'>
                    {viewer?.xp} XP • Level {viewer?.level}
                  </Text>
                </Stack>
                <Stack align='start' gap='sm' pl='lg'>
                  {enableEditAvatar &&
                    renderMenuItem({
                      icon: <Icon name='User02' />,
                      label: t('Avatar Editor'),
                      onClick: openEditor,
                      testId: 'profile-menu-avatar-button'
                    })}
                  {wikiUrl &&
                    renderMenuItem({
                      icon: <Icon name='File06' />,
                      label: t('View Wiki'),
                      onClick: () => window.open(TENANT_CONFIG.urlLinks.wiki, '_blank'),
                      testId: 'profile-menu-wiki-button'
                    })}
                  {address &&
                    renderMenuItem({
                      icon: <Icon name='Wallet01' />,
                      label: t('Wallet') + `: ${sliceAddress(address as string, 5)}`,
                      testId: 'profile-menu-wallet-button',
                      onClick: () => openWallet({ view: 'Account' })
                    })}
                  {chain?.name &&
                    renderMenuItem({
                      icon: <Icon name='Link02' />,
                      label: 'Chain: ' + chain?.name,
                      testId: 'profile-menu-chain-button'
                    })}
                  {supportUrl &&
                    renderMenuItem({
                      icon: <Icon name='Headphones02' />,
                      label: t('Support'),
                      onClick: () => window.open(supportUrl, '_blank'),
                      testId: 'profile-menu-support-button'
                    })}
                </Stack>
              </Stack>
              <Flex justify='space-between' px='lg'>
                <Button data-testid='profile-menu-close-button' w={CTA_WIDTH} variant='outline' onClick={close}>
                  {t('Close')}
                </Button>
                <Button data-testid='profile-menu-logout-button' w={CTA_WIDTH} onClick={handleLogout}>
                  {t('Logout')}
                </Button>
              </Flex>
            </Stack>
          )}
        </Box>
      </Modal>
    </>
  )
}

export default ProfileMenu
