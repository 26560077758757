import { useDisconnect } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'
import { deleteJWT } from '@utils/auth'

interface ReturnType {
  logout: () => void
}

const useLogout = (): ReturnType => {
  const { disconnectAsync } = useDisconnect()
  const queryClient = useQueryClient()

  const logout = () => {
    deleteJWT()
    localStorage.clear()
    queryClient.clear()
    disconnectAsync()
  }

  return { logout }
}

export default useLogout
