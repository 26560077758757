import { useGqlQuery } from '@api/gql/gqlServices'
import {
  GetTransactionHistoryDocument,
  TransactionHistoryType,
  type GetTransactionHistoryQuery
} from '@generated/generates'
import type { HistoryTableElement } from '../views/types'

const useHistory = () => {
  const { data, isLoading, status, refetch } = useGqlQuery<GetTransactionHistoryQuery>(GetTransactionHistoryDocument)

  let history: HistoryTableElement[] = []
  if (data?.viewer?.transactionHistory) {
    history = data.viewer.transactionHistory.map((historyEntrance) => {
      const { type, description, value, txLink, txAddress, createdAt, badges } = historyEntrance
      const isOffChain = type === TransactionHistoryType.OffChain

      return {
        description,
        XP: isOffChain ? value : undefined,
        badges,
        onChain: {
          type,
          txLink,
          txAddress // txAddress is optional here
        },
        date: new Date(createdAt)
      }
    })
  }

  return {
    isLoading,
    status,
    refetch,
    history: status === 'success' ? history : null
  }
}

export default useHistory
