import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Title, Text, Box, Image, Group, Carousel } from 'summon-ui/mantine'
import { AssetsPrefix } from '@config/enum'
import useDiamondsOnboarding from '../hooks/useDiamondsOnboarding'

const DiamondsOnboarding = () => {
  const t = useTranslation()
  const {
    isDiamondsOnboardingModalOpen,
    onCloseDiamondsOnboardingModal,
    handleClickActionButton,
    slide,
    setSlide,
    isLastSlide
  } = useDiamondsOnboarding()
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const slideOneHeight = isMobile ? 440 : 480

  const renderStepOne = () => (
    <Box>
      <Image
        src={`${AssetsPrefix.G7}/diamonds/diamonds-onboarding-1.png`}
        fallbackSrc='https://placehold.co/350x210?text=Placeholder'
      />
      <Title order={3} ta='center' mt='md'>
        {t('Diamonds are forever')}
      </Title>
      <Text ta='center'>
        {t(
          "The Diamonds you earn from staking can be used to buy G7 Boxes filled with rewards from our Citizens Pool. Your Diamond's balance will update everyday. Unlike the rest of Web3 there's no reason to hodl, use your Diamonds to buy G7 boxes as soon as you get the chance."
        )}
      </Text>
    </Box>
  )

  const renderStepTwo = () => (
    <Box>
      <Image
        src={`${AssetsPrefix.G7}/diamonds/diamonds-onboarding-2.png`}
        fallbackSrc='https://placehold.co/350x210?text=Placeholder'
      />
      <Title order={3} ta='center' mt='md'>
        {t('Buy some G7 Boxes')}
      </Title>
      <Text ta='center'>
        {t(
          'G7 Boxes are filled with amazing prizes ranging from USDC to G7 tokens to limited edition partner gaming assets. Once you buy a box, it takes 8 hours to unlock. And you can only buy a new box when you have a free slot.'
        )}
      </Text>
    </Box>
  )

  const renderStepThree = () => (
    <Box>
      <Image
        src={`${AssetsPrefix.G7}/diamonds/diamonds-onboarding-3.png`}
        fallbackSrc='https://placehold.co/350x210?text=Placeholder'
      />
      <Title order={3} ta='center' mt='md'>
        {t('Unlock your G7 Box')}
      </Title>
      <Text ta='center'>
        {t(
          "When you open your G7 box, the prizes within are revealed and transferred to your wallet. There's nothing else to do, except stack more Diamonds and buy more G7 boxes."
        )}
      </Text>
    </Box>
  )

  return (
    <Modal
      opened={isDiamondsOnboardingModalOpen}
      onClose={onCloseDiamondsOnboardingModal}
      withCloseButton={false}
      radius='md'
      closeOnClickOutside={false}
    >
      <Box>
        <Carousel
          draggable={false}
          withIndicators
          withControls={false}
          initialSlide={slide}
          height={slideOneHeight}
          onSlideChange={(index) => setSlide(index)}
          styles={{
            indicator: { backgroundColor: 'red', borderRadius: '100%', width: 8, height: 8 }
          }}
        >
          <Carousel.Slide>{renderStepOne()}</Carousel.Slide>
          <Carousel.Slide>{renderStepTwo()}</Carousel.Slide>
          <Carousel.Slide>{renderStepThree()}</Carousel.Slide>
        </Carousel>
        <Group justify='space-between' wrap='nowrap'>
          <Button fullWidth onClick={handleClickActionButton}>
            {isLastSlide ? t('Get Started') : t('Next')}
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default DiamondsOnboarding
