interface ErrorResponse {
  response?: {
    errors?: Array<{ message?: string }>
  }
}

export function isErrorResponse(obj: any): obj is ErrorResponse {
  return (
    obj &&
    typeof obj === 'object' &&
    obj.response &&
    typeof obj.response === 'object' &&
    obj.response.errors &&
    Array.isArray(obj.response.errors)
  )
}
