import { Box, Button, Stack, Flex, TextInput, useForm } from 'summon-ui/mantine'

export interface SolanaFormValues {
  solanaWalletAddress?: string
}

interface Props {
  solanaWalletAddress?: string
  onSubmit: (values: SolanaFormValues) => void
  isUpdating: boolean
}

const SolanaForm = ({ solanaWalletAddress, onSubmit, isUpdating }: Props) => {
  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<SolanaFormValues>({
    initialValues: { solanaWalletAddress: solanaWalletAddress || '' }
  })

  const handleSubmit = async (): Promise<void> => {
    onSubmit(values)
  }

  const isSubmitButtonDisabled = solanaWalletAddress === values.solanaWalletAddress

  return (
    <form onSubmit={onSubmitForm(handleSubmit)}>
      <Stack gap='sm'>
        <Box>
          <TextInput {...getInputProps('solanaWalletAddress')} placeholder='Enter your Solana Wallet' />
        </Box>
        <Flex justify='end'>
          <Button
            data-testid='settings-solana-save-button'
            type='submit'
            disabled={isSubmitButtonDisabled}
            loading={isUpdating}
          >
            Save
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}

export default SolanaForm
