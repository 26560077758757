import { useState } from 'react'
import { Icon, showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Flex, Stack, Text } from 'summon-ui/mantine'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { SocialType } from '@components/SocialConnect/types'
import type { TaskWithQuestId } from '@features/quests/types'
import { useCheckTaskCompletion2Mutation, useUpdatePartnerUserMutation } from '@generated/generates'
import { useUpdateViewerSettings } from '@hooks'

const REFRESH_STATUS_SUBTITLE = 'Please allow up to 1 minute for confirmation'
const REFRESH_STATUS_LAST = "If you haven't connected your account, please do so in settings"
const NOT_COMPLETED_TASK_TITLE = 'The task has not been completed'
const NOT_COMPLETED_TASK_SUBTITLE = 'Follow the instructions and check again'
const API_LIMIT_TASK_TITLE = 'Servers are busy right now.'
const API_LIMIT_TASK_SUBTITLE = 'Try again in a minute.'
const NEED_AUTH_TITLE = 'Connect account'
const NEED_AUTH_SUBTITLE = 'You need to connect your account and authorize access to complete this task.'
const ERROR_SAVING_PARTNER_ID_TITLE = 'Something is wrong with the partner user id'
const ERROR_SAVING_PARTNER_ID_DESC = 'Please, check that you are entering the correct information and try again'

enum IntegrationKey {
  Twitter = 'twitter',
  Discord = 'discord'
}

enum CheckTaskCompletion {
  NotCompleted = 'NOT_COMPLETED',
  ApiLimit = 'API_LIMIT',
  RefreshStatus = 'REFRESH_STATUS',
  NeedAuthorization = 'NEED_AUTHORIZATION',
  Completed = 'COMPLETED'
}

interface Props {
  task?: TaskWithQuestId
}

const useCheckTaskCompletion = ({ task }: Props) => {
  const t = useTranslation()
  const queryClient = useQueryClient()
  const [socialConnect, setSocialConnect] = useState<SocialType | null>(null)
  const [isCheckStatusLoading, setIsCheckStatusLoading] = useState(false)
  const [isSavingPartnerUser, setIsSavingPartnerUser] = useState(false)
  const { updateViewerSettings, isUpdating: isUpdatingUserSettings } = useUpdateViewerSettings()

  const { mutateAsync: checkTaskCompletionMutation, isPending: isCheckTaskCompletionLoading } =
    useCheckTaskCompletion2Mutation(graphqlClient(), {
      onSuccess(data) {
        const { checkTaskCompletion2 } = data

        switch (checkTaskCompletion2) {
          case CheckTaskCompletion.Completed:
            return checkStatus()
          case CheckTaskCompletion.NotCompleted:
            return showNotification({
              variant: 'danger',
              title: NOT_COMPLETED_TASK_TITLE,
              message: NOT_COMPLETED_TASK_SUBTITLE
            })
          case CheckTaskCompletion.ApiLimit:
            return showNotification({
              variant: 'danger',
              title: API_LIMIT_TASK_TITLE,
              message: API_LIMIT_TASK_SUBTITLE
            })
          case CheckTaskCompletion.RefreshStatus:
            return checkStatus()
          case CheckTaskCompletion.NeedAuthorization: {
            showNotification({
              variant: 'info',
              title: NEED_AUTH_TITLE,
              message: NEED_AUTH_SUBTITLE
            })

            const isTwitter = task?.integrationFeature?.integrationKey === IntegrationKey.Twitter
            const isDiscord = task?.integrationFeature?.integrationKey === IntegrationKey.Discord
            return isTwitter
              ? setSocialConnect(SocialType.Twitter)
              : isDiscord
                ? setSocialConnect(SocialType.Discord)
                : null
          }
          default:
            return checkStatus()
        }
      },
      onError(error) {
        console.error('ERROR: ', error)
      }
    })

  const updateUserSettings = (data: { [key: string]: string }) => {
    updateViewerSettings(data, {
      onError: (error) => {
        console.error('Error updating user settings: ', error)
      },
      onFinally: () => {
        debouncedInvalidateQuests(3000)
      }
    })
  }

  const { mutateAsync: updatePartnerUserIdMutation } = useUpdatePartnerUserMutation(graphqlClient(), {
    onError: (error: unknown) => {
      console.error('Error saving SCID: ', error)
      showNotification({
        variant: 'danger',
        title: ERROR_SAVING_PARTNER_ID_TITLE,
        //@ts-ignore
        message: error?.response?.errors[0]?.message || ERROR_SAVING_PARTNER_ID_DESC
      })
    }
  })

  const updateUserPartner = (partnerUserId: string, featureId: string) => {
    try {
      setIsSavingPartnerUser(true)
      updatePartnerUserIdMutation({ partnerUserId, featureId })
    } catch (error) {
      console.error('Error saving partner user ID: ', error)
      setIsSavingPartnerUser(false)
    } finally {
      debouncedInvalidateQuests(3000, () => setIsSavingPartnerUser(false))
    }
  }

  const invalidateQuests = async () => {
    await queryClient.invalidateQueries({ queryKey: ['GetQuest'] })
    await queryClient.invalidateQueries({ queryKey: ['GetCategoryQuests'] })
    await queryClient.invalidateQueries({ queryKey: ['GetUser'] })
    await queryClient.invalidateQueries({ queryKey: ['GetClaimables'] })
    await queryClient.invalidateQueries({ queryKey: ['GetRewardsRedeemed'] })
    await queryClient.invalidateQueries({ queryKey: ['GetCategoryBySlug'] })
  }

  const debouncedInvalidateQuests = (time: number, callback?: () => void) => {
    setTimeout(() => {
      invalidateQuests()
      callback && callback()
    }, time)
  }

  const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
    let timeoutId: NodeJS.Timeout
    return function (...args: Parameters<T>) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func(...args), delay)
    }
  }

  const checkStatus = debounce(() => {
    invalidateQuests()
    showNotification({
      title: (
        <Flex gap={4} align='center' justify='start' mx={0}>
          <Icon name='SearchSm' size='sm' c='dark.1' />
          {t('Action status is being verified')}
        </Flex>
      ),
      message: (
        <Stack gap={4} pt='xs'>
          <Text size='sm'>{REFRESH_STATUS_SUBTITLE}</Text>
          <Text fw='bold' size='sm'>
            {REFRESH_STATUS_LAST}
          </Text>
        </Stack>
      ),
      variant: 'info'
    })
  }, 2000)

  const handleCheckTaskCompletion = () => {
    if (task) {
      setIsCheckStatusLoading(true)
      try {
        checkTaskCompletionMutation({
          questId: task.questId!,
          taskId: task.id
        })
      } finally {
        setIsCheckStatusLoading(false)
      }
    }
  }

  return {
    handleCheckTaskCompletion,
    isCheckTaskCompletionLoading,
    socialConnect,
    setSocialConnect,
    invalidateQuests,
    checkStatus,
    isCheckStatusLoading,
    updateUserSettings,
    isUpdatingUserSettings,
    updateUserPartner,
    isSavingPartnerUser
  }
}

export default useCheckTaskCompletion
