import { useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'summon-ui/intl'
import { Button, Divider, Drawer, Group, Stack, Text } from 'summon-ui/mantine'
import { useUpdateCartMutation } from '@api/modules/shopify/hooks/useUpdateCartMutation'
import { useCart } from '../hooks/useCart'
import NumberInput from './NumberInput'

type FormSchema = {
  quantity: string
}

const CartItem = ({
  quantity,
  imgSrc,
  title,
  imgAlt,
  description,
  amount,
  lineId,
  merchandiseId,
  currency
}: {
  quantity: number
  imgSrc: string
  imgAlt: string
  title: string
  description: string
  amount: string
  lineId: string
  merchandiseId: string
  currency: string
}) => {
  const cart = useCart()
  const methods = useForm<FormSchema>({ defaultValues: { quantity: quantity.toString() } })
  const updateCart = useUpdateCartMutation()
  const watch = useWatch({ control: methods.control, name: 'quantity' })

  useEffect(() => {
    if (watch === quantity.toString()) return
    updateCart.mutate(
      { cartId: cart.id, lines: [{ id: lineId, quantity: watch, merchandiseId: merchandiseId }] },
      {
        onSuccess: () => cart.refetch()
      }
    )
  }, [watch])

  const price = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).format(Number(amount ?? 0))

  return (
    <FormProvider {...methods}>
      <form>
        <Group gap='md' preventGrowOverflow={false} wrap='nowrap'>
          <img className='w-24 h-24 rounded-lg' src={imgSrc} alt={imgAlt} />
          <Stack gap='xs' w='100%'>
            <Text fw={500}>{title}</Text>
            <Text size='xs' c='gray'>
              {description}
            </Text>

            <Group justify='space-between'>
              <div className='w-[100px]'>
                <NumberInput name='quantity' className='py-[8px]' defaultValue={quantity} min={0} />
              </div>
              <Text fw={500}>{price}</Text>
            </Group>
          </Stack>
        </Group>
      </form>
    </FormProvider>
  )
}

// This is used to track revenue that is owed to Summon.xyz
// It utilizes discount codes & the Simple Affiliate Shopify app
function getCheckoutUrl(url: string | undefined) {
  try {
    if (!url) return ''
    const urlObject = new URL(url)
    urlObject.searchParams.append('discount', 'summon')
    return urlObject.toString()
  } catch (error) {
    return ''
  }
}

export const Cart = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const t = useTranslation()
  const cart = useCart()
  const subTotal = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(Number(cart?.estimatedCost?.totalAmount?.amount ?? 0))
  const items = cart?.lines?.edges ?? []

  return (
    <Drawer opened={isOpen} onClose={onClose}>
      <Drawer.Header title='Cart' />
      <Drawer.Body>
        <Stack gap='md'>
          {items.map(({ node: { id, merchandise, quantity } }) => {
            const image = merchandise.image.url ?? merchandise.product.featuredImage?.originalSrc ?? ''
            const altText = merchandise.image.altText ?? merchandise.product.featuredImage?.altText ?? ''

            return (
              <CartItem
                key={id}
                lineId={id}
                merchandiseId={merchandise.id}
                title={merchandise.product.title}
                imgSrc={image}
                imgAlt={altText}
                description={merchandise.title}
                amount={merchandise.priceV2.amount}
                currency={merchandise.priceV2.currencyCode}
                quantity={quantity}
              />
            )
          })}
        </Stack>

        <Divider my='lg' />

        <Group justify='space-between' w='100%'>
          <Text>{t('Subtotal')}</Text>
          <Text fw={700}>{subTotal}</Text>
        </Group>
      </Drawer.Body>
      <Group w='100%' justify='space-between'>
        <Button
          variant='outline'
          onClick={() => {
            onClose()
          }}
        >
          {t('Back')}
        </Button>
        <Link to={getCheckoutUrl(cart?.checkoutUrl)} target='_blank'>
          <Button>{t('Checkout')}</Button>
        </Link>
      </Group>
    </Drawer>
  )
}
