import { NavLink as RouterNavLink, useMatch, useResolvedPath } from 'react-router-dom'
import { AwesomeIcon, G7Icon } from 'summon-ui'
import type { G7IconName, IconName, IconPrefix } from 'summon-ui/awesome-icons'
import { NavLink, Title, useMantineColorScheme } from 'summon-ui/mantine'
import { BOTTOM_MENU } from '@layouts/DashboardLayout/constants'

const BottomNavigationMenu = ({ isSmall }: { isSmall: boolean }) => {
  const { colorScheme } = useMantineColorScheme()

  return (
    <>
      {BOTTOM_MENU.map(({ iconName, pfx, ...link }) => {
        const resolvedPath = useResolvedPath(link.to)
        const match = useMatch({ path: resolvedPath.pathname, end: true })
        const isActive = !!match

        return (
          <NavLink
            key={link.name}
            component={RouterNavLink}
            to={link.to}
            data-testid={`navigation-menu-${link.name}-link`}
            leftSection={
              pfx === 'fak' ? (
                <G7Icon name={iconName as G7IconName} pfx={pfx} color={colorScheme === 'light' ? 'gray.7' : 'white'} />
              ) : (
                <AwesomeIcon
                  pfx={pfx as IconPrefix}
                  color={colorScheme === 'light' ? 'gray.7' : 'white'}
                  name={iconName as IconName}
                />
              )
            }
            w={isSmall ? 50 : '100%'}
            tt='uppercase'
            active={isActive}
            {...(isSmall
              ? {}
              : {
                  label: (
                    <Title order={6} tt='uppercase'>
                      {link.name}
                    </Title>
                  )
                })}
          />
        )
      })}
    </>
  )
}

export default BottomNavigationMenu
