import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGqlMutation } from '@api/gql/gqlServices'
import { GAME7_CHAIN } from '@constants/chains'
import { MigrateUserToG7ChainDocument, type MigrateUserToG7ChainMutation } from '@generated/generates'
import useViewer from '@hooks/viewer/useViewer'

const useMigrate = () => {
  const { viewer, isLoading: isLoadingUser, refetch } = useViewer()
  const navigate = useNavigate()
  const { mutateAsync: migrate, isPending: isLoading } = useGqlMutation<MigrateUserToG7ChainMutation>(
    MigrateUserToG7ChainDocument,
    undefined,
    {
      onSuccess: () => {
        refetch()
      }
    }
  )

  useEffect(() => {
    if (!viewer || viewer.chainId === GAME7_CHAIN.id) {
      navigate('/')
    }
  }, [viewer])

  return { migrate, isLoading: isLoading || isLoadingUser }
}

export default useMigrate
