import { Image, Progress } from 'summon-ui/mantine'

type Props = {
  value: number
}

const PROGRESS_COLOR = '#F04438'
const PROGRESS_STEPS = [
  {
    value: 5,
    image: '/images/credits/5.svg',
    text: '5'
  },
  {
    value: 25,
    image: '/images/credits/25.svg',
    text: '25'
  },
  {
    value: 50,
    image: '/images/credits/50.svg',
    text: '50'
  },
  {
    value: 100,
    image: '/images/credits/100.svg',
    text: '100'
  }
]
const ReferralsProgress = ({ value, ...props }: Props) => {
  return (
    <>
      <Progress.Root size={9} className='flex items-center relative !overflow-visible' w='100%'>
        <Progress.Section
          animated
          aria-label='completion progress'
          value={value}
          style={{
            boxShadow: `0px 0px 12px 2px ${PROGRESS_COLOR}`,
            background: PROGRESS_COLOR
          }}
          {...props}
        />
        {PROGRESS_STEPS.map((step) => (
          <Image
            key={step.value}
            w={{ xs: 50, md: 75 }}
            style={{
              filter: value < step.value ? 'grayscale(100%)' : ''
            }}
            h={{ xs: 35, md: 75 }}
            pos='absolute'
            left={`${step.value}%`}
            src={step.image}
            ml={{ xs: '-10', md: '-32' }}
          />
        ))}
      </Progress.Root>
    </>
  )
}

export default ReferralsProgress
