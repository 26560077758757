import { useNavigate } from 'react-router-dom'
import { EnhancedPaper } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Paper, Stack, Title, Text, Image, Flex, TypographyStylesProvider, Box } from 'summon-ui/mantine'
import { TutorialIds } from '@hocs/Tutorial/helpers.tutorial'
import useNextCategorySuggested from '../hooks/useNextCategorySuggested'

const RecommendedCategory = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { nextSuggestedCategory, isNextCategoryLoading } = useNextCategorySuggested()

  if (!nextSuggestedCategory && !isNextCategoryLoading) return null

  return (
    <Stack gap='sm' p={{ xs: 'md', md: '0' }}>
      <Title data-testid='recommended-quest-title-label' fz='md' visibleFrom='sm'>
        {t('Recommended Quests')}
      </Title>
      {isNextCategoryLoading ? (
        <Paper withBorder radius='md' bg='gray.3' h={140} className='animate-pulse' />
      ) : (
        <EnhancedPaper withBorder p='md' radius='md' withBg>
          <Box id={TutorialIds.SuggestedQuestCard}>
            <Flex align='center' justify='space-between' gap='sm'>
              {nextSuggestedCategory?.imageUrl && (
                <Image
                  data-testid='recommended-quest-avatar-image'
                  radius='md'
                  w='40%'
                  bgsz='cover'
                  mih='100%'
                  src={nextSuggestedCategory?.imageUrl}
                />
              )}
              <Stack gap='md' w='100%'>
                <Stack gap='0'>
                  <Title data-testid='recommended-quest-name-label' fz='md' lineClamp={1}>
                    {nextSuggestedCategory?.name}
                  </Title>
                  <TypographyStylesProvider>
                    <Text
                      data-testid='recommended-quest-description-label'
                      size='xs'
                      className='line-clamp-3'
                      dangerouslySetInnerHTML={{ __html: nextSuggestedCategory?.description || '' }}
                    />
                  </TypographyStylesProvider>
                </Stack>

                <Button
                  data-testid='recommended-quest-view-button'
                  fullWidth
                  onClick={() => navigate(`/quests/${nextSuggestedCategory?.id}`)}
                >
                  {t('View Quests')}
                </Button>
              </Stack>
            </Flex>
          </Box>
        </EnhancedPaper>
      )}
    </Stack>
  )
}

export default RecommendedCategory
