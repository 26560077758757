import { useState, useEffect } from 'react'
import { useDebouncedState } from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { useGqlQuery } from '@api/gql/gqlServices'
import { GetBoomlandLeaderboardDocument } from '@generated/generates'
import type { LeaderboardUser, GetBoomlandLeaderboardQuery } from '@generated/generates'
import { buildUpLeaderboardTableData } from './helpers'

const useLeaderboard = (pageSize: number) => {
  const { isConnected } = useAccount()
  const [currentPage, setCurrentPage] = useDebouncedState(1, 200)
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0)
  const { data, isLoading, status, refetch } = useGqlQuery<GetBoomlandLeaderboardQuery>(
    GetBoomlandLeaderboardDocument,
    {
      name: 'boomland',
      limit: pageSize,
      offset: (currentPage - 1) * pageSize
    }
  )

  useEffect(() => {
    if (data && data.leaderboard !== undefined) {
      setTotalPagesCount(Math.ceil(data.leaderboard.total / pageSize))
    }
  }, [data, pageSize])

  const handleRetrievePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    isLoading,
    status,
    refetch,
    leaderboard:
      status === 'success'
        ? buildUpLeaderboardTableData(
            data?.leaderboard.data as LeaderboardUser[],
            isConnected && data?.leaderboard.viewer
              ? (data.leaderboard.viewer as LeaderboardUser)
              : ({} as LeaderboardUser)
          )
        : [],
    currentPage,
    navigateToPage: handleRetrievePage,
    totalPagesCount,
    isUserInLeaderboard: isConnected && !!data?.leaderboard.viewer,
    totalUsersCount: data?.leaderboard.total || 0
  }
}

export default useLeaderboard
