import { Hosts } from '@config/enum'
import type { ContractsType } from '../types'

// PROD
const AVATAR_MAINNET = '0x023938455d7A9B90984E38d9069fA4b34345a63c'
const ITEMS_MAINNET = '0xD437b472d96C5F005CC013655494Acd10c19867C'
const LEVEL_MAINNET = '0xF473487C507fdCD4BF21b989512FaBE3Ad032CC3'
const MARKETPLACE_MAINNET = '0xD437b472d96C5F005CC013655494Acd10c19867C'
const MARKETPLACE_FEES_MAINNET = '0x27fC323216BAeee0eB4F059fCA7a5877ee2B4281'

// STAGING
const AVATAR_GAME7_MAINNET = '0xb7170B3BA95279d5bD7649c81bFf18d0e6320686'
const ITEMS_GAME7_MAINNET = '0x864eC825aE0A62566781ea19A55d34Ed69BB2E50'
const LEVEL_GAME7_MAINNET = '0x84865664f4307160caB290a7606a26Ef94C1cad7'

// DEVELOP
const AVATAR_GAME7_TESTNET = '0xBB314D534DB5860AfCc4e139BEBf33Db9640657c'
const ITEMS_GAME7_TESTNET = '0xA65011B91c8c737fDE20267CB2C2871672124629'
const LEVEL_GAME7_TESTNET = '0x76E45aBc1139C913b43DBB09e99d3650a06e7F22'
const ACCESS_TOKEN_GAME7_TESTNET = '0x83B1144773200BCd3Cf955B53E2b8a78EB26DBB7'

const ACHIEVO_WALLET_MAINNET = '0x85f56764F58F595D08252b98942554bFB5Eea390'
// This is a wallet and we use the same for develop and staging
const ACHIEVO_WALLET_TESTNET = '0x4d790f180C71029F983c0A70901E3DcA1aAe12f5'

const CONTRACTS_DICTIONARY: Record<Hosts, ContractsType> = {
  [Hosts.G7]: {
    avatar: AVATAR_MAINNET,
    items: ITEMS_MAINNET,
    level: LEVEL_MAINNET,
    // TODO: Add contract here before release to prod
    accessToken: '0x',
    marketplace: MARKETPLACE_MAINNET,
    marketplaceFees: MARKETPLACE_FEES_MAINNET,
    achievoWallet: ACHIEVO_WALLET_MAINNET
  },
  [Hosts.G7Staging]: {
    avatar: AVATAR_GAME7_MAINNET,
    items: ITEMS_GAME7_MAINNET,
    level: LEVEL_GAME7_MAINNET,
    // TODO: Add contract here before release to staging
    accessToken: '0x',
    marketplace: MARKETPLACE_MAINNET,
    marketplaceFees: MARKETPLACE_FEES_MAINNET,
    achievoWallet: ACHIEVO_WALLET_TESTNET
  },
  [Hosts.G7Develop]: {
    avatar: AVATAR_GAME7_TESTNET,
    items: ITEMS_GAME7_TESTNET,
    level: LEVEL_GAME7_TESTNET,
    accessToken: ACCESS_TOKEN_GAME7_TESTNET,
    marketplace: MARKETPLACE_MAINNET,
    marketplaceFees: MARKETPLACE_FEES_MAINNET,
    achievoWallet: ACHIEVO_WALLET_TESTNET
  }
}

const host = window.location.host
const devHost =
  ((window.localStorage.getItem('tenant') && JSON.parse(window.localStorage.getItem('tenant') as string)) as string) ||
  null

const defaultContracts = CONTRACTS_DICTIONARY[Hosts.G7Develop]
const contracts =
  CONTRACTS_DICTIONARY[host as Hosts] || (devHost && CONTRACTS_DICTIONARY[devHost as Hosts]) || defaultContracts

export default contracts
