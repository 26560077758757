import { TenantImageEnum, useAssetsContext } from 'summon-ui'
import { Image } from 'summon-ui/mantine'

const FallbackPage = () => {
  const { getTenantImage } = useAssetsContext()
  const NotFoundImage = getTenantImage(TenantImageEnum.NotFound)

  return (
    <div className='flex items-center justify-center min-h-[70vh] '>
      <Image src={NotFoundImage} className='px-4' />
    </div>
  )
}

export default FallbackPage
