import { useTranslation } from 'summon-ui/intl'
import { Modal, Stack, Text, TextInput, Group, Button, useForm } from 'summon-ui/mantine'
import useCheckTaskCompletion from '@features/quests/hooks/useCheckTaskCompletion'
import { useViewer } from '@hooks'

const validateInput = (value?: string) =>
  value?.length && 4 <= value.length && value.length <= 20 ? null : 'Must be between 4 and 20 characters'

export interface UsernameFormValues {
  username?: string
}

interface Props {
  opened: boolean
  onClose: () => void
}

const ActionUsernameModal = ({ opened, onClose }: Props) => {
  const t = useTranslation()
  const { submitForm, isPending, getInputProps } = useHandleUsername()

  return (
    <Modal title='Enter Username' opened={opened} onClose={onClose} maw={430}>
      <form onSubmit={submitForm}>
        <Stack>
          <Text>{t('Sync your username to enhance your quest experience and unlock exclusive rewards')}</Text>

          <TextInput {...getInputProps('username')} label='Username' placeholder='Enter your username' />

          <Group justify='space-between' w='100%' mt='sm'>
            <Button onClick={onClose} variant='outline' miw={115}>
              {t('Cancel')}
            </Button>
            <Button type='submit' loading={isPending} disabled={isPending} miw={115}>
              {t('Submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default ActionUsernameModal

const useHandleUsername = () => {
  const { viewer } = useViewer()

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<UsernameFormValues>({
    initialValues: { username: viewer?.username || '' },
    validate: {
      username: validateInput
    }
  })
  const { updateUserSettings, isUpdatingUserSettings } = useCheckTaskCompletion({})
  const handleSubmit = async (data: typeof values) => {
    return updateUserSettings({ username: data?.username || '' })
  }
  return {
    getInputProps,
    isPending: isUpdatingUserSettings,
    submitForm: onSubmitForm(handleSubmit)
  }
}
