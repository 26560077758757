import { Fragment, useState } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Group, Text, Stack, Divider, Box } from 'summon-ui/mantine'
import { graphqlClient } from '@api/config'
import CharmverseLogo from '@assets/icons/socials/charmverse.svg?react'
import DiscordLogo from '@assets/icons/socials/discord.svg?react'
import TwitterLogo from '@assets/icons/socials/twitter.svg?react'
import { SocialConnect } from '@components'
import { SocialType } from '@components/SocialConnect/types'
import { SocialAccounts, useDisconnectSocialNetworkMutation } from '@generated/generates'
import { useViewer } from '@hooks'
import { SettingItem } from '../components/SettingItem'
import { SettingsHeader } from '../components/SettingsHeader'
import ReferralForm from './ReferralForm'
import TelegramForm from './TelegramForm'

interface SocialNetwork {
  id: number
  name: string
  accessor: string
  logo: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  connect: () => void
  disconnect: () => void
}

const SocialView = () => {
  const t = useTranslation()
  const { viewer, refetch } = useViewer()
  const { mutateAsync: disconnectSocialNetworkMutation, isPending: isDisconnecting } =
    useDisconnectSocialNetworkMutation(graphqlClient(), {
      onSuccess() {
        showNotification({
          variant: 'success',
          message: 'Social account disconnected successfully'
        })
        refetch()
      }
    })

  const [socialConnect, setSocialConnect] = useState<SocialType | null>(null)

  const socialsNetworks: SocialNetwork[] = [
    {
      id: 1,
      name: 'Charmverse',
      accessor: 'charmverseUserId',
      logo: CharmverseLogo,
      connect: () => setSocialConnect(SocialType.Charmverse),
      disconnect: () => disconnectSocialNetworkMutation({ socialAccounts: SocialAccounts.Charmverse })
    },
    {
      id: 2,
      name: 'Discord',
      accessor: 'discordUsername',
      logo: DiscordLogo,
      connect: () => setSocialConnect(SocialType.Discord),
      disconnect: () => disconnectSocialNetworkMutation({ socialAccounts: SocialAccounts.Discord })
    },
    {
      id: 3,
      name: 'X',
      accessor: 'twitterUsername',
      logo: TwitterLogo,
      connect: () => setSocialConnect(SocialType.Twitter),
      disconnect: () => disconnectSocialNetworkMutation({ socialAccounts: SocialAccounts.Twitter })
    }
  ]

  return (
    <>
      <SettingsHeader title={t('Social Accounts')} subTitle={t('Manage your account connections')} />
      <Box mt='xs' maw={1000}>
        <SettingItem name='Referral' description={t('Add a referral code')}>
          <ReferralForm referrerCode={viewer?.referrerCode} />
        </SettingItem>
        <SettingItem name='Telegram' description={t('Add your telegram ID')}>
          <TelegramForm telegramId={viewer?.customData?.telegram?.id || ''} />
        </SettingItem>
        <SettingItem
          name={t('Connected accounts')}
          description={t('You have connected the following accounts to your profile')}
        >
          {viewer &&
            socialsNetworks.map((social, index) => (
              <Fragment key={social.id}>
                <Group justify='space-between' pt={index === 0 ? '0' : 'md'}>
                  <Group gap='sm'>
                    <social.logo className='h-full ' />
                    <Stack gap='0'>
                      <Text fw={700}>{social.name}</Text>
                      <Text size='xs' c='dimmed'>
                        {(viewer && (viewer as any)[social.accessor]) || t('Not Connected')}
                      </Text>
                    </Stack>
                  </Group>
                  <Button
                    data-testid={`settings-${social.name}-button`}
                    variant='transparent'
                    onClick={viewer && (viewer as any)[social.accessor] ? social.disconnect : social.connect}
                    loading={(isDisconnecting && social.name === socialConnect) || social.name === socialConnect}
                    disabled={isDisconnecting || social.name === socialConnect}
                  >
                    {viewer && (viewer as any)[social.accessor] ? t('Disconnect') : t('Connect')}
                  </Button>
                </Group>
                {index !== socialsNetworks.length - 1 && <Divider mt='md' />}
              </Fragment>
            ))}
        </SettingItem>
      </Box>
      <Modal className='max-w-[400px]' opened={!!socialConnect} onClose={() => setSocialConnect(null)}>
        <SocialConnect type={socialConnect as SocialType} close={() => setSocialConnect(null)} />
      </Modal>
    </>
  )
}

export default SocialView
