import { useOutletContext } from 'react-router-dom'
import ConnectedHomePage from '@pages/home/ConnectedHomePage'
import GuestHomePage from '@pages/home/GuestHomePage'

const HomePage = () => {
  const outletContext = useOutletContext<{ isAuthenticated: boolean }>()
  return outletContext?.isAuthenticated ? <ConnectedHomePage /> : <GuestHomePage />
}

export default HomePage
