import { Link } from 'react-router-dom'
import { TenantImageEnum, useAssetsContext } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { BackgroundImage, Badge, Box, Button, Stack, Text, Title } from 'summon-ui/mantine'

const MaintenancePage = () => {
  const t = useTranslation()
  const { getTenantImage } = useAssetsContext()
  return (
    <BackgroundImage
      src={getTenantImage(TenantImageEnum.LoginBg) || ''}
      className='h-screen px-4 flex justify-center items-center w-screen'
    >
      <Stack ta='center' gap='0'>
        <Title fz={{ xs: 'h1', md: '56' }} tt='uppercase'>
          {t('website under maintenance')}
        </Title>
        <Text maw='600' mx='auto'>
          {t(
            "Thank you for visiting the Game7 Portal. We are currently undergoing some maintenance to enhance your user experience. We'll be back shortly!"
          )}
        </Text>
        <Link to='https://discord.com/invite/g7dao' target='_blank'>
          <Button size='lg' mt='xl'>
            {t('Join discord')}
          </Button>
        </Link>
      </Stack>
      <Box className='fixed bottom-10'>
        <Link to='https://summon.xyz' target='_blank'>
          <Badge variant='outline' color='white' size='lg' fw={200}>
            {t('Powered by Summon')}
          </Badge>
        </Link>
      </Box>
    </BackgroundImage>
  )
}

export default MaintenancePage
