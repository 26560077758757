import { useTranslation } from 'summon-ui/intl'
import NoItemsMessage from '../NoItemsMessage'

const NO_MEDALS_TITLE = 'No Medals Earned'
const NO_MEDALS_SUBTITLE = "Medals are earned for truly exceptional contributions. We're sure you'll earn one soon!"

const Medals = () => {
  const t = useTranslation()

  return <NoItemsMessage title={t(NO_MEDALS_TITLE)} message={t(NO_MEDALS_SUBTITLE)} />
}

export default Medals
