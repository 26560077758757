import { EnhancedPaper, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Flex, Text, Stack, Image, Paper, rgba, useMantineTheme, Title, Space, Box } from 'summon-ui/mantine'

const rewards = [
  {
    tier: 'Legendary',
    leaderboard: 'Top 1%',
    estAvgBags: '19,000 BOOM Bags',
    imgUrl: '/images/lootdrop/boomlandRewards/legendary.png'
  },
  {
    tier: 'Rare',
    leaderboard: 'Next 2%',
    estAvgBags: '12,000 BOOM Bags',
    imgUrl: '/images/lootdrop/boomlandRewards/rare.png'
  },
  {
    tier: 'Uncommon',
    leaderboard: 'Next 7%',
    estAvgBags: '7,000 BOOM Bags',
    imgUrl: '/images/lootdrop/boomlandRewards/uncommon.png'
  },
  {
    tier: 'Common',
    leaderboard: 'Next 10%',
    estAvgBags: '6,000 BOOM Bags',
    imgUrl: '/images/lootdrop/boomlandRewards/common.png'
  }
]

const LootdropBoomlandRewardsList = () => {
  const t = useTranslation()
  const { colors } = useMantineTheme()
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const isMaxTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })

  return (
    <EnhancedPaper withBorder withBg radius='md' className='!border-yellow-3'>
      <Stack bg={rgba(colors.yellow[3], 0.15)} p='xl'>
        <Title order={2}>{t('Rewards')}</Title>
        <Space />
        <Stack gap='xl'>
          {rewards.map(({ tier, leaderboard, estAvgBags, imgUrl }, index) => (
            <Flex
              key={index}
              gap={isMaxTablet ? 'sm' : 'xl'}
              direction={isMaxTablet ? 'column' : 'row'}
              align='center'
              justify='space-evenly'
              className='z-0'
            >
              <Box pos='relative' px='xl' pt='xs'>
                <Paper
                  radius='100%'
                  className='-z-10'
                  pos='absolute'
                  top='25%'
                  left='30%'
                  h='60%'
                  w='40%'
                  style={{
                    boxShadow: `0 0 30px 25px ${index % 2 === 0 ? 'rgba(253, 181, 114, 0.5)' : 'rgba(239, 103, 196, 0.5)'} `
                  }}
                />
                <Image src={imgUrl} radius='sm' className='z-50' />
              </Box>
              <Stack gap='xs' w='100%'>
                <Flex justify={isMobile ? 'center' : 'space-between'} direction={isMobile ? 'column' : 'row'}>
                  <Text fw='bold' ta={isMaxTablet ? 'center' : 'start'}>
                    {tier} Tier
                  </Text>
                </Flex>
                <Paper p='lg' radius='md' bg={rgba(colors.dark[5], 0.4)}>
                  <Text ta={isMobile ? 'center' : 'start'} c='yellow.1'>
                    {leaderboard} Leaderboard
                  </Text>
                  <Space h={6} />
                  <Flex justify={isMobile ? 'center' : 'space-between'} gap={isMobile ? 'xs' : 'xl'}>
                    <Text c='yellow.1'>{t('Reward')}</Text>
                    <Flex gap='xs'>
                      {!isMobile && (
                        <Image src='/images/lootdrop/boomlandRewards/boomland-star.png' w={25} h={25} radius='sm' />
                      )}
                      <Text c='yellow.1'>Est. Avg. {estAvgBags}</Text>
                    </Flex>
                  </Flex>
                </Paper>
              </Stack>
            </Flex>
          ))}
        </Stack>
      </Stack>
    </EnhancedPaper>
  )
}

export default LootdropBoomlandRewardsList
