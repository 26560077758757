import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useViewer, useUpdateViewerSettings } from '@hooks'
import { useWeb3Modal } from '@web3modal/wagmi/react'

interface Props {
  callback?: () => void
}

const useLootdropOnboarding = ({ callback }: Props) => {
  const queryClient = useQueryClient()
  const { open: openConnectModal } = useWeb3Modal()
  const { viewer, isAuthenticated } = useViewer()
  const [slide, setSlide] = useState(0)
  const isFirstSlide = slide === 0
  const { updateViewerSettings, isUpdating: isCompletingOnboarding } = useUpdateViewerSettings()

  const refreshUserQuery = () => queryClient.invalidateQueries({ queryKey: ['GetUser'] })

  const completeLootdropOnboarding = async () => {
    await updateViewerSettings(
      { isLootDropOnboardingCompleted: true },
      {
        onSuccess: () => {
          refreshUserQuery()
          callback && callback()
        },
        onError: (error) => {
          console.error('Error completing Lootdrop onboarding: ', error)
        }
      }
    )
  }

  const handleClickActionButton = () => {
    const finalAction = isAuthenticated ? completeLootdropOnboarding : openConnectModal
    return isFirstSlide ? setSlide(1) : finalAction && finalAction()
  }

  return {
    slide,
    setSlide,
    isLootdropOnbardingCompleted: viewer?.isLootDropOnboardingCompleted,
    handleClickActionButton,
    isCompletingOnboarding
  }
}

export default useLootdropOnboarding
