import { Spinner, showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import { useViewer, useUpdateViewerSettings } from '@hooks'
import { SettingItem } from '../components/SettingItem'
import { SettingsHeader } from '../components/SettingsHeader'
import ImxForm, { type ImxFormValues } from './ImxForm'
import SolanaForm, { type SolanaFormValues } from './SolanaForm'

const walletSettings = TENANT_CONFIG?.features?.walletSettings

const WalletView = () => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const { updateViewerSettings, isUpdating } = useUpdateViewerSettings()

  const handleSubmit = (data: SolanaFormValues | ImxFormValues) => {
    updateViewerSettings(
      { ...data },
      {
        onSuccess: () => {
          showNotification({
            variant: 'success',
            message: 'Wallet updated successfully'
          })
        },
        onError: () => {
          showNotification({
            variant: 'danger',
            message: 'Unable to save wallet'
          })
        }
      }
    )
  }

  return (
    <>
      <SettingsHeader title={t('Wallets')} subTitle={t('Connect your various wallets to your tenant')} />
      {walletSettings?.solana && (
        <SettingItem
          name={t('Connected Solana Wallet')}
          description={t('You have connected the following Solana wallet to your account')}
        >
          {!viewer ? (
            <Center className='h-32'>
              <Spinner />
            </Center>
          ) : (
            <SolanaForm
              solanaWalletAddress={(viewer?.customData?.solanaWallet?.address as string) || ''}
              onSubmit={handleSubmit}
              isUpdating={isUpdating}
            />
          )}
        </SettingItem>
      )}
      {walletSettings?.immutableX && (
        <SettingItem
          name={t('Connected Immutable X Wallet')}
          description={t('You have connected the following Immutable X wallet to your account')}
        >
          {!viewer ? (
            <Center className='h-32'>
              <Spinner />
            </Center>
          ) : (
            <ImxForm
              immutableXAddress={(viewer?.customData?.immutableX?.address as string) || ''}
              onSubmit={handleSubmit}
              isUpdating={isUpdating}
            />
          )}
        </SettingItem>
      )}
    </>
  )
}

export default WalletView
