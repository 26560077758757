import { Route, Routes } from 'react-router-dom'
import { useDisclosure } from 'summon-ui/mantine'
import { ErrorBoundary } from '@components'
import ReferralsView from '@features/credits/views/Referrals.view'
import { useViewer } from '@hooks'
import CreditsOnboarding from './components/CreditsOnboarding'
import useCreditsOnboarding from './hooks/useCreditsOnboarding'
import CreditsView from './views/Credits.view'

export const CreditsViews = () => {
  const { isCreditsOnboardingCompleted } = useCreditsOnboarding({})
  const [opened, { close }] = useDisclosure(!isCreditsOnboardingCompleted)
  const { viewer } = useViewer()
  return (
    <>
      <Routes>
        <Route path='/' index element={<CreditsView />} errorElement={<ErrorBoundary />} />
        <Route path='/referrals' index element={<ReferralsView />} errorElement={<ErrorBoundary />} />
      </Routes>
      {viewer && <CreditsOnboarding open={opened} onClose={close} />}
    </>
  )
}
