import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'summon-ui/intl'
import { Box, Carousel, Flex, Loader, ScrollArea, Stack, Tabs, Text, Title } from 'summon-ui/mantine'
import { SocialSharingModal } from '@components'
import CTASection from '@components/Inventory/CTASection'
import InventorySlot from '@components/Inventory/InventorySlot'
import { TENANT_CONFIG } from '@config'
import { SlotType } from '@config/enum'
import useEquipItems from '@hooks/inventory/useEquipItems'
import useItems from '@hooks/inventory/useItems'
import Avatar3D from '@modules/avatar'
import { usePreviewItems } from '@modules/avatar/store/usePreviewItems.store'

const InventoryDrawer = () => {
  const { groupedItems, isLoading, mergeGroupWithKeys } = useItems({ preload: true })
  const { addOrRemovePreviewItem, previewItems } = usePreviewItems()
  const items = mergeGroupWithKeys(groupedItems, TENANT_CONFIG.sortedSlots)
  const { equipItems, isEquippingItems, isEquipSuccess } = useEquipItems({})
  const navigate = useNavigate()
  const t = useTranslation()
  return (
    <>
      <Box visibleFrom='md'>
        {isEquipSuccess || isEquippingItems ? (
          <Stack h='90vh' align='center' justify='center' gap='lg' ta='center'>
            <Avatar3D className='max-h-[400px]' />
            <Stack gap='0' align='center'>
              <Title>{isEquippingItems ? t('Equipping') : t('Success')}</Title>
              <Text maw='300'>
                {isEquippingItems
                  ? t('Your avatar is being equipped')
                  : `${t('Your avatar was successfully equipped')}. ${t('Close this menu and check it out')}.`}
              </Text>
              {isEquippingItems && <Loader mt='lg' />}
            </Stack>
          </Stack>
        ) : (
          Object.entries(items).map(([key, value]) => (
            <Stack key={key} mt='md'>
              <Text>{SlotType[Number(key)]}</Text>
              <Flex wrap='wrap' gap='sm'>
                {value ? (
                  value.map((item) => (
                    <InventorySlot
                      key={item.tokenId}
                      isLoading={isLoading}
                      isActive={!!previewItems.find((previewItem) => previewItem.tokenId === item.tokenId)}
                      image={item.imageUrl}
                      onClick={() => addOrRemovePreviewItem(item)}
                    />
                  ))
                ) : (
                  <InventorySlot isLoading={false} />
                )}
              </Flex>
            </Stack>
          ))
        )}
      </Box>
      <Tabs hiddenFrom='md' defaultValue={TENANT_CONFIG.sortedSlots[0].toString()}>
        <ScrollArea>
          <Tabs.List my='sm' w={700}>
            {TENANT_CONFIG.sortedSlots.map((key) => (
              <Tabs.Tab value={String(key)} key={key}>
                {SlotType[key]}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </ScrollArea>
        {TENANT_CONFIG.sortedSlots.map((key) => (
          <Tabs.Panel value={String(key)} key={key}>
            <Carousel
              withControls={false}
              align='start'
              dragFree
              maw='100vw'
              slideSize={{ xs: '25%', sm: '20%' }}
              slideGap='sm'
              slidesToScroll={5}
            >
              {groupedItems?.[key] ? (
                groupedItems[key].map((item) => (
                  <Carousel.Slide key={item.tokenId}>
                    <InventorySlot
                      isLoading={isLoading}
                      isActive={!!previewItems.find((previewItem) => previewItem.tokenId === item.tokenId)}
                      image={item.imageUrl}
                      onClick={() => addOrRemovePreviewItem(item)}
                    />
                  </Carousel.Slide>
                ))
              ) : (
                <InventorySlot isLoading={false} />
              )}
            </Carousel>
          </Tabs.Panel>
        ))}
      </Tabs>
      <CTASection
        displayBack={isEquipSuccess}
        onClose={() => navigate('/')}
        onBack={() => navigate('/')}
        onEquip={equipItems}
        isEquipping={isEquippingItems}
      >
        {isEquipSuccess && <SocialSharingModal variant='avatar' asButton buttonText='Share' />}
      </CTASection>
    </>
  )
}

export default InventoryDrawer
