import { Icon, Logo, showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Title, Text, Button, Flex, Stack, Box } from 'summon-ui/mantine'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import CharmverseIcon from '@assets/icons/socials/charmverseCircle.svg?react'
import DiscordIcon from '@assets/icons/socials/discord.svg?react'
import TwitterIcon from '@assets/icons/socials/twitter.svg?react'
import { useCreateCharmverseMemberMutation } from '@generated/generates'
import { useViewer } from '@hooks'
import { handleDiscordAuth } from '@utils/connectors/discord'
import { handleTwitterAuth } from '@utils/connectors/twitter'
import { SocialType } from './types'

const CHARMVERSE_DESCRIPTION =
  'Connect to Charmverse to unlock more potential quests, see future proposals and have your voice heard.'
const DISCORD_DESCRIPTION =
  'Connect your Discord to access exclusive benefits across the platform and level up your experience'
const TWITTER_DESCRIPTION = 'Connect your X account to unlock platform-wide benefits and level up your adventure.'
const BLOCKER_WARNING = 'Please ensure your pop-up blocker is disabled to successfully connect your account.'

type SocialConnectProps = {
  type: SocialType
  close: () => void
}

const SocialConnect = ({ type, close }: SocialConnectProps) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const queryClient = useQueryClient()
  const { mutate: createCharmverseMember, isPending: isConnectingToCharmverse } = useCreateCharmverseMemberMutation(
    graphqlClient(),
    {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['GetUser'] })
        close()
      },
      onError(error) {
        console.error(error)
        showNotification({
          variant: 'danger',
          message: t('Unable to Connect Charmverse Account')
        })
        close()
      }
    }
  )

  const handleCharmverseAuth = () => {
    createCharmverseMember({})
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const ConnectToIcon = (props: any) =>
    ({
      [SocialType.Charmverse]: <CharmverseIcon {...props} />,
      [SocialType.Discord]: <DiscordIcon {...props} />,
      [SocialType.Twitter]: <TwitterIcon {...props} />
    })[type]

  const action = () => {
    switch (type) {
      case SocialType.Charmverse:
        handleCharmverseAuth()
        break
      case SocialType.Discord:
        handleDiscordAuth(viewer?.id as string)
        break
      case SocialType.Twitter:
        handleTwitterAuth(viewer?.id as string)
        break
      default:
        break
    }
  }

  const descriptionByType = () => {
    switch (type) {
      case SocialType.Charmverse:
        return t(CHARMVERSE_DESCRIPTION)
      case SocialType.Discord:
        return t(DISCORD_DESCRIPTION)
      case SocialType.Twitter:
        return t(TWITTER_DESCRIPTION)
      default:
        break
    }
  }

  return (
    <Stack gap='xl' p='sm'>
      <Flex w='100%' align='center' justify='center'>
        <ConnectToIcon data-testid={`connect-social-${type}-image`} className='size-16' />
        <Icon name='DotsHorizontal' className='size-8 mx-3' />
        <Box>
          <Logo size='xl' />
        </Box>
      </Flex>
      <Stack gap='xs'>
        <Title data-testid='connect-social-title-label' ta='center' order={3} fw={700} mb='sm'>
          {t('Connect')} {type}
        </Title>
        <Text data-testid='connect-social-description-label' ta='center'>
          {descriptionByType()}
        </Text>
        <Text data-testid='connect-social-blocker-label' ta='center'>
          {BLOCKER_WARNING}
        </Text>
      </Stack>
      <Flex justify='space-between'>
        <Button data-testid='connect-social-cancel-button' variant='outline' onClick={close}>
          {t('Cancel')}
        </Button>
        <Button data-testid='connect-social-connect-button' onClick={action} loading={isConnectingToCharmverse}>
          {t('Connect')}
        </Button>
      </Flex>
    </Stack>
  )
}

export default SocialConnect
