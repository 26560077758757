import { EnhancedPaper, TenantImageEnum, useAssetsContext, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Flex, Text, Stack, Image, Paper, rgba, useMantineTheme, Title, Space } from 'summon-ui/mantine'
import type { Reward } from '@generated/generates'
import { calculateAllRewardsOdds, roundOddsObject, calculateTotalRewardsUnits, formatOdds } from '../helpers'

interface LootdropRewardsProps {
  lootdropTotalMultiplier: number
  participantCount: number
  userMultiplier: number
  rewards: Reward[]
}

const LootdropRewardsList = ({
  rewards,
  lootdropTotalMultiplier,
  userMultiplier,
  participantCount
}: LootdropRewardsProps) => {
  const t = useTranslation()
  const { colors } = useMantineTheme()
  const { getTenantImage } = useAssetsContext()
  const misteryBoxImage = getTenantImage(TenantImageEnum.MisteryBox)
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const isMaxTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const odds = calculateAllRewardsOdds(rewards, lootdropTotalMultiplier, userMultiplier)
  const roundedOdds = roundOddsObject(odds)
  const displayOdds = participantCount > calculateTotalRewardsUnits(rewards)

  return (
    <EnhancedPaper withBorder withBg radius='md' className='!border-yellow-3'>
      <Stack bg={rgba(colors.lime[3], 0.15)} px='lg' pt='lg' pb='sm'>
        <Title order={2}>{t('Rewards')}</Title>
        <Text>
          {t(
            'The higher your multiplier, the better your odds of receiving legendary loot. These prizes will be available as soon as the LootDrop closes. May the odds be ever in your favor!'
          )}
        </Text>
        <Space />
        <Stack gap='xl'>
          {rewards
            // FIXME: this is a workaround to sort the rewards by maxUnitDistributionLimit, we will move this to backend later
            .sort(
              (reward1, reward2) =>
                (reward1.rewardIntegration?.constraints?.maxUnitDistributionLimit || 5000) -
                (reward2?.rewardIntegration?.constraints?.maxUnitDistributionLimit || 5000)
            )
            .map(({ id, title, description, imageUrl, rewardIntegration }, index) => (
              <Flex
                key={index}
                gap={isMaxTablet ? 'sm' : 'xl'}
                direction={isMaxTablet ? 'column' : 'row'}
                align='center'
              >
                <Image src={imageUrl || misteryBoxImage} radius='sm' w={140} />
                <Stack gap='xs' w='100%'>
                  <Flex justify={isMobile ? 'center' : 'space-between'} direction={isMobile ? 'column' : 'row'}>
                    <Text fw='bold' ta={isMaxTablet ? 'center' : 'start'}>
                      {title}
                    </Text>
                    {displayOdds && (
                      <Text c='yellow.1' ta={isMaxTablet ? 'center' : 'end'}>
                        {!rewardIntegration?.constraints?.maxUnitDistributionLimit
                          ? t('Everyone Else')
                          : `${formatOdds(roundedOdds[id])}% ${t('Odds')}`}
                      </Text>
                    )}
                  </Flex>
                  <Paper p='lg' radius='md' bg={rgba(colors.dark[5], 0.4)}>
                    {rewardIntegration?.constraints?.maxUnitDistributionLimit && (
                      <Flex justify='space-between'>
                        <Text c='yellow.1'>{t('Number of Boxes')}</Text>
                        <Text>{rewardIntegration?.constraints?.maxUnitDistributionLimit}</Text>
                      </Flex>
                    )}
                    <Flex justify='space-between'>
                      <Text c='yellow.1'>{t('Reward')}</Text>
                      <Flex gap={4}>
                        <Text c='yellow.1'>{description}</Text>
                      </Flex>
                    </Flex>
                  </Paper>
                </Stack>
              </Flex>
            ))}
        </Stack>
      </Stack>
    </EnhancedPaper>
  )
}

export default LootdropRewardsList
