import { useState } from 'react'
import useAvatarMetadata from '@hooks/inventory/useAvatarMetadata'
import CompletedButton from '../components/CompletedButton'
import StartButton from '../components/StartButton'
import LineaGuildFormModal from './LineaGuildFormModal'

interface Props {
  isCompleted: boolean
  isDisabled?: boolean
}

const LineaGuild = ({ isCompleted, isDisabled = false }: Props) => {
  const [isCurrentlyCompleted, setIsCurrentlyCompleted] = useState(isCompleted)
  const [isLineaGuildModalOpen, setIsLineaGuildModalOpen] = useState(false)
  const { refetchAvatarMetadata } = useAvatarMetadata({})

  const handleSuccess = () => {
    setIsCurrentlyCompleted(true)
    refetchAvatarMetadata()
  }

  const handleStartTask = () => {
    setIsLineaGuildModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsLineaGuildModalOpen(false)
  }

  return (
    <>
      {isCurrentlyCompleted ? (
        <CompletedButton />
      ) : (
        <StartButton onStartTask={handleStartTask} isDisabled={isDisabled} />
      )}
      <LineaGuildFormModal isOpen={isLineaGuildModalOpen} onSuccess={handleSuccess} onFinish={handleCloseModal} />
    </>
  )
}

export default LineaGuild
