import { useTranslation } from 'summon-ui/intl'
import { Stack, Text, Title } from 'summon-ui/mantine'
import { ConnectButton } from '@components'
import BoxTemplate from '../BoxTemplate/BoxTemplate'

interface Props {
  isUserAuthenticated: boolean
}

const G7Box = ({ isUserAuthenticated }: Props) => {
  const t = useTranslation()

  return (
    <BoxTemplate
      description={
        <Stack gap={0}>
          <Text>{t('Unlocks rare rewards with a')}</Text>
          <Title order={2} fw='bold'>
            G7 BOX
          </Title>
        </Stack>
      }
      {...(!isUserAuthenticated ? { ActionCTA: <ConnectButton /> } : {})}
    />
  )
}

export default G7Box
