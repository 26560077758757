import { Text, Stack, Box, Title } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import { Tenant } from '@config/enum'
import Avatar3D from '@modules/avatar'

const TITLE = 'Share your Avatar'
const DESCRIPTION = 'Display your cool new avatar to your friends over social media.'
const NOTE = 'NOTE: You need to download your avatar first so you can attach it.'

const isGame7Avatar = TENANT_CONFIG.name === Tenant.G7

const ShareAvatar = () => {
  return (
    <Stack gap='xs'>
      <Box className='aspect-square rounded-full mx-auto w-[200px] overflow-hidden'>
        <Avatar3D
          centerProps={{
            position: [0, isGame7Avatar ? -1.2 : 0, 0],
            zoom: 12
          }}
        />
      </Box>
      <Stack gap='xs' className='p-4'>
        <Title ta='center' order={2}>
          {TITLE}
        </Title>
        <Text ta='center'>
          {DESCRIPTION}{' '}
          <Text span ta='center' fw='bold'>
            {NOTE}
          </Text>
        </Text>
      </Stack>
    </Stack>
  )
}

ShareAvatar.displayName = 'ShareAvatar'
export default ShareAvatar
