import { useGqlQuery } from '@api/gql/gqlServices'
import { GetUserCreditsBalanceDocument, type GetUserCreditsBalanceQuery } from '@generated/generates'
import { useViewer } from '@hooks'

const getRandomOrZero = () => {
  return Math.random() < 0.5 ? 0 : Math.floor(Math.random() * 1000)
}

// TODO: This hook calls the credits balance query to mock backend behaviour and returns a random number
const useUserTokensBalance = () => {
  const { viewer } = useViewer()

  const { isLoading, isSuccess, isError } = useGqlQuery<GetUserCreditsBalanceQuery>(
    GetUserCreditsBalanceDocument,
    {},
    {
      enabled: !!viewer
    }
  )

  return {
    balance: getRandomOrZero(),
    isLoading,
    isSuccess,
    isError
  }
}

export default useUserTokensBalance
