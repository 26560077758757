import { useState } from 'react'
import { Modal, Button, Box, Stack, Flex, useDisclosure } from 'summon-ui/mantine'
import type { SharingItem } from './types'
import useShare from './useShare'

interface Props {
  variant: SharingItem
  isOpened?: boolean
  asButton?: boolean
  buttonText?: string
}

const SocialSharingModal = ({ variant, isOpened = false, asButton = true, buttonText = 'Open modal' }: Props) => {
  const [opened, { open, close }] = useDisclosure(isOpened)
  const [isReadyToShare, setIsReadyToShare] = useState(variant === 'avatar' ? false : true)
  const { getSharingInfo, renderSharingButton } = useShare(variant)
  const { Component, downloadSharingImg } = getSharingInfo()

  const handleDownloadSharingImg = () => {
    downloadSharingImg && downloadSharingImg()
    setIsReadyToShare(true)
  }

  const handleClose = () => {
    setIsReadyToShare(false)
    close()
  }

  return (
    <>
      <Modal withOverlay opened={opened} onClose={close} withCloseButton={false}>
        <Box className='p-2'>
          <Stack gap='lg'>
            {Component}
            <Stack gap='md'>
              <Flex gap='md'>
                <Button onClick={handleClose} fullWidth variant='outline' color='gray'>
                  Close
                </Button>
                {isReadyToShare ? (
                  renderSharingButton('X')
                ) : (
                  <Button onClick={handleDownloadSharingImg} fullWidth>
                    Download
                  </Button>
                )}
              </Flex>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      {asButton && <Button onClick={open}>{buttonText}</Button>}
    </>
  )
}

export default SocialSharingModal
