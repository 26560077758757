import { Center, Overlay } from 'summon-ui/mantine'
import { AssetsPrefix } from '@config/enum'

type VideoOverlayProps = {
  onClose: () => void
  sourceUrl: string
}

const VideoOverlay = ({ onClose, sourceUrl }: VideoOverlayProps) => {
  return (
    <Overlay pos='fixed' onClick={onClose}>
      <Center className='h-screen flex align-center rounded-full'>
        <video width='500' height='500' autoPlay muted onEnded={onClose}>
          <source src={`${AssetsPrefix.G7}${sourceUrl}`} type='video/webm' />
        </video>
      </Center>
    </Overlay>
  )
}

export default VideoOverlay
