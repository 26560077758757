import { useEffect, useRef } from 'react'
import { useTour } from '@reactour/tour'

const INITIAL_STEP = 0

const useStartTutorial = (hasCompletedTutorial?: boolean, isOnboardingCompleted?: boolean) => {
  const tour = useTour()

  const { setIsOpen: setStartTutorial, setCurrentStep, steps } = tour
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (hasCompletedTutorial === false && isOnboardingCompleted === true) {
      const checkForElement = () => {
        const selector = steps[INITIAL_STEP].selector as string
        const element = document.querySelector<HTMLElement>(selector)

        if (element) {
          setCurrentStep(INITIAL_STEP)
          setStartTutorial(true)
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
          }
        }
      }

      intervalRef.current = setInterval(checkForElement, 500)

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [hasCompletedTutorial, isOnboardingCompleted])
}

export default useStartTutorial
