import { useTranslation } from 'summon-ui/intl'
import { Modal, Stack, Text, TextInput, Group, Button, useForm } from 'summon-ui/mantine'
import useCheckTaskCompletion from '@features/quests/hooks/useCheckTaskCompletion'
import { useViewer } from '@hooks'

export interface SolanaFormValues {
  solanaWallet?: string
}

interface Props {
  opened: boolean
  onClose: () => void
}

const ActionSolanaModal = ({ opened, onClose }: Props) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const { updateUserSettings, isUpdatingUserSettings } = useCheckTaskCompletion({})

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<SolanaFormValues>({
    initialValues: { solanaWallet: viewer?.customData?.solanaWallet?.address || '' }
  })

  const handleSubmit = async (data: typeof values) => {
    try {
      updateUserSettings({ solanaWalletAddress: data?.solanaWallet || '' })
    } finally {
      setTimeout(() => {
        onClose()
      }, 3000)
    }
  }

  return (
    <Modal title='Enter Solana Wallet' opened={opened} onClose={onClose} maw={430}>
      <form onSubmit={onSubmitForm(handleSubmit)}>
        <Stack>
          <Text>{t('Save your Solana wallet to complete this action and earn rewards')}</Text>

          <TextInput
            {...getInputProps('solanaWallet')}
            label='Solana Wallet'
            placeholder={t('Enter your Solana Wallet')}
          />

          <Group justify='space-between' w='100%' mt='sm'>
            <Button onClick={onClose} variant='outline' miw={115}>
              {t('Cancel')}
            </Button>
            <Button type='submit' loading={isUpdatingUserSettings} disabled={isUpdatingUserSettings} miw={115}>
              {t('Submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default ActionSolanaModal
