/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterProvider } from '@features/marketplace/contexts/FilterContext'

export const withFilter = (WrappedComponent: React.ComponentType) =>
  (function withFilter(props: any) {
    return (
      <FilterProvider>
        <WrappedComponent {...props} />
      </FilterProvider>
    )
  })
