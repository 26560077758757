import { useEffect, useState } from 'react'
import { useReferral } from '@hooks'

const useReferralModal = () => {
  const [referralModal, setReferralModal] = useState(false)
  const { addReferral, referralError, referralCode, isPending } = useReferral()
  useEffect(() => {
    if (referralCode) {
      addReferral(referralCode)
        ?.then(() => {
          setReferralModal(false)
        })
        .catch(() => {
          if (referralError !== 'User has already been referred.' && referralError) {
            setReferralModal(true)
          }
        })
    } else {
      setReferralModal(true)
    }
  }, [referralCode])
  return {
    referralModal,
    setReferralModal,
    storedReferralCode: referralCode,
    isPending,
    referralError,
    addReferral
  }
}

export default useReferralModal
