import { Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, Card, Flex, rem, Text, Title, Tooltip, Slider } from 'summon-ui/mantine'
import { STAKE_PERIOD_MARKS } from './constants'

interface Props {
  onSelect: (period: number) => void
  stakePeriod: number
}

const RewardsInfo = ({ stakePeriod, onSelect }: Props) => {
  const t = useTranslation()

  return (
    <Box>
      <Flex align='center' mb='xs'>
        <Title order={5}>{t('Lockup period')}</Title>
        <Tooltip
          withArrow
          multiline
          label={t(
            "A lockup period means that you will not be able to withdraw your tokens for the entire lockup period you choose. In exchange, you'll get more Diamond rewards each day."
          )}
          w={200}
          position='right'
        >
          <Box>
            <Icon name='InfoCircle' />
          </Box>
        </Tooltip>
      </Flex>
      <Card radius='md' withBorder p='8'>
        <Flex gap='4' align='center'>
          <Icon name='InfoCircle' /> <Text>{t('You could be earning 2x-4x more Diamonds by locking up.')}</Text>
        </Flex>
      </Card>
      <Slider
        value={stakePeriod}
        onChange={onSelect}
        my='md'
        label={(val) => STAKE_PERIOD_MARKS.find((mark) => mark.value === val)?.label ?? ''}
        step={50}
        marks={STAKE_PERIOD_MARKS}
        color='yellow.6'
        styles={{
          bar: { boxShadow: '0px 0px 13.737px 2.29px #E43B3B' },
          thumb: {
            width: rem(20),
            height: rem(20),
            border: 0,
            backgroundColor: 'white',
            boxShadow: '0px 0px 13.737px 5px #EAAA08'
          }
        }}
      />
    </Box>
  )
}

export default RewardsInfo
