import { useTranslation } from 'summon-ui/intl'
import { Stack, Text, Button, Title } from 'summon-ui/mantine'

export const WL_TITLE = 'Join Now!'
export const WL_SUB_TITLE =
  'To be granted access to Game7 nation, you need to join our Discord to get started on your journey into our Game7 Nation'

const LoginError = () => {
  const t = useTranslation()
  return (
    <Stack align='center' w='100%'>
      <Title c='white'>{t(WL_TITLE)}</Title>
      <Text ta='center' c='white'>
        {t(WL_SUB_TITLE)}
      </Text>
      <Button bg='indigo'>Discord</Button>
    </Stack>
  )
}
export default LoginError
