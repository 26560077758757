import { Hosts } from '@config/enum'

export const LOCAL_DEV_STRING = '.xps-user-portal.pages.dev'

export const isDev = import.meta.env.DEV || window.location.hostname.includes(LOCAL_DEV_STRING)

export const isDevelop = window?.location?.href.includes('develop') || isDev

export const isStaging = window?.location?.host === Hosts.G7Staging

export const isProd = window?.location?.host === Hosts.G7
