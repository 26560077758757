import { useState, useMemo } from 'react'
import { G7Icon, Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, Button, Card, Checkbox, Flex, NumberInput, Space, Stack, Text, Title } from 'summon-ui/mantine'
import { ConnectButton } from '@components'
import { useViewer } from '@hooks'
import { useUserTokensBalance } from '@hooks/balances'
import LockupPeriod from './LockupPeriod'
import RewardsInfo from './RewardsInfo'
import { STAKE_PERIOD_MARKS } from './constants'

const StakeTokensForm = () => {
  const t = useTranslation()
  const { viewer, isAuthenticated } = useViewer()
  const [isChecked, setIsChecked] = useState(false)
  const [stakePeriod, setStakePeriod] = useState(50)
  const [stakeAmount, setStakeAmount] = useState<number | undefined>()
  const { balance, isLoading } = useUserTokensBalance()

  const handleSelectPeriod = (period: number) => {
    setStakePeriod(period)
    setIsChecked(false)
  }

  const selectedPeriod = useMemo(() => STAKE_PERIOD_MARKS.find((mark) => mark.value === stakePeriod), [stakePeriod])
  const lockupMultiplier = useMemo(() => {
    switch (selectedPeriod?.months) {
      case 12:
        return 1.5
      case 24:
        return 3
      default:
        return 1
    }
  }, [selectedPeriod])

  const levelMultiplier = viewer?.level ? +(viewer.level * 0.1 - 0.1).toFixed(2) : 0
  const totalMultiplier = lockupMultiplier + levelMultiplier
  const diamonds = +(totalMultiplier * (Number(stakeAmount) || 0)).toFixed(2)

  // TODO: get calculated diamonds
  return (
    <Card withBorder radius='md' bg='black' p='30'>
      <Stack ta='center' gap='xs'>
        <Title>{t('Stake Tokens')}</Title>
        <Text>{t('Stake or Lockup G7 tokens to earn Diamonds.')}</Text>
      </Stack>
      <Space h='lg' />
      <Stack gap='lg'>
        <Stack gap='4'>
          <Flex justify='space-between' align='center'>
            <Text size='sm'>{t('Tokens to stake')}</Text>
            {!!viewer && (
              <Flex gap='xs'>
                <Text size='sm'>{t('Balance')}:</Text>
                {isLoading ? <Spinner size='xs' /> : `${balance} G7`}
              </Flex>
            )}
          </Flex>
          <NumberInput
            size='lg'
            hideControls
            labelProps={{ mb: 'xs' }}
            leftSection={<G7Icon name='g7-token-mark-4' pfx='fak' color='red' size='xl' />}
            value={stakeAmount}
            onChange={(value) => setStakeAmount(Number(value))}
            rightSection={
              <Box mr='md'>
                {balance && balance > 0 ? (
                  <Button variant='transparent' fw='bold' p='0' c='red.5' onClick={() => setStakeAmount(balance)}>
                    MAX
                  </Button>
                ) : null}
              </Box>
            }
            placeholder={t('Token amount')}
          />
        </Stack>
        <LockupPeriod onSelect={handleSelectPeriod} stakePeriod={stakePeriod} />
        <RewardsInfo
          diamonds={isAuthenticated ? diamonds.toString() : '-'}
          totalMultiplier={isAuthenticated ? totalMultiplier.toString() + 'x' : '-'}
          levelMultiplier={levelMultiplier.toString()}
          lockupMultiplier={lockupMultiplier.toString()}
        />
        {selectedPeriod && (
          <Flex gap='md'>
            <Checkbox checked={isChecked} onChange={(event) => setIsChecked(event.currentTarget.checked)} mt='4' />
            <Box>
              <Title order={5}>{t('Lockup Agreement')}</Title>
              <Text>
                {t(
                  'By clicking the Lockup button below, I understand and accept that my tokens will be locked and inaccessible for {selectedPeriodMonths} months, with a 72-hour cooldown period when unstaking.',
                  { selectedPeriodMonths: selectedPeriod?.months }
                )}
              </Text>
            </Box>
          </Flex>
        )}
        {isAuthenticated ? (
          <Button fullWidth disabled={!stakePeriod || !isChecked || !stakeAmount}>
            {t('Lockup')}
          </Button>
        ) : (
          <ConnectButton />
        )}
      </Stack>
    </Card>
  )
}

export default StakeTokensForm
