import type { PropsWithChildren } from 'react'
import { G7Icon } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { alpha, Box, Button, Card, Center, Flex, Image, Paper, Stack, Text, Title } from 'summon-ui/mantine'
import DailyClaimAction from './DailyDiamonds/DailyClaimAction'

const TEMP_DISABLE_G7_BOX_CARD = true

type Props = {
  isSmallVariant?: boolean
}

const ActionCards = ({ isSmallVariant = false }: Props) => {
  const t = useTranslation()
  return (
    <Stack gap='md' maw={isSmallVariant ? '100%' : 300}>
      {!TEMP_DISABLE_G7_BOX_CARD && (
        <ActionCard
          isSmallVariant={isSmallVariant}
          image='/images/diamonds/box.png'
          title={t('Buy a G7 box')}
          description={`${t('Unlocks rare and tradable items')}. ${t('Learn more')}.`}
        >
          <Button mt='sm' fullWidth variant='white'>
            {t('Buy for')}
            <FormattedMessage
              id='diamondAndAmount'
              values={{
                space: <>&nbsp;</>,
                currencyIcon: <G7Icon className='ml-2' name='diamonds' pfx='fak' />,
                currencyAmount: 100
              }}
            />
          </Button>
        </ActionCard>
      )}
      <ActionCard
        isSmallVariant={isSmallVariant}
        image='/images/diamonds/diamond.png'
        title={t('Daily diamond box')}
        description={t('Claim to get {count} - {maxCount} free diamonds', { count: 10, maxCount: 10000 })}
      >
        <DailyClaimAction />
      </ActionCard>
    </Stack>
  )
}

type ActionCardProps = {
  isSmallVariant: boolean
  title: string
  description: string
  image: string
} & PropsWithChildren

const ActionCard = ({ isSmallVariant, title, description, image, children }: ActionCardProps) => {
  return isSmallVariant ? (
    <Center>
      <Paper bg={alpha('var(--mantine-color-gray-8)', 0.9)}>
        <Flex gap='sm' align='center' w='100%' px='sm'>
          <Image src={image} h='64' />
          <Box w='100%'>
            <Title size='md'>{title}</Title>
          </Box>
          <Box w={180}>{children}</Box>
        </Flex>
      </Paper>
    </Center>
  ) : (
    <Card radius='md' bg={alpha('var(--mantine-color-gray-8)', 0.9)}>
      <Flex gap='sm' align='center' mb='sm'>
        <Image src={image} h='64' />
        <Box>
          <Title order={4}>{title}</Title>
          <Text>{description}</Text>
        </Box>
      </Flex>
      {children}
    </Card>
  )
}

export default ActionCards
