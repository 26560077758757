import { useTranslation } from 'summon-ui/intl'
import { Modal, Button } from 'summon-ui/mantine'
import { SocialConnect } from '@components'
import type { SocialType } from '@components/SocialConnect/types'
import useCheckTaskCompletion from '@features/quests/hooks/useCheckTaskCompletion'
import type { TaskWithQuestId } from '@features/quests/types'

interface Props {
  task: TaskWithQuestId
  checkStatus: () => void
}

const ActionButtonCheck = ({ task }: Props) => {
  const t = useTranslation()
  const {
    handleCheckTaskCompletion,
    isCheckTaskCompletionLoading,
    isCheckStatusLoading,
    socialConnect,
    setSocialConnect
  } = useCheckTaskCompletion({ task })

  return (
    <>
      <Button
        onClick={handleCheckTaskCompletion}
        loading={isCheckTaskCompletionLoading || isCheckStatusLoading}
        disabled={isCheckTaskCompletionLoading || isCheckStatusLoading}
        variant='outline'
      >
        {t('Check Status')}
      </Button>

      <Modal maw={400} opened={!!socialConnect} onClose={() => setSocialConnect(null)}>
        <SocialConnect type={socialConnect as SocialType} close={() => setSocialConnect(null)} />
      </Modal>
    </>
  )
}

export default ActionButtonCheck
