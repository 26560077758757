import { create } from 'zustand'
import type { SlotType } from '@config/enum'
import type { InventoryItem } from '@generated/generates'
import { useCameraStorage } from './camera.store'

type PreviewItemsState = {
  previewItems: InventoryItem[] | []
  addOrRemovePreviewItem: (v: InventoryItem) => void
  setPreviewItems: (v: InventoryItem[], keepPreviousData?: boolean) => void
}

export const usePreviewItems = create<PreviewItemsState>()((set, get) => ({
  previewItems: [],

  setPreviewItems: (v) => set({ previewItems: v }),

  addOrRemovePreviewItem: (item: InventoryItem) => {
    const isItemInPreview = get().previewItems.some((i) => i.tokenId === item.tokenId)

    useCameraStorage.getState().setCameraMode(item.slotType as SlotType)
    if (isItemInPreview) {
      // If item exists, remove it
      set({ previewItems: get().previewItems.filter((i) => i.tokenId !== item.tokenId) })
    } else {
      // Remove incompatible items and add the new one
      set({
        previewItems: [...get().previewItems.filter((i) => i.slotType !== item.slotType), item]
      })
    }
  }
}))
