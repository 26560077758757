import { useEffect, useMemo } from 'react'
import { useGqlQuery } from '@api/gql/gqlServices'
import { GetDnaItemsDocument } from '@generated/generates'
import type { GetDnaItemsQuery, InventoryItem, Maybe } from '@generated/generates'
import useBaseSkins from '@hooks/inventory/useBaseSkins'
import { useGLTF } from '@react-three/drei'
import useAvatarMetadata from './useAvatarMetadata'

export enum ItemTypes {
  DNA = 'dna',
  Traits = 'traits'
}

type UseItemsProps = {
  itemsType?: ItemTypes
  preload?: boolean
}
const useItems = ({ itemsType, preload }: UseItemsProps) => {
  const { avatarMetadata, isAvatarMetadataLoading } = useAvatarMetadata({
    enabled: !itemsType
  })
  const { baseSkins, isLoading } = useBaseSkins({
    enabled: !!(itemsType === ItemTypes.DNA)
  })
  const { data: dnaItemsData, isLoading: isDnaItemsLoading } = useGqlQuery<GetDnaItemsQuery>(
    GetDnaItemsDocument,
    {},
    {
      enabled: itemsType === ItemTypes.Traits,
      staleTime: Infinity
    }
  )

  const { items, loading } = useMemo(() => {
    switch (itemsType) {
      case ItemTypes.DNA:
        return { items: baseSkins || [], loading: isLoading }
      case ItemTypes.Traits:
        return { items: dnaItemsData?.getDNAItems.items || [], loading: isDnaItemsLoading }
      default:
        return {
          items: avatarMetadata?.inventory.items || [],
          loading: isAvatarMetadataLoading
        }
    }
  }, [itemsType, dnaItemsData, avatarMetadata, baseSkins])

  const groupedItems = (items as InventoryItem[])?.reduce(
    (acc: Record<number, InventoryItem[]>, item: InventoryItem) => {
      const key: Maybe<number | undefined> = item.slotType

      // Ensure the key is neither null nor undefined
      if (key !== null && key !== undefined) {
        // If this slot type doesn't exist yet, initialize it as an empty array
        if (!acc[key]) {
          acc[key] = []
        }
        // Push the item into the array for this slotType
        acc[key].push(item)
      }

      return acc
    },
    {} as Record<number, InventoryItem[]>
  )
  useEffect(() => {
    if (items && preload) {
      useGLTF.preload(items.map((item) => item.glbUrl as string))
    }
  }, [items, preload])

  const mergeGroupWithKeys = (
    originalObject: Record<number, InventoryItem[]>,
    keysArray: number[]
  ): Record<number, InventoryItem[] | null> => {
    const mergedObject: Record<number, InventoryItem[] | null> = {}

    keysArray.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(originalObject, key)) {
        mergedObject[key] = originalObject[key] // Keep the value if it exists
      } else {
        mergedObject[key] = null // Set a default value (empty object in this case)
      }
    })

    return mergedObject
  }

  return {
    items: items as InventoryItem[],
    groupedItems,
    isLoading: loading,
    mergeGroupWithKeys
  }
}

export default useItems
