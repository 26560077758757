import { useState } from 'react'

interface Props {
  onConversionSuccess?: () => void
}

const useConvertCredits = ({ onConversionSuccess }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const convertCreditsMutation = () => {
    setIsLoading(true) // Set loading to true when mutation starts

    // Simulate API call delay
    setTimeout(() => {
      // Mocked conversion behavior

      if (onConversionSuccess) {
        onConversionSuccess()
      }
    }, 3000) // Simulating a 3-second delay
  }

  return {
    convertCreditsMutation,
    isConvertCreditsMutationLoading: isLoading
  }
}

export default useConvertCredits
