import { useTranslation } from 'summon-ui/intl'
import useHyperplayLaunchGame from '@features/quests/components/cta/hyperplay/useHyperplayLaunchGame'
import CompletedButton from '../components/CompletedButton'
import StartButton from '../components/StartButton'

interface Props {
  gameId: string
  isCompleted: boolean
  isDisabled?: boolean
}

const Hyperplay = ({ isCompleted, isDisabled = false, gameId }: Props) => {
  const t = useTranslation()

  const { launchGame, isLaunching } = useHyperplayLaunchGame({})

  const handleStartTask = () => {
    launchGame(gameId)
  }

  return isCompleted ? (
    <CompletedButton />
  ) : (
    <StartButton onStartTask={handleStartTask} isDisabled={isDisabled} loading={isLaunching} text={t('Play')} />
  )
}

export default Hyperplay
