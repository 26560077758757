import { create } from 'zustand'
import { SlotType } from '@config/enum'
import { CameraMode } from '@constants/names'

export interface CameraState {
  lerping: boolean
  cameraMode: CameraMode
  setLerping: (v: boolean) => void
  setCameraMode: (slot?: SlotType) => void
  resetCameraMode: () => void
}

const toCameraMode = (slotType?: SlotType): CameraMode => {
  switch (slotType) {
    case SlotType.Eyes:
    case SlotType.Eyewear:
    case SlotType.Eyebrows:
    case SlotType.FacialHair: {
      return CameraMode.Face
    }

    case SlotType.Headwear:
    case SlotType.Hair: {
      return CameraMode.Head
    }

    case SlotType.Tops:
    case SlotType.Outerwear: {
      return CameraMode.Top
    }
    case SlotType.Legwear: {
      return CameraMode.Bottom
    }

    case SlotType.Companion: {
      return CameraMode.Companion
    }

    case SlotType.Footwear:
    case SlotType.Socks: {
      return CameraMode.Feet
    }

    default: {
      return CameraMode.Free
    }
  }
}

export const useCameraStorage = create<CameraState>()((set) => ({
  cameraMode: CameraMode.Free,
  lerping: false,
  setCameraMode: (slot) =>
    set({
      cameraMode: toCameraMode(slot)
    }),
  resetCameraMode: () =>
    set({
      cameraMode: CameraMode.Free
    }),
  setLerping: (v) =>
    set({
      lerping: v
    })
}))
