import { Box, Button, Stack, Flex, TextInput, useForm as useFormMantine } from 'summon-ui/mantine'
import { useReferral } from '@hooks'

export interface ReferralFormValues {
  referrerCode: string
}

interface Props {
  referrerCode?: string | null
}

const ReferralForm = ({ referrerCode }: Props) => {
  const { addReferral, isPending, referralError } = useReferral()
  const { getInputProps, values, onSubmit, errors } = useFormMantine<ReferralFormValues>({
    initialValues: { referrerCode: referrerCode || '' }
  })

  const handleReferralSubmit = (values: { referrerCode: string | undefined }) => {
    if (values.referrerCode) {
      addReferral(values.referrerCode)
    }
  }
  const isSubmitButtonDisabled = referrerCode === values.referrerCode

  return (
    <form onSubmit={onSubmit(handleReferralSubmit)}>
      <Stack gap='sm'>
        <Box>
          <TextInput
            disabled={!!referrerCode}
            data-testid='settings-referral-field'
            {...{ ...getInputProps('referrerCode'), error: referralError || errors['referralCode'] }}
            placeholder='Enter your referral code'
            autoComplete='off'
          />
        </Box>
        {!referrerCode?.length && (
          <Flex justify='end'>
            <Button
              loading={isPending}
              data-testid='settings-referral-save-button'
              type='submit'
              disabled={isSubmitButtonDisabled}
            >
              Save
            </Button>
          </Flex>
        )}
      </Stack>
    </form>
  )
}

export default ReferralForm
