import type { IStakeInfoCard } from '../StakeInfoCards/StakeInfoCard'

export const INFO_CARDS_DATA: IStakeInfoCard[] = [
  {
    id: '0',
    title: 'Get more diamonds!',
    description: 'Lock up tokens for 24 months to get 3x more Diamonds daily.',
    image: '/images/staking/diamonds-info-card.png',
    hasGradient: true
  },
  {
    id: '1',
    title: 'Diamond rewards for your efforts',
    description: 'Diamonds are an exclusive in-app currency used for buying G7 boxes...',
    image: '/images/staking/diamonds-rewards-card.png'
  },
  {
    id: '2',
    title: 'Building a G7 Nation',
    description: 'Staking tokens are your way of showing your support of G7 and earning Diamonds.',
    image: '/images/staking/building-info-card.png',
    learnMore: 'https://manifesto.game7.io'
  }
]
