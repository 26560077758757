import { useLocation } from 'react-router-dom'
import { useAssetsContext, TenantImageEnum } from 'summon-ui'
import { graphqlClient } from '@api/config'
import { useGetCampaignsQuery, type Campaign, type Category } from '@generated/generates'
import useQuestsCategories from '../../hooks/useQuestCategories'

interface UseAllQuestsCategoriesDataReturn {
  campaignsList: { label: string; value: string }[]
  questsCategories: Category[]
  isCategoriesLoading: boolean
  featuredCategory?: Category
  questPlaceholder: string | null
}

const useAllQuestsCategoriesData = (): UseAllQuestsCategoriesDataReturn => {
  // Get search params from the URL
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  // Fetch campaigns data
  const { data: campaignsData } = useGetCampaignsQuery(graphqlClient())
  const campaignsList =
    campaignsData?.campaigns.map((campaign: Campaign) => ({ label: campaign.name!, value: campaign.key! })) || []

  // Fetch quests categories data
  const { questsCategories: questsCategoriesData, isCategoriesLoading, featuredCategory } = useQuestsCategories()

  // Access the assets context for placeholder image
  const { getTenantImage } = useAssetsContext()
  const questPlaceholder = getTenantImage(TenantImageEnum.QuestPlaceholder)

  // Filter quests categories based on URL parameters
  const questsCategories = queryParams.get('campaign')
    ? questsCategoriesData.filter((category: Category) => category.campaigns.includes(queryParams.get('campaign')!))
    : questsCategoriesData

  return {
    campaignsList,
    questsCategories,
    isCategoriesLoading,
    featuredCategory,
    questPlaceholder
  }
}

export default useAllQuestsCategoriesData
