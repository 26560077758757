/* eslint-disable react/no-unknown-property */
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import * as THREE from 'three'
import clsx from 'clsx'
import { Environment } from '@react-three/drei'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useAvatarCapture } from '../context/AvatarCaptureProvider'
import BaseAvatar from './BaseAvatar'
import { CameraManager } from './CameraManager'
import EquipedItems from './EquipedItems'

const DEFAULT_ZOOM = 5.5

type Props = {
  className?: string
  isEditor?: boolean
  background?: string | undefined
  centerProps?: any
  preview?: boolean
}

const Avatar3D = ({ className, background, centerProps, preview = false }: Props) => {
  const { avatarRef } = useAvatarCapture()

  return (
    <>
      <Canvas
        camera={{ zoom: centerProps?.zoom || DEFAULT_ZOOM, fov: 75 }}
        data-testid='avatar-preview'
        className={clsx(className, 'cursor-grabbing aspect-square')}
        frameloop='always'
        resize={{ debounce: 0 }}
        gl={{ preserveDrawingBuffer: true }} // Important for capturing the canvas
        shadows
      >
        <CameraManager preview={preview} />
        <Environment files='/avatar-environment-apartment.hdr' />
        <ambientLight intensity={1} />
        {background && <color attach='background' args={[background]} />}

        <group dispose={null} position={centerProps?.position || [0, -0.7, 0]}>
          <BaseAvatar />
          <EquipedItems preview={preview} />
        </group>

        {/* AvatarCapture component for exposing capture logic */}
        <AvatarCapture ref={avatarRef} />
      </Canvas>
    </>
  )
}

const AvatarCapture = forwardRef((_props, ref) => {
  const { gl, scene, camera } = useThree() // Access renderer, scene, and camera

  // Capture function exposed via ref
  useImperativeHandle(ref, () => ({
    captureAvatarFace: (zoom: number, isEditor?: boolean) => {
      camera.zoom = zoom // Set the desired zoom level for the capture
      camera.updateProjectionMatrix()
      // Position the camera in front of the avatar's face
      camera.position.set(2, -1.2, 4) // Adjust values to fit the avatar's face
      camera.lookAt(new THREE.Vector3(0, isEditor ? 0 : 0.45, 0)) // Adjust this vector based on avatar's head position

      // Render the current frame to capture the avatar's face
      gl.render(scene, camera)

      // Capture the canvas content as a base64 image
      return new Promise<Blob | null>((resolve) => {
        gl.domElement.toBlob((blob) => {
          resolve(blob)
        }, 'image/png')
      })
    }
  }))

  return null // This component only exposes the capture logic, no rendering needed
})

const AvatarRotation = forwardRef((_props, ref: any) => {
  const [rotationSpeed, setRotationSpeed] = useState(0) // Control rotation speed
  const rotationDuration = 1.5 // Duration in seconds for a 360-degree rotation
  const startTimeRef = useRef<number | null>(null) // Start time reference for animation

  useFrame(({ clock }) => {
    if (!ref.current || rotationSpeed === 0) return

    // Calculate the elapsed time since the animation started
    const elapsedTime = clock.getElapsedTime() - (startTimeRef.current || 0)

    if (elapsedTime < rotationDuration) {
      // Rotate the avatar group by a fraction of 360 degrees per frame
      ref.current.rotation.y += Math.PI * 2 * rotationSpeed
    } else {
      // Stop the rotation once the duration is over
      setRotationSpeed(0)
    }
  })

  const rotate = () => {
    setRotationSpeed(0.1) // Set speed to initiate rotation
    startTimeRef.current = performance.now() / 2000 // Record the start time
  }
  useEffect(() => {
    setTimeout(() => rotate(), 5000)
  }, [ref])
  return null
})

AvatarRotation.displayName = 'AvatarRotation'
AvatarCapture.displayName = 'AvatarCapture'
Avatar3D.displayName = 'Avatar3D'
export default Avatar3D
