export const sliceAddress = (address: string, divider = 4) => {
  return address ? `${address.slice(0, divider + 1)}...${address.slice(address.length - divider + 1)}` : '-'
}

export const splitPascalCase = (str: string) => {
  return str.split(/(?=[A-Z])/).join(' ')
}

export const snakeCaseToUpperCase = (str: string) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const tittleCaseToSnakeCase = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join('_')
}

export const hasJsonStructure = (str: string) => {
  if (typeof str !== 'string') return false
  try {
    const result = JSON.parse(str)
    const type = Object.prototype.toString.call(result)
    return type === '[object Object]' || type === '[object Array]'
  } catch (err) {
    return false
  }
}
