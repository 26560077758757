import { useTranslation } from 'summon-ui/intl'
import { Modal, Stack, Text, TextInput, Group, Button, useForm } from 'summon-ui/mantine'
import { getAddress } from 'viem'
import { useViewer } from '@hooks'
import useUpdateImxWallet from './useUpdateImxWallet'

interface ImxFormValues {
  imxWallet?: string
}

interface Props {
  isOpen: boolean
  onSuccess: () => void
  onFinish: () => void
}

const ImxFormModal = ({ isOpen, onSuccess, onFinish }: Props) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const { updateImxWallet, isUpdatingImxWallet } = useUpdateImxWallet()

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<ImxFormValues>({
    initialValues: { imxWallet: viewer?.customData?.immutableX?.address || '' },
    validate: {
      imxWallet: (value) => {
        try {
          getAddress(value || '')
          return null
        } catch {
          return 'Invalid IMX wallet address.'
        }
      }
    }
  })

  const handleSubmit = async (data: typeof values) => {
    if (data?.imxWallet) {
      await updateImxWallet({ immutableXAddress: data.imxWallet, onSuccess, onFinish })
      onFinish()
    }
  }

  return (
    <Modal title={t('Enter Immutable zkEVM Wallet Address')} opened={isOpen} onClose={onFinish} maw={430}>
      <form onSubmit={onSubmitForm(handleSubmit)}>
        <Stack>
          <Text>{t('Save your Immutable X wallet address to complete actions on IMX-based games')}</Text>
          <TextInput
            labelProps={{ mb: 'sm' }}
            {...getInputProps('imxWallet')}
            label={t('Wallet Address')}
            placeholder={t('Enter your wallet address')}
          />
          <Group justify='space-between' w='100%' mt='sm'>
            <Button onClick={onFinish} variant='outline' miw={115}>
              {t('Cancel')}
            </Button>
            <Button type='submit' loading={isUpdatingImxWallet} miw={115}>
              {t('Submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default ImxFormModal
