import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import 'summon-ui/styles/mantine/mantine-carousel.css'
import 'summon-ui/styles/mantine/mantine-core.css'
import 'summon-ui/styles/mantine/mantine-notifications.css'
import 'summon-ui/styles/mantine/mantine-tiptap.css'
import 'summon-ui/styles/summon-ui.css'
import './styles/global.css'
import App from './App'
import './iframeProtection'
import './styles/tailwind-custom-preflight.css'
import './styles/tailwind.css'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://app.game7.io/'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>
)
