import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, Button, Flex, Group } from 'summon-ui/mantine'
import { TENANT_ONBOARDING } from '@config'
import MintButton from '@features/onboarding/components/MintButton'
import OnboardingContainer from '@features/onboarding/components/OnboardingContainer'
import OnboardingInventory from '@features/onboarding/components/OnboardingInventory'
import ReferralModal from '@features/onboarding/components/ReferralModal'
import SaveAvatarButton from '@features/onboarding/components/SaveAvatarButton'
import { useLogout, useViewer } from '@hooks'
import OnboardingSidebar from '../../components/OnboardingSidebar'

const STEPS = [
  { name: 'Create Avatar', description: 'Create your Avatar and mint', id: 0 },
  { name: 'Select Traits', description: 'Select DNA traits to save to IPFS', id: 1 }
]
export const Game7OnboardingView = () => {
  const { viewer } = useViewer()
  const isMinLaptop = useMediaScreen({ breakpoint: 'laptop', type: 'min' })
  const isDna = viewer && !viewer.skinId
  const t = useTranslation()
  const { logout } = useLogout()
  return (
    <Group h='100%' w='100%' wrap='nowrap' gap='0'>
      <ReferralModal />
      {isMinLaptop && <OnboardingSidebar steps={STEPS} />}
      <Box p={{ base: 20, md: 32 }} w='100%' h='100%' pos='relative'>
        <OnboardingContainer
          title={TENANT_ONBOARDING.avatar.title}
          subTitle={TENANT_ONBOARDING.avatar.subtitle}
          ActionButton={() => (
            <Flex justify='space-between' w='100%'>
              <Button variant='outline' onClick={logout}>
                {t('Close')}
              </Button>
              {isDna ? <MintButton /> : <SaveAvatarButton />}
            </Flex>
          )}
        >
          <OnboardingInventory isDna={!!isDna} />
        </OnboardingContainer>
      </Box>
    </Group>
  )
}
