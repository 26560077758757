import { Route, Routes } from 'react-router-dom'
import { useDisclosure } from 'summon-ui/mantine'
import { CategoryUrlRouterHandler, ErrorBoundary } from '@components'
import LootdropOnboarding from './components/LootdropOnboarding'
import useLootdropOnboarding from './hooks/useLootdropOnboarding'
import LootdropCategoryDetailsView from './views/LootdropCategoryDetails.view'
import LootdropDetailsView from './views/LootdropDetails.view'
import LootdropsView from './views/Lootdrops.view'

export const LootdropsViews = () => {
  const { isLootdropOnbardingCompleted } = useLootdropOnboarding({})
  const [opened, { close }] = useDisclosure(!isLootdropOnbardingCompleted)

  return (
    <>
      <Routes>
        <Route path='/' element={<LootdropsView />} errorElement={<ErrorBoundary />} />
        <Route path='/:lootdropSlug' element={<LootdropDetailsView />} errorElement={<ErrorBoundary />} />
        <Route
          path='/:lootdropSlug/:categoryIdOrSlug'
          element={
            <CategoryUrlRouterHandler
              renderUrlSlugCase={(urlSlug) => {
                return <LootdropCategoryDetailsView urlSlug={urlSlug} />
              }}
            />
          }
          errorElement={<ErrorBoundary />}
        />
      </Routes>
      <LootdropOnboarding open={opened} onClose={close} />
    </>
  )
}
