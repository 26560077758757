import { Countdown, EnhancedPaper, TimeIndicator, useMediaScreen } from 'summon-ui'
import { LootdropProgress } from 'summon-ui/g7'
import { useTranslation } from 'summon-ui/intl'
import {
  alpha,
  Badge,
  Box,
  Flex,
  Image,
  NumberFormatter,
  Stack,
  Text,
  rgba,
  Title,
  useMantineTheme,
  Group
} from 'summon-ui/mantine'
import { weAreBetweenDates } from '@features/lootdrops/helpers'
import type { LootDrop } from '@generated/generates'
import styles from './lootdropCard.module.css'

const LootdropCard = ({
  completionPercentage,
  startTime,
  endTime,
  name,
  description,
  participantCount,
  coverImage,
  rewardPoolInfo
}: LootDrop) => {
  const t = useTranslation()
  const { colors } = useMantineTheme()
  // active should be linked to progress bar
  const isActive = weAreBetweenDates(startTime, endTime)
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const highlighted = isActive && completionPercentage

  const renderStyle = () => {
    const bgColor = highlighted ? rgba(colors.lime[8], 0.1) : 'transparent'
    const bgColorHover = highlighted ? rgba(colors.lime[9], 0.2) : rgba(colors.gray[4], 0.1)
    const borderColor = highlighted ? colors.lime[9] : colors.gray[7]

    return {
      '--bg-color': bgColor,
      '--border-color': borderColor,
      '--hover-bg-color': bgColorHover
    }
  }

  return (
    <EnhancedPaper withBg>
      <Stack p='lg' style={renderStyle()} className={styles.root}>
        <Flex gap={isMobile ? 'md' : 0} direction={isMobile ? 'row' : 'column'} justify={isMobile ? 'start' : 'center'}>
          {isMobile && (
            <Image
              src={coverImage}
              alt='lootdrop cover image'
              data-highlighted={highlighted}
              className={styles.root}
              radius='md'
              w={100}
            />
          )}
          <Group justify={isMobile ? 'center' : 'space-between'} align={isMobile ? 'start' : 'center'} gap='xs'>
            <Title order={3}>{name}</Title>
            {isActive ? (
              <Countdown
                from={new Date(endTime)}
                render={(humanFormattedCountdown) => (
                  <Badge variant='outline' fw='bolder' size='lg' color={highlighted ? 'lime.9' : 'gray'} c='white'>
                    {humanFormattedCountdown}
                  </Badge>
                )}
              />
            ) : (
              <TimeIndicator
                startTime={new Date(startTime)}
                revealCountdownOn={new Date()}
                endTime={new Date(endTime)}
                betweenStartEndTimeText={t('Open')}
                endTimeText={t('Ended')}
                render={({ indicator }) => (
                  <Badge fw='bolder' size='lg' color='gray.7' c='white'>
                    {indicator}
                  </Badge>
                )}
              />
            )}
          </Group>
        </Flex>
        <Flex gap='md'>
          {!isMobile && (
            <Box p='0' miw={145} h={145}>
              <Image
                src={coverImage}
                alt='lootdrop cover image'
                data-highlighted={highlighted}
                className={styles.root}
                radius='md'
                w={145}
              />
            </Box>
          )}
          <Stack>
            <Text lineClamp={2} size='lg' dangerouslySetInnerHTML={{ __html: description || '' }} />
            <Text tt='capitalize'>
              {t('participants')}: <NumberFormatter thousandSeparator value={participantCount} />
            </Text>
            {isActive && (
              <Group gap='sm'>
                <Badge size='lg' color={alpha('var(--mantine-color-white)', 0.1)}>
                  {rewardPoolInfo}
                </Badge>
              </Group>
            )}
          </Stack>
        </Flex>
        {completionPercentage != null && (
          <Box py='md'>
            <LootdropProgress value={completionPercentage || 0} />
          </Box>
        )}
      </Stack>
    </EnhancedPaper>
  )
}

export default LootdropCard
