import { useState } from 'react'

const useUnstake = () => {
  const [isUnstaking, setIsUnstaking] = useState(false)

  const unstake = async ({ onSuccess }: { onSuccess: () => void }) => {
    setIsUnstaking(true)
    await new Promise((resolve) => setTimeout(resolve, 3000))
    onSuccess()
    setIsUnstaking(false)
  }

  return {
    unstake,
    isUnstaking
  }
}

export default useUnstake
