import { useState } from 'react'
import CompletedButton from '../components/CompletedButton'
import StartButton from '../components/StartButton'
import TelegramFormModal from './TelegramFormModal'

interface Props {
  isCompleted: boolean
  isDisabled?: boolean
}

const Telegram = ({ isCompleted, isDisabled = false }: Props) => {
  const [isCurrentlyCompleted, setIsCurrentlyCompleted] = useState(isCompleted)
  const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false)

  const handleSuccess = () => {
    setIsCurrentlyCompleted(true)
  }

  const handleStartTask = () => {
    setIsTelegramModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsTelegramModalOpen(false)
  }

  return (
    <>
      {isCurrentlyCompleted ? (
        <CompletedButton />
      ) : (
        <StartButton onStartTask={handleStartTask} isDisabled={isDisabled} />
      )}
      <TelegramFormModal isOpen={isTelegramModalOpen} onSuccess={handleSuccess} onFinish={handleCloseModal} />
    </>
  )
}

export default Telegram
