import { useTranslation } from 'summon-ui/intl'
import { Button, Flex, Modal, Overlay, Stack, Title } from 'summon-ui/mantine'
import Redeemable from '@features/home/components/rewards/redeemables/Redeemable'
import useQuestCompletedEffect from '@features/quests/hooks/useQuestCompletedEffect'
import { useGetCategoryQuests, useRedeemables } from '@hooks'

const RewardsModal = ({ categoryId }: { categoryId: string }) => {
  const t = useTranslation()

  const { quests } = useGetCategoryQuests({ categoryId })
  const { completedCategory, resetRewards, completedQuestId } = useQuestCompletedEffect({
    quests
  })
  const { rewardsRedeemables } = useRedeemables()
  const rewards = rewardsRedeemables?.filter(
    (redeemable) =>
      redeemable.questId === completedQuestId || (redeemable.categoryId === categoryId && completedCategory)
  )

  const claimableRewards = rewards?.filter((reward) => !reward.redeemed)
  const showModal = (completedCategory || !!completedQuestId) && !!rewards?.length
  if (!showModal) return null
  return (
    <>
      <Overlay backgroundOpacity={0.2} color='gray' blur={7} />
      <Modal withCloseButton={false} opened={showModal} onClose={resetRewards}>
        <Stack ta='center'>
          <Title>{t('Unlocked Rewards')}</Title>
          <Flex w='100%' justify='space-around' gap='sm'>
            {rewards?.map(({ id, title, imageUrl, redeemed, rewardIntegration: { additionalData } }) => (
              <Redeemable
                key={id}
                tokenId={additionalData.rewardTokenId?.toString()}
                redeemableId={id}
                title={title}
                imageUrl={imageUrl}
                isSponsored
                isRedeemed={redeemed}
              />
            ))}
          </Flex>
          <Button mt='xs' variant='outline' onClick={resetRewards} fullWidth>
            {claimableRewards ? t('Close') : t('Claim Later')}
          </Button>
        </Stack>
      </Modal>
    </>
  )
}

export default RewardsModal
