import { TENANT_CONFIG, TENANT_MARKETPLACE } from '@config'
import { isDevelop } from '@utils/checkEnvironment'

const DEFAULT_AVATAR_IMAGE = '/images/default-avatar-icon.png'
const NAVIGATION_MENU = [
  {
    name: 'Home',
    iconName: 'house',
    pfx: 'far',
    to: '/'
  },
  {
    name: 'Quests',
    iconName: 'swords',
    pfx: 'far',
    to: '/quests'
  },
  ...(TENANT_CONFIG.features.lootdrops
    ? [
        {
          name: 'Lootdrop',
          iconName: 'toolbox',
          pfx: 'far',
          to: '/lootdrop'
        }
      ]
    : []),
  {
    name: 'Credits',
    iconName: 'g7-credits',
    pfx: 'fak',
    to: '/credits',
    isNew: true
  },
  ...(isDevelop
    ? [
        {
          name: 'Staking',
          iconName: 'coins',
          pfx: 'far',
          to: '/staking',
          isNew: true
        }
      ]
    : []),
  ...(isDevelop
    ? [
        {
          name: 'Diamond Store',
          iconName: 'diamonds',
          pfx: 'fak',
          to: '/diamonds',
          isNew: true
        }
      ]
    : []),
  {
    name: 'Leaderboard',
    iconName: 'trophy',
    pfx: 'far',
    to: '/leaderboard',
    soon: !TENANT_CONFIG.features.leaderboard
  },
  {
    name: 'Marketplace',
    iconName: 'cart-shopping',
    pfx: 'far',
    to: '/marketplace',
    soon: !TENANT_MARKETPLACE.enabled
  },
  {
    name: 'History',
    iconName: 'clock-rotate-left',
    pfx: 'far',
    to: '/history',
    locked: true
  }
]
const BOTTOM_MENU = [
  {
    name: 'Settings',
    iconName: 'gear',
    pfx: 'far',
    to: '/settings'
  }
]

export { DEFAULT_AVATAR_IMAGE, NAVIGATION_MENU, BOTTOM_MENU }
