import { useState } from 'react'

type OpenG7BoxStep = 'box-animation' | 'box-rewards' | undefined

const useOpenG7BoxSteps = () => {
  const [step, setStep] = useState<OpenG7BoxStep>()

  const handleResetStep = () => {
    setStep(undefined)
  }
  const handleGoToAnimationStep = () => {
    setStep('box-animation')
  }
  const handleGoToRewardsStep = () => {
    setStep('box-rewards')
  }
  return {
    isAnimationStep: step === 'box-animation',
    isRewardsStep: step === 'box-rewards',
    goToAnimationStep: handleGoToAnimationStep,
    goToRewardsStep: handleGoToRewardsStep,
    resetStep: handleResetStep
  }
}

export default useOpenG7BoxSteps
