import { useMediaScreen } from 'summon-ui'
import { Box, Modal } from 'summon-ui/mantine'
import { Widget } from '@typeform/embed-react'

const MODAL_HEADER_HEIGHT = 60

interface Props {
  typeformId: string
  isOpened: boolean
  userId: string
  onFinish: () => void
  onSuccess: () => void
}

const TypeformModal = ({ typeformId, userId, isOpened, onFinish, onSuccess }: Props) => {
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })

  const handleSuccess = () => {
    // Wait couple of seconds for the typeform to finish submitting info
    setTimeout(() => {
      onSuccess()
      onFinish()
    }, 2000)
  }

  return (
    <Modal
      opened={isOpened}
      onClose={onFinish}
      size={isMobile ? 'auto' : '100%'}
      styles={{
        header: {
          height: `${MODAL_HEADER_HEIGHT}px`
        },
        content: {
          height: '100%'
        },
        body: {
          height: `calc(100% - ${MODAL_HEADER_HEIGHT}px)`
        }
      }}
    >
      <Box h='100%' {...(isMobile ? { w: '320px' } : {})}>
        <Widget
          id={typeformId}
          hidden={{
            user: userId
          }}
          className='h-full'
          onSubmit={handleSuccess}
        />
      </Box>
    </Modal>
  )
}

export default TypeformModal
