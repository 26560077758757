/* eslint-disable @typescript-eslint/no-explicit-any */
import { TENANT_CONFIG } from '@config'

export const shopifyClient = async ({ query, variables = {} }: { query: string; variables?: Record<string, any> }) => {
  const result = await fetch(TENANT_CONFIG.api.shopify?.storeUrl ?? '', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': TENANT_CONFIG.api.shopify?.apiToken ?? ''
    },
    body: JSON.stringify({ query, variables })
  })

  if (!result.ok) {
    console.error(result)
    return false
  }

  const { data } = (await result.json()) as { data: any }

  return data
}
