import { useState } from 'react'
import { Countdown } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { alpha, Button, Flex, Paper, Text, useDisclosure } from 'summon-ui/mantine'
import VideoOverlay from '@components/VideoOverlay'
import DailyClaimRewardModal from './DailyClaimRewardModal'
import useDailyClaim from './useDailyClaim'

const DailyClaimAction = () => {
  const t = useTranslation()
  const { claim, canClaim, isClaiming, unlockDate } = useDailyClaim()
  const [diamondsReward, setDiamondsReward] = useState<null | number>(null)
  const [animationOpen, { open: openAnimation, close: closeAnimation }] = useDisclosure(false)
  const [rewardModal, { open: openRewardModal, close: closeRewardModal }] = useDisclosure(false)

  const handleClaimSuccess = (diamondsReward: number) => {
    setDiamondsReward(diamondsReward)
    openAnimation()
  }

  return (
    <>
      {canClaim ? (
        <Button bg='green' loading={isClaiming} onClick={() => claim({ onSuccess: handleClaimSuccess })} fullWidth>
          {t('Claim')}
        </Button>
      ) : (
        <Paper bg={alpha('var(--mantine-color-gray-9)', 0.4)} p='xs' radius='md'>
          <Flex align='center' c='dark.3' justify='center'>
            <Text fw='bold'>{t('Next in')}</Text>
            : &nbsp;
            <Countdown
              key={unlockDate}
              from={new Date(unlockDate)}
              render={(humanFormattedCountdown) => <Text fw='bold'>{humanFormattedCountdown}</Text>}
            />
          </Flex>
        </Paper>
      )}
      {animationOpen && (
        <VideoOverlay
          onClose={() => {
            closeAnimation()
            openRewardModal()
          }}
          sourceUrl='/lootboxes/legendary.webm'
        />
      )}
      {diamondsReward && (
        <DailyClaimRewardModal diamondsReward={diamondsReward} opened={rewardModal} onClose={closeRewardModal} />
      )}
    </>
  )
}

export default DailyClaimAction
