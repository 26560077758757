import type { ReactNode } from 'react'
import { createContext, useContext, useRef } from 'react'
import axios from 'axios'
import { showNotification } from 'summon-ui'
import { useIsFetching, useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { useGqlQuery } from '@api/gql/gqlServices'
import { getTenantByHost } from '@config'
import type { GetSignedImageUrlQuery } from '@generated/generates'
import { GetSignedImageUrlDocument, useUpdateCurrentUserImageMutation } from '@generated/generates'
import { useViewer } from '@hooks'

type AvatarCaptureContextType = {
  captureAvatarFace: (isEditor?: boolean) => Promise<void>
  avatarRef: React.MutableRefObject<any>
  downloadAvatar: () => void
  isSavingAvatarImage: boolean
}

const CAPTURE_ZOOM_LEVEL = 10
const AvatarCaptureContext = createContext<AvatarCaptureContextType | null>(null)

export const useAvatarCapture = () => {
  const context = useContext(AvatarCaptureContext)
  if (!context) {
    throw new Error('useAvatarCapture must be used within an AvatarCaptureProvider')
  }
  return context
}

export const AvatarCaptureProvider = ({ children }: { children: ReactNode }) => {
  const avatarRef = useRef<any>(null)
  const queryClient = useQueryClient()
  const isFetchingAvatar = useIsFetching({ queryKey: ['GetUser'] })
  const { viewer } = useViewer()
  const { data: imageUpload, refetch } = useGqlQuery<GetSignedImageUrlQuery>(GetSignedImageUrlDocument, undefined, {
    enabled: !!viewer
  })
  const { mutateAsync: updateUserImage, isPending } = useUpdateCurrentUserImageMutation(graphqlClient(), {})
  const isLoading = isPending || !!isFetchingAvatar
  return (
    <AvatarCaptureContext.Provider
      value={{
        captureAvatarFace: async (isEditor?: boolean) => {
          const photo = await avatarRef.current?.captureAvatarFace(CAPTURE_ZOOM_LEVEL, isEditor)
          const formData = new FormData()
          formData.append(
            'file',
            photo,
            `user_avatar_${getTenantByHost().env}_${getTenantByHost().tenant}_${viewer?.tokenId}`
          )
          const { data } = await axios.post(imageUpload?.getSignedImageUrl?.uploadURL as string, formData)
          await updateUserImage({ avatarUrl: data.result.variants[0] })
          queryClient.invalidateQueries({ queryKey: ['GetUser'] })
          refetch()
          showNotification({
            variant: 'success',
            message: 'Avatar updated successfully'
          })
        },
        downloadAvatar: async () => {
          const photo = await avatarRef.current?.captureAvatarFace(CAPTURE_ZOOM_LEVEL, true)
          const url = URL.createObjectURL(photo)
          const a = document.createElement('a')
          a.href = url
          a.download = 'avatar'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        },
        avatarRef,
        isSavingAvatarImage: isLoading
      }}
    >
      {children}
    </AvatarCaptureContext.Provider>
  )
}
