import { useState, useEffect } from 'react'

const usePathname = (): string => {
  const [pathname, setPathname] = useState<string>(window.location.pathname)

  useEffect(() => {
    const handleLocationChange = () => {
      setPathname(window.location.pathname)
    }

    // Listen for the 'popstate' event, which is triggered on back/forward navigation
    window.addEventListener('popstate', handleLocationChange)

    // Handle the case of pushState or replaceState, which do not trigger 'popstate'
    const originalPushState = window.history.pushState.bind(window.history)
    const originalReplaceState = window.history.replaceState.bind(window.history)

    // Types for the pushState and replaceState arguments
    type HistoryStateArgs = [data: unknown, title: string, url?: string | URL | null]

    // Monkey patching pushState to trigger the event
    window.history.pushState = (...args: HistoryStateArgs) => {
      originalPushState(...args)
      handleLocationChange()
    }

    // Monkey patching replaceState to trigger the event
    window.history.replaceState = (...args: HistoryStateArgs) => {
      originalReplaceState(...args)
      handleLocationChange()
    }

    return () => {
      window.removeEventListener('popstate', handleLocationChange)

      // Cleanup by restoring original methods
      window.history.pushState = originalPushState
      window.history.replaceState = originalReplaceState
    }
  }, [])

  return pathname
}

export default usePathname
