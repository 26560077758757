import { Link } from 'react-router-dom'
import { Logo } from 'summon-ui'
import { Box } from 'summon-ui/mantine'

const BREAKPOINT = 'lg'
const LOGO_SIZE = 'md'

const ResponsiveLogo = ({ isClickable = true }: { isClickable?: boolean }) => {
  const renderLogo = () => {
    return (
      <>
        <Box hiddenFrom={BREAKPOINT}>
          <Logo size={LOGO_SIZE} />
        </Box>
        <Box visibleFrom={BREAKPOINT}>
          <Logo variant='full' size={LOGO_SIZE} />
        </Box>
      </>
    )
  }
  return isClickable ? <Link to='/'>{renderLogo()}</Link> : renderLogo()
}

export default ResponsiveLogo
