import { useTranslation } from 'summon-ui/intl'
import { Button, Flex, Stack, TextInput, useForm as useFormMantine } from 'summon-ui/mantine'

const validateInput = (value?: string) =>
  value?.length ? (4 <= value.length && value.length <= 20 ? null : 'Must be between 4 and 20 characters') : null

export interface NameFormValues {
  firstName: string
  lastName: string
  username: string
}

interface NameFormProps {
  firstName?: string
  lastName?: string
  username?: string
  onSubmit: (values: NameFormValues) => void
  isUpdating: boolean
}

const NameForm = ({ firstName, lastName, username, onSubmit, isUpdating }: NameFormProps) => {
  const t = useTranslation()

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useFormMantine<NameFormValues>({
    initialValues: { firstName: firstName || '', lastName: lastName || '', username: username || '' },
    validate: {
      firstName: validateInput,
      lastName: validateInput,
      username: validateInput
    }
  })

  const handleSubmit = async (): Promise<void> => {
    onSubmit(values)
  }

  const isSubmitButtonDisabled =
    firstName === values.firstName && lastName === values.lastName && username === values.username

  return (
    <form onSubmit={onSubmitForm(handleSubmit)}>
      <Stack gap='sm'>
        <Stack>
          <TextInput
            data-testid='settings-username-field'
            {...getInputProps('username')}
            label={t('Username')}
            placeholder={t('Enter your username')}
            autoComplete='off'
          />
          <TextInput
            data-testid='settings-name-firstName-field'
            {...getInputProps('firstName')}
            label={t('First Name')}
            placeholder={t('Enter your first name')}
            autoComplete='off'
          />
          <TextInput
            data-testid='settings-name-lastName-field'
            {...getInputProps('lastName')}
            label={t('Last Name')}
            placeholder={t('Enter your last name')}
            autoComplete='off'
          />
        </Stack>
        <Flex justify='end'>
          <Button
            data-testid='settings-name-save-button'
            type='submit'
            disabled={isSubmitButtonDisabled}
            loading={isUpdating}
          >
            {t('Save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}

export default NameForm
