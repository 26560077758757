
      import { TENANT_CONFIG } from './src/config'

      const luckyOrangeTag = TENANT_CONFIG?.analytics?.luckyOrangeTag

      function loadLuckyOrangeAnalytics() {
        const src = `https://tools.luckyorange.com/core/lo.js?site-id=${luckyOrangeTag}`
        const script = document.createElement('script')
        script.src = src
        script.async = true
        document.head.appendChild(script)
      }

      // Conditionally load Lucky oragne analytics only if the tenant has it enabled
      if (luckyOrangeTag) {
        loadLuckyOrangeAnalytics()
      }
    