import { EnhancedPaper } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Divider, Button, Box, Group } from 'summon-ui/mantine'

interface CTASectionProps {
  displayBack: boolean
  onBack?: () => void
  onClose: () => void
  onEquip: () => void
  isEquipping: boolean
  children?: React.ReactNode
}

const CTASection = ({ displayBack, onBack, onClose, onEquip, isEquipping, children }: CTASectionProps) => {
  const t = useTranslation()
  return (
    <Box pos='sticky' bottom={0}>
      <Divider mx={-16} />
      <EnhancedPaper py={16} mb={-16} withBg>
        <Group justify='space-between'>
          {displayBack ? (
            <Button variant='outline' miw={100} onClick={onBack}>
              {t('Back')}
            </Button>
          ) : (
            <Button variant='outline' miw={100} onClick={onClose}>
              {t('Close')}
            </Button>
          )}
          {!displayBack && (
            <Button onClick={onEquip} loading={isEquipping}>
              {t('Equip Gear')}
            </Button>
          )}
          {children}
        </Group>
      </EnhancedPaper>
    </Box>
  )
}

export default CTASection
