import { EnhancedPaper, Icon, useMediaScreen } from 'summon-ui'
import { BadgePrize } from 'summon-ui/g7'
import { useTranslation } from 'summon-ui/intl'
import { Button, Flex, Image, Text, Stack, Title, Paper, TypographyStylesProvider } from 'summon-ui/mantine'
import { type Category } from '@generated/generates'
import { useCustomNavigate } from '@hooks'

interface Props {
  featuredCategory: Category
  questPlaceholder: string | null
}

const FeaturedCategory = ({ featuredCategory, questPlaceholder }: Props) => {
  const t = useTranslation()
  const navigate = useCustomNavigate()
  const isMaxTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })

  const featuredButtonTitle = (): string => {
    if (!completionPercentage) {
      return 'View'
    }
    if (completionPercentage === 0) {
      return t('Get Started')
    } else if (completionPercentage === 100) {
      return t('Completed')
    } else {
      return t('Continue')
    }
  }

  const handleOpenCategory = (id: string) => () => {
    navigate(id)
  }

  const { id, urlSlug, completionPercentage, imageUrl, name, totalXP, totalCredits, description } = featuredCategory
  const isFeaturedCategoryCompleted = completionPercentage === 100

  return (
    <EnhancedPaper withBorder radius='md' p='lg' withBg h='100%' w='100%'>
      <Flex direction={isMaxTablet ? 'column' : 'row'} gap='lg' align='center'>
        <Paper className='overflow-hidden' radius='md'>
          <Image
            data-testid='featured-category-hero-image'
            alt='quest'
            src={imageUrl || questPlaceholder}
            radius='md'
            w={isMaxTablet ? '100%' : 500}
            onClick={handleOpenCategory(urlSlug || id)}
            className='cursor-pointer hover:scale-110 transition-all duration-500'
          />
        </Paper>

        <Stack gap='sm' w={isMaxTablet ? '100%' : '50%'}>
          <Title data-testid='featured-category-name-label' fw='bold' lineClamp={1}>
            {name}
          </Title>
          <Flex gap='xs' pt='xs'>
            {totalXP != null && (
              <BadgePrize variant='xp' info={`${new Intl.NumberFormat('en-US').format(totalXP)} XP`} />
            )}
            {totalCredits != null && (
              <BadgePrize variant='credits' info={`${new Intl.NumberFormat('en-US').format(totalCredits)} CREDITS`} />
            )}
          </Flex>
          <TypographyStylesProvider>
            <Text
              {...(!isMaxTablet ? { lineClamp: 5 } : {})}
              data-testid='featured-category-description-label'
              dangerouslySetInnerHTML={{ __html: description || '' }}
            />
          </TypographyStylesProvider>
          <Button
            data-testid='featured-category-open-button'
            maw={160}
            onClick={handleOpenCategory(urlSlug || id)}
            loading={!featuredCategory}
            {...(isFeaturedCategoryCompleted ? { variant: 'outline' } : {})}
            {...(isFeaturedCategoryCompleted ? { c: 'green.9' } : {})}
            leftSection={isFeaturedCategoryCompleted ? <Icon name='CheckCircle' c='green.9' /> : null}
          >
            {featuredButtonTitle()}
          </Button>
        </Stack>
      </Flex>
    </EnhancedPaper>
  )
}

export default FeaturedCategory
