import { useGqlQuery } from '@api/gql/gqlServices'
import { GetUserCreditsBalanceDocument, type GetUserCreditsBalanceQuery } from '@generated/generates'
import { useViewer } from '@hooks'

const getRandomOrZero = () => {
  return Math.random() < 0.5 ? 0 : Math.floor(Math.random() * 1000)
}

const useUserCreditsBalance = () => {
  const { viewer } = useViewer()

  const { isLoading, isSuccess, isError } = useGqlQuery<GetUserCreditsBalanceQuery>(
    GetUserCreditsBalanceDocument,
    {},
    {
      enabled: !!viewer
    }
  )
  return {
    balance: getRandomOrZero(),
    isSuccess,
    isLoading,
    isError
  }
}

export default useUserCreditsBalance
