export enum ProductSortKeys {
  BEST_SELLING = 'BEST_SELLING',
  PRICE = 'PRICE'
}

export enum ProductCollectionSortKeys {
  BEST_SELLING = 'BEST_SELLING',
  PRICE = 'PRICE',
  PRICE_REVERSE = 'PRICE_REVERSE'
}
