import { create } from 'zustand'
import type { InventoryItem } from '@hooks/inventory/useAvatarMetadata'

type IOnboardingState = {
  step: number
  nextStep: () => void
  toFinalStep: () => void
  previousStep: () => void
  setStep: (step: number) => void
  selectedOnboardingSkin: InventoryItem | null
  setSelectedOnboardingSkin: (item: InventoryItem) => void
}

export const useOnboardingStorage = create<IOnboardingState>()((set, get) => ({
  selectedOnboardingSkin: null,
  setSelectedOnboardingSkin: (item: InventoryItem) => {
    set({
      selectedOnboardingSkin: item
    })
  },
  setStep: (step) => {
    set({
      step
    })
  },
  step: 1,
  nextStep: () => {
    set({
      step: get().step > 0 && get().step < 4 ? get().step + 1 : get().step
    })
  },
  toFinalStep: () => {
    set({
      step: 2
    })
  },
  previousStep: () => {
    set({
      step: get().step > 1 && get().step < 3 ? get().step - 1 : get().step
    })
  }
}))
