import { useLocation } from 'react-router-dom'
import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Flex, Stack, Title, Select, Box, Paper, useMantineColorScheme } from 'summon-ui/mantine'
import { type Category } from '@generated/generates'
import { useCustomNavigate } from '@hooks'
import CategoryCard from '../../components/CategoryCard'
import QuestCategoryCardGrid from '../../components/QuestCategoryCardGrid'
import FeaturedCategory from './FeaturedCategory'
import useAllQuestsCategoriesData from './useAllQuestsCategories'

const FEATURED_CARD_MIN_WIDTH = 300
const FEATURED_CARD_HEIGHT = 325

const AllQuestsCategoriesView = () => {
  const t = useTranslation()
  const { search } = useLocation()
  const navigate = useCustomNavigate()
  const isMaxTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const { colorScheme } = useMantineColorScheme()
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const { campaignsList, questsCategories, isCategoriesLoading, featuredCategory, questPlaceholder } =
    useAllQuestsCategoriesData()

  const renderCardElement = (category: Category) => {
    return <CategoryCard key={category.id} category={category} />
  }

  const renderFeaturedLoading = () => (
    <Paper className='animate-pulse' radius='lg' miw={300} h={325} bg={colorScheme === 'dark' ? 'dark.3' : 'gray.3'} />
  )

  const renderFeaturedCategory = () =>
    featuredCategory ? (
      <FeaturedCategory featuredCategory={featuredCategory} questPlaceholder={questPlaceholder} />
    ) : null

  const featuredCardMiwAndH = isMaxTablet
    ? { h: '100%' }
    : {
        miw: FEATURED_CARD_MIN_WIDTH,
        h: FEATURED_CARD_HEIGHT
      }
  return (
    <Stack gap='lg'>
      {(isCategoriesLoading || (!isCategoriesLoading && featuredCategory)) && (
        <Title order={2}>{t('Featured Quest')}</Title>
      )}
      {(isCategoriesLoading || (!isCategoriesLoading && featuredCategory)) && (
        <Box {...featuredCardMiwAndH}>{isCategoriesLoading ? renderFeaturedLoading() : renderFeaturedCategory()}</Box>
      )}
      <Flex
        justify='space-between'
        align={isMobile ? '' : 'center'}
        direction={isMobile ? 'column' : 'row'}
        mt='md'
        gap={isMobile ? 'sm' : 0}
      >
        <Title order={2}>{t('Quest Book')}</Title>
        <Select
          defaultValue='all'
          data={[{ label: 'All', value: '' }, ...campaignsList]}
          checkIconPosition='right'
          value={search.replace('?campaign=', '')}
          w={isMobile ? '100%' : 'auto'}
          onChange={(_value, option) =>
            navigate({
              search: option?.value.length ? `?campaign=${option.value}` : ''
            })
          }
        />
      </Flex>
      <QuestCategoryCardGrid
        isLoading={isCategoriesLoading}
        cards={questsCategories}
        renderCardElement={renderCardElement}
      />
    </Stack>
  )
}

export default AllQuestsCategoriesView
