import type { ReactNode } from 'react'
import { Table, type TableColumn, type ResponsiveList } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Avatar, Flex, Box, Center, NumberFormatter, Card, Title, Anchor } from 'summon-ui/mantine'
import TwitterLogo from '@assets/icons/socials/twitter.svg?react'
import { EmptyState } from '@components'
import TopUserBubble from '@features/credits/components/TopUserBubble'
import { getFactionAvatar } from '@utils/factionLeaderboardAvatars'
import useReferralsLeaderboard, { type ReferralLeaderboardTableElement } from '../hooks/useReferralsLeaderboard'

const MIN_REFERRALS = 100
const PAGE_SIZE = 50
const MAX_TABLE_KNOWN_POSITION = PAGE_SIZE

type TableKeys = GeneralTableKeys

type GeneralTableKeys = 'rank' | 'member' | 'referralCode' | 'users' | 'credits'
const RANK_COL_W = 40
const MEMBER_COL_W = 300
const USERS_COL_W = 200
const CREDITS_COL_W = 200
const REFERRALCODE_COL_W = 200

const FactionsLeaderboardTable = () => {
  const t = useTranslation()
  const { leaderboard, isLoading, topThreeLeaderboardUsers } = useReferralsLeaderboard(50, MIN_REFERRALS)
  const renderRank = (value: number): ReactNode => <Center>{value || `>${MAX_TABLE_KNOWN_POSITION}`}</Center>

  const renderNameInfo = (value: ReferralLeaderboardTableElement['member']): ReactNode => {
    return (
      <Flex align='center' justify='left' gap='md' h={48}>
        <Flex align='center' justify='left' gap='sm'>
          <Avatar
            data-testid='leaderboard-table-avatar-image'
            src={getFactionAvatar(value.walletAddress as string) || value.avatarUrl}
            className='shadow-xl size-10'
          />
          <Box data-testid='leaderboard-table-wallet-label'>{value.name}</Box>
          {value?.twitter && (
            <Anchor
              href={`https://x.com/${value?.twitter}`}
              target='_blank'
              rel='noopener noreferrer'
              underline='never'
            >
              <TwitterLogo className='h-6' />
            </Anchor>
          )}
        </Flex>
      </Flex>
    )
  }

  const columns: TableColumn<ReferralLeaderboardTableElement, TableKeys>[] = [
    {
      name: '#',
      key: 'rank',
      sort: (a, b) => a.rank! - b.rank!,
      render: (value) => renderRank(value as number),
      width: RANK_COL_W
    },
    {
      name: 'Name',
      key: 'member',
      render: (value) => renderNameInfo(value as ReferralLeaderboardTableElement['member']),
      width: MEMBER_COL_W
    },
    {
      name: 'Referral Code',
      key: 'referralCode',
      width: REFERRALCODE_COL_W
    },
    {
      name: t('Referred Users'),
      key: 'users',
      width: USERS_COL_W
    },
    {
      name: t('Referral Credits earned'),
      key: 'credits',
      render: (value) => <NumberFormatter thousandSeparator value={value as number} />,
      sort: (a, b) => a.credits! - b.credits!,
      width: CREDITS_COL_W
    }
  ]

  const responsiveList: ResponsiveList<ReferralLeaderboardTableElement, TableKeys> = {
    member: {
      key: 'member',
      name: 'Member',
      renderElementKey: (value) => (
        <Flex align='center' justify='left' gap='md'>
          <Flex align='center' justify='left' gap='xs'>
            <Avatar
              src={getFactionAvatar(value.walletAddress as string) || value.avatarUrl}
              className='size-10 shadow-xl'
            />
            <Box>{value.name}</Box>
          </Flex>
        </Flex>
      ),
      renderElementValue: () => null
    },
    rank: {
      key: 'rank',
      name: 'Rank',
      renderElementKey: () => <>{t('Rank')}</>,
      renderElementValue: (value) => <>{value}</>
    },
    users: {
      key: 'users',
      name: 'users',
      renderElementKey: () => <>{t('Referred Users')}</>,
      renderElementValue: (value) => <>{value}</>
    },
    credits: {
      key: 'credits',
      name: 'credits',
      renderElementKey: () => <>{t('Referral Credits earned')}</>,
      renderElementValue: (value) => <NumberFormatter thousandSeparator value={value || 0} />
    },
    referralCode: {
      key: 'referralCode',
      name: 'referralCode',
      renderElementKey: () => <>Referral Code</>,
      renderElementValue: (value) => <>{value}</>
    }
  }

  if ((leaderboard === null || leaderboard.length === 0) && !isLoading) {
    const title = t(leaderboard === null ? 'No Leaderboard Found' : 'Empty Leaderboard')
    const description = t(
      leaderboard === null
        ? 'We can’t find any activity, come back later'
        : 'The battlefield awaits. Rally your faction, conquer quests, and claim your place in the halls of'
    )
    return (
      <Center h='100%' w='100%' pt='40'>
        <EmptyState title={title} description={description} testId='factions-leaderboard' />
      </Center>
    )
  }

  return (
    <>
      <Flex
        visibleFrom='md'
        className='rounded-md'
        justify='center'
        gap='15%'
        my='lg'
        py='xl'
        style={{
          backgroundImage: 'url("/images/credit-leaderboard.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        {topThreeLeaderboardUsers?.map((user, index) => <TopUserBubble key={`${user?.username}-${index}`} {...user} />)}
      </Flex>
      <Card p='lg'>
        <Flex align='center' justify='space-between' h='100%'>
          <Title data-testid='history-title-label' order={4}>
            Top Factions
          </Title>
        </Flex>
      </Card>
      <Table<ReferralLeaderboardTableElement, TableKeys, TableKeys>
        data={leaderboard}
        dataAlignment={{
          rank: 'center',
          users: 'center',
          credits: 'center',
          referralCode: 'center'
        }}
        columns={columns}
        responsiveList={responsiveList}
        stickyConf={{
          stickyHeader: true,
          stickyHeaderOffset: 0
        }}
        isLoading={isLoading}
      />
    </>
  )
}
export default FactionsLeaderboardTable
