/* eslint-disable react/no-unknown-property */
import { memo, Suspense, useEffect } from 'react'
import * as THREE from 'three'
import { Spinner } from 'summon-ui'
import { useAnimations, useGLTF } from '@react-three/drei'
import { getNodeData } from '@utils/object3d'

interface MeshProps {
  children?: React.ReactNode
  uri: string
  color?: string | null | undefined
  renderOrder?: number
  position?: [number, number, number]
  rotation?: [number, number, number]
  scale?: [number, number, number]
  usePrimitive?: boolean
}

const Mesh = memo(
  ({ children, uri, color, renderOrder = 1, position, rotation, scale, usePrimitive = false }: MeshProps) => {
    const { nodes, materials, scene, animations } = useGLTF(uri)
    // @ts-ignore
    const meshes = getNodeData(nodes, materials)
    const { ref, actions, names, mixer } = useAnimations(animations, scene)

    // Ensures that the animation plays and loops properly
    useEffect(() => {
      if (names.length > 0) {
        const actionToPlay = actions['Dance'] ? actions['Dance'] : actions[names[3]]

        if (actionToPlay) {
          // Access the clip through the public method
          const clip = actionToPlay.getClip()

          const filteredTracks = clip.tracks.filter((track) => {
            const binding = THREE.PropertyBinding.parseTrackName(track.name)
            const node = scene.getObjectByName(binding.nodeName)
            return !!node // Keep track if node exists
          })

          if (filteredTracks.length > 0) {
            const filteredClip = new THREE.AnimationClip(clip.name, clip.duration, filteredTracks)

            const filteredAction = mixer.clipAction(filteredClip) // Create new action from the filtered clip
            filteredAction.reset()
            filteredAction.setLoop(THREE.LoopRepeat, Infinity)
            filteredAction.clampWhenFinished = false
            filteredAction.play()
          }
        }
      }
    }, [actions, names, mixer, scene])

    return (
      <Suspense fallback={<Spinner />}>
        {usePrimitive ? (
          <primitive
            ref={ref}
            key={uri}
            renderOrder={1}
            object={scene}
            position={position}
            rotation={rotation}
            scale={scale}
          />
        ) : (
          meshes?.map((mesh) => (
            <mesh
              renderOrder={renderOrder}
              frustumCulled={false}
              castShadow={true}
              name={mesh.name}
              key={mesh.name}
              geometry={mesh.geometry}
              material={mesh.material}
              position={position || mesh.position}
              rotation={rotation || mesh.rotation}
              scale={scale || mesh.scale}
            >
              {/* TODO: Remove color related code */}
              {!!color && <meshStandardMaterial {...mesh.material} color={color} />}
            </mesh>
          ))
        )}
        {children}
      </Suspense>
    )
  }
)

Mesh.displayName = 'Mesh'
export default Mesh
