import { Title, Text, Divider, Box } from 'summon-ui/mantine'

type SettingsHeaderProps = {
  title: string
  subTitle: string
}

export const SettingsHeader = ({ title, subTitle }: SettingsHeaderProps) => (
  <Box pb='sm'>
    <Title order={3}>{title}</Title>
    <Text>{subTitle}</Text>
    <Divider mt='md' />
  </Box>
)
