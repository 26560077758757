import { Icon } from 'summon-ui'
import { Stack, Box, Text, Title } from 'summon-ui/mantine'

interface Props {
  title: string
  message: string
}

const NoItemsMessage = ({ title, message }: Props) => {
  return (
    <Stack gap='lg' justify='center' align='center' pt='xl'>
      <Box>
        <div className='rounded-full bg-[#fee4e2] border-[#fef3f3] border-8 p-2'>
          <Icon name='Shield01' color='brand.5' size='xl' />
        </div>
      </Box>
      <Stack gap='xs' justify='top' align='center' className='h-36'>
        <Title order={4}>{title}</Title>
        <Text ta='center' w={275}>
          {message}
        </Text>
      </Stack>
    </Stack>
  )
}

export default NoItemsMessage
