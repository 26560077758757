import type { LineaGuildLeaderboardTableElement, LineaGuildLeaderboardUser } from '../types'

const buildUpLineaGuildLeaderboardTableData = (
  lineaGuildLeaderboard: Array<LineaGuildLeaderboardUser>
): Array<LineaGuildLeaderboardTableElement> => {
  const updatedLineaGuildLeaderboard = [
    ...lineaGuildLeaderboard.map((user) => ({
      rank: user.position,
      name: user.guildName,
      points: user.totalPoints,
      members: user.totalMembers
    }))
  ]

  return updatedLineaGuildLeaderboard
}

export { buildUpLineaGuildLeaderboardTableData }
