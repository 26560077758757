import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'

interface Props {
  onSuccess?: () => void
}

const useHyperplayLaunchGame = ({ onSuccess }: Props) => {
  const [isLaunching, setIsLaunching] = useState(false)

  const goToGamePageMutation = useMutation({
    mutationFn: async (gameId: string) => {
      if (window.api && typeof window.api.goToGamePage === 'function' && gameId) {
        window.api.install(gameId)
        window.api.goToGamePage(gameId)
      } else {
        console.error('No window.api detected')
      }
    }
  })

  const handleAppContextClick = async (gameId: string) => {
    await goToGamePageMutation.mutateAsync(gameId).then(() => {
      onSuccess && onSuccess()
    })
  }

  const handleFocus = () => {
    setIsLaunching(false)

    // Clean up the event listeneryarn setupWithoutOptional
    window.removeEventListener('focus', handleFocus)
  }

  const handleWebContextClick = (gameId: string) => {
    setIsLaunching(true)

    // Create an invisible iframe to handle the custom protocol
    // We do this cause using window.location.href = `hyperplay://launch/hyperplay/${gameId} blocks the window.open below
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = `hyperplay://launch/hyperplay/${gameId}`
    document.body.appendChild(iframe)

    // Set a timer to open the fallback website if the app is not installed
    const timer = setTimeout(() => {
      // Cleanup the iframe after use
      document.body.removeChild(iframe)

      if (document.hasFocus()) {
        window.open('https://www.hyperplay.xyz/downloads', '_blank')
        handleFocus() // Cleanup focus listener and reset state
      }
      clearTimeout(timer) // Clear the timer
    }, 2500)

    // Add a focus event listener to handle returning to the app
    window.addEventListener('focus', handleFocus)
  }

  const launchGame = (gameId: string) => {
    if (window.api) {
      handleAppContextClick(gameId)
    } else {
      handleWebContextClick(gameId)
    }
  }

  return { isLaunching, launchGame }
}

export default useHyperplayLaunchGame
