import type { Variables } from 'graphql-request'
import {
  type UseQueryOptions,
  type UseQueryResult,
  useQuery,
  type QueryFunction,
  type UseMutationResult,
  type UseMutationOptions,
  type MutationFunction,
  useMutation
} from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { deleteJWT } from '@utils/auth'

type VariablesUnion<T = Record<string, unknown>> = {
  variables?: T | undefined
  requestHeaders?: HeadersInit | undefined
}

const getOperationNameFromDocument = (document: string): string => {
  const matches = document.match(/(?:query|mutation)\s+(\w+)/)
  return matches ? matches[1] : ''
}

function handleUnauthorized() {
  deleteJWT()
  window.location.reload()
}

export const useGqlQuery = <TData>(
  queryDocument: string,
  variables?: VariablesUnion['variables'],
  options?: Omit<UseQueryOptions<TData, unknown, TData, [string, VariablesUnion?]>, 'queryKey' | 'queryFn'>
): UseQueryResult<TData, unknown> => {
  const queryFn: QueryFunction<TData, [string, VariablesUnion?]> = async ({ queryKey }) => {
    const [, queryVariables] = queryKey

    try {
      const response = await graphqlClient().request<TData, Variables>(queryDocument, queryVariables)
      return response
    } catch (error: any) {
      if (error.response?.status === 401) {
        handleUnauthorized()
      }
      throw error // Re-throw the error for further handling if needed
    }
  }

  const queryName = getOperationNameFromDocument(queryDocument)

  const queryKey: [string, VariablesUnion?] = variables === undefined ? [queryName] : [queryName, variables]

  return useQuery<TData, unknown, TData, [string, VariablesUnion?]>({ queryKey, queryFn, ...options })
}

export const useGqlMutation = <TData>(
  mutationDocument: string,
  variables?: Variables | undefined,
  options?: Omit<UseMutationOptions<TData, unknown, Variables, Variables>, 'mutationKey' | 'mutationFn'>
): UseMutationResult<TData, unknown, Variables, unknown> => {
  const mutationFn: MutationFunction<TData, Variables> = async (mutationVariables: Variables) => {
    try {
      const response = await graphqlClient().request<TData, Variables>(mutationDocument, mutationVariables)
      return response
    } catch (error: any) {
      if (error.response?.status === 401) {
        handleUnauthorized()
      }
      throw error // Re-throw the error for further handling if needed
    }
  }

  const mutationName = getOperationNameFromDocument(mutationDocument)
  const mutationKey: [string, Variables?] = variables === undefined ? [mutationName] : [mutationName, variables]

  return useMutation<TData, unknown, Variables, Variables>({ mutationKey, mutationFn, ...options })
}
