import { type PropsWithChildren } from 'react'
import { useViewer } from '@hooks'
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'

const optimizelyClient = createInstance({ sdkKey: import.meta.env.VITE_OPTIMIZELY_TOKEN })

function isClientValid() {
  return optimizelyClient.getOptimizelyConfig() !== null
}

const OptimizelyCustomProvider = ({ children }: PropsWithChildren) => {
  const { viewer } = useViewer()
  optimizelyClient.onReady().then(() => {
    isClientValid()
  })

  return (
    <OptimizelyProvider optimizely={optimizelyClient} {...(viewer ? { user: { id: viewer.id } } : {})}>
      {children}
    </OptimizelyProvider>
  )
}

export default OptimizelyCustomProvider
