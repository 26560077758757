import { AssetsPrefix } from '@config/enum'

export const getFactionAvatar = (wallet: string): string | null => {
  return lowercaseFactionAvatar[wallet.toLowerCase()]
    ? `${AssetsPrefix.G7}${lowercaseFactionAvatar[wallet.toLowerCase()]}`
    : null
}

const factionAvatar = {
  '0xd9EBc885674a93a7c119f37a6ABF9dBED84E1ff8': '/factions/YP.jpeg',
  '0x67d7880fF749b7ab8A7B0D853224fD94269344A6': '/factions/SEARCHFI.jpg',
  '0xfcF1c44ed4B02C0F1744355a7b62Af7394e91B30': '/factions/KGEN.png',
  '0x41B58b7A1dF5b2483211e1C2E0eEBB6BcCdaCd18': '/factions/JAMBO.jpg',
  '0xAb080f2009785D3309c6aA96e61A0bAc8D9DFb02': '/factions/YGG.png',
  '0x75A210a445f1d99bb7F5479d9d4F1D6fb7367344': '/factions/APC.jpg',
  '0xCaCaa4f37D78D5d0A62990ee3A6DC67DC977Bf34': '/factions/CARV.jpg',
  '0x59699A3A1AfDD81FC901A9Afcd41C83a32A037f2': '/factions/W3GG.jpg',
  '0x68e33A587f1d697c5e0f8b8794cFA304B41a61e2': '/factions/SANJAY.jpg',
  '0x9fF26d7e160A52064b328E38793e3569acbcaE99': '/factions/ELISA.jpg',
  '0x47CC04d208784c08513A96A8a608a36a821eF309': '/factions/RAIDEN.png',
  '0x59f2F6Af4Eec349AC4553bAa1Ca977dB791E9C28': '/factions/BOOMLAND.jpg',
  '0xAC1B3FD3AC58c7BC49167447d6BA1F820bc63af1': '/factions/BRYCENT.jpg',
  '0x80e52d1211c635F25dD9A43d2F04eB9e5d722209': '/factions/PIRATENATION.png',
  '0xDFd5762f47D394fD69dB2900Dba967450557276b': '/factions/NAMLONG.png'
} as Record<string, string>

const lowercaseFactionAvatar = Object.fromEntries(
  Object.entries(factionAvatar).map(([key, value]) => [key.toLowerCase(), value])
) as Record<string, string>
