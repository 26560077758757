import type { PropsWithChildren } from 'react'
import { EnhancedPaper } from 'summon-ui'
import { Box, Divider, Flex, Group, Text, Title } from 'summon-ui/mantine'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import Avatar3D from '@modules/avatar'

type Props = {
  title: string
  subTitle: string
  ActionButton: React.FC
} & PropsWithChildren

const OnboardingContainer = ({ title, subTitle, ActionButton, children }: Props) => {
  const { step } = useOnboardingStorage()

  return (
    <Box mah={100}>
      <Text hiddenFrom='md' fz='xs'>
        STEP {step + 1}/2
      </Text>
      <Title fz={{ xs: 'h3', md: 'h1' }}>{title}</Title>
      <Text fz={{ xs: 'sm', md: 'md' }} mb='sm'>
        {subTitle}
      </Text>
      <Flex direction={{ xs: 'column', md: 'row' }} mt='lg' gap='lg'>
        <Avatar3D className='flex-1 max-h-[50vh] md:max-h-none' preview />
        <Box className='flex-1'>{children}</Box>
      </Flex>
      <Box h='100%' pl='sm' mb='100'></Box>
      <EnhancedPaper withBg w='100%' className='md:absolute fixed bottom-0 left-0'>
        <Divider mb='md' />
        <Group justify='end' px='lg' mb='lg'>
          <ActionButton />
        </Group>
      </EnhancedPaper>
    </Box>
  )
}

export default OnboardingContainer
