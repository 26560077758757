import { useTranslation } from 'summon-ui/intl'
import { Stack, Tabs } from 'summon-ui/mantine'
import Redeemables from '@features/home/components/rewards/redeemables'
import Badges from './badges'
import Medals from './medals'

enum RewardsTabsOptions {
  Claimables = 'Claimables',
  Badges = 'Badges',
  Medals = 'Medals'
}

const Rewards = () => {
  const t = useTranslation()

  const TabsList = () => (
    <Tabs.List>
      {Object.values(RewardsTabsOptions).map((tab) => (
        <Tabs.Tab key={tab} value={tab}>
          {tab}
        </Tabs.Tab>
      ))}
    </Tabs.List>
  )

  const RewardsTabs = () => (
    <Tabs defaultValue={t(RewardsTabsOptions.Claimables)} keepMounted={false} mt='md'>
      <TabsList />
      <Tabs.Panel value={t(RewardsTabsOptions.Claimables)}>
        <Redeemables />
      </Tabs.Panel>
      <Tabs.Panel value={t(RewardsTabsOptions.Badges)}>
        <Badges />
      </Tabs.Panel>
      <Tabs.Panel value={t(RewardsTabsOptions.Medals)}>
        <Medals />
      </Tabs.Panel>
    </Tabs>
  )

  return (
    <Stack>
      <RewardsTabs />
    </Stack>
  )
}

export default Rewards
