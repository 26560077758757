import { G7Icon, Spinner } from 'summon-ui'
import type { G7IconName, G7IconPrefix } from 'summon-ui/awesome-icons'
import { useTranslation } from 'summon-ui/intl'
import type { MantineSpacing } from 'summon-ui/mantine'
import { Flex, Text, Paper, NumberFormatter } from 'summon-ui/mantine'

interface Props {
  iconName: string
  iconPrefix: string
  value: number
  isLoading: boolean
  color: string
  borderColorClassname: string
  py?: MantineSpacing
}

const BalanceInfoCard = ({ borderColorClassname, iconName, iconPrefix, color, value, isLoading, py = 'xs' }: Props) => {
  const t = useTranslation()

  return (
    <Paper
      withBorder
      className={borderColorClassname}
      py={py}
      px='xs'
      radius='md'
      w='100%'
      bg='transparent'
      style={{
        backdropFilter: 'blur(10px)'
      }}
    >
      <Flex gap='xs' w='100%' align='center'>
        <G7Icon name={iconName as G7IconName} pfx={iconPrefix as G7IconPrefix} color={color} />
        <Flex w='100%' align='center' gap={isLoading ? 'sm' : 0} fw='bold' c={color}>
          <Text fw='bold'>{t('Balance')}:&nbsp;</Text>
          {isLoading ? (
            <Spinner size='xs' />
          ) : (
            <NumberFormatter className='font-bold' value={value} thousandSeparator />
          )}
        </Flex>
      </Flex>
    </Paper>
  )
}

export default BalanceInfoCard
