import styles from './stakingCard.module.css'
import type { StakingCardData } from './types'

const stakingCardsData: StakingCardData[] = [
  {
    title: { text: 'G7 Staking & Lock Up', label: 'RECOMMENDED', labelBgColor: 'success.6', labelTextColor: 'white' },
    description: 'Lorem ipsum dolor sit amet consectetur. In tincidunt ut ultrices blandit nisl malesuada.',
    features: [
      { icon: '💎', text: 'Daily Diamonds' },
      { icon: '💎', text: 'Upfront Diamonds' }
    ],
    bottomImg: '/images/staking/floating-diamonds.svg',
    className: styles.grayBackgroundGradient
  },
  {
    title: { text: 'LP Staking', label: 'TOP REWARDS', labelBgColor: 'white', labelTextColor: 'black' },
    description: 'Lorem ipsum dolor sit amet consectetur. In tincidunt ut ultrices blandit nisl malesuada.',
    features: [{ icon: '💎', text: 'Daily G7 Tokens' }],
    bottomImg: '/images/staking/floating-diamonds-and-coins.svg',
    className: styles.redBackgroundGradient
  }
]

export default stakingCardsData
