import { useEffect, useRef, useState } from 'react'
import { type Quest } from '@generated/generates'

const useQuestCompletedEffect = ({ quests }: { quests: Quest[] }) => {
  const [completedQuestId, setCompletedQuestId] = useState<string | null>(null)
  const [completedCategory, setCompletedCategory] = useState<boolean>(false)
  // To store the initial quests data
  const initialQuestsRef = useRef<Quest[] | null>(null)

  // Store the initial quests on the first render
  useEffect(() => {
    if (!initialQuestsRef.current && quests.length > 0) {
      initialQuestsRef.current = quests // Store the initial quests
    }
  }, [quests])

  // Detect when a single quest is completed
  useEffect(() => {
    if (initialQuestsRef.current && !completedQuestId) {
      const newCompleted = quests.find((quest) => {
        const initialQuest = initialQuestsRef.current?.find((q) => q.id === quest.id)
        // Detect if the quest was not completed initially but is now completed
        return initialQuest && !initialQuest.isCompleted && quest.isCompleted
      })

      // If a new quest is completed, set it as the completed quest
      if (newCompleted) {
        initialQuestsRef.current = quests
        setCompletedQuestId(newCompleted.id)
        const areQuestsCompleted = quests.every((quest) => quest.isCompleted)
        if (areQuestsCompleted && quests.length > 0) {
          setCompletedCategory(true)
        }
      }
    }
  }, [quests, completedQuestId])

  const resetRewards = () => {
    setCompletedQuestId(null)
    setCompletedCategory(false)
  }

  return {
    completedQuestId,
    completedCategory,
    resetRewards
  }
}

export default useQuestCompletedEffect
