import { RouterProvider } from 'react-router-dom'
import { useDecision } from '@optimizely/react-sdk'
import router, { maintenanceRouter } from '../router'

const AppRouter = () => {
  const [decision] = useDecision('lockdown')
  const isUnderMaintenance = decision.enabled

  return <RouterProvider router={isUnderMaintenance ? maintenanceRouter : router} />
}

export default AppRouter
