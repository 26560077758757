import { useTranslation } from 'summon-ui/intl'
import { Button } from 'summon-ui/mantine'
import { useIsFetching } from '@tanstack/react-query'
import useOnboardingUtils from '@features/onboarding/hooks/useOnboardingUtils'
import useEquipItems from '@hooks/inventory/useEquipItems'

const SaveAvatarButton = () => {
  const { completeOnboarding, isSavingAvatar, isCompletingOnboarding } = useOnboardingUtils()
  const isFetchingAvatar = useIsFetching({ queryKey: ['GetUser'] })
  const t = useTranslation()
  const submitUser = async () => {
    try {
      await completeOnboarding({})
    } catch (error) {
      console.error('Error completing onboarding', error)
    }
  }
  const { equipItems, isEquippingItems } = useEquipItems({ onCallback: submitUser, isDna: true })

  const isLoading = isEquippingItems || isSavingAvatar || !!isFetchingAvatar || isCompletingOnboarding
  return (
    <Button
      data-testid='select-traits-save-button'
      onClick={() => equipItems()}
      miw={120}
      loading={isLoading}
      disabled={isLoading}
    >
      {t('Save')}
    </Button>
  )
}

export default SaveAvatarButton
