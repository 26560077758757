import { useTranslation } from 'summon-ui/intl'
import { Box, Button, Stack, Flex, TextInput, useForm } from 'summon-ui/mantine'
import { getAddress } from 'viem'

export interface ImxFormValues {
  immutableXAddress?: string
}

interface Props {
  immutableXAddress?: string
  onSubmit: (values: ImxFormValues) => void
  isUpdating: boolean
}

const ImxForm = ({ immutableXAddress, onSubmit, isUpdating }: Props) => {
  const t = useTranslation()

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<ImxFormValues>({
    initialValues: { immutableXAddress: immutableXAddress || '' },
    validate: {
      immutableXAddress: (value) => {
        try {
          getAddress(value || '')
          return null
        } catch {
          return 'Invalid IMX wallet address.'
        }
      }
    }
  })

  const handleSubmit = async (): Promise<void> => {
    onSubmit(values)
  }

  const isSubmitButtonDisabled = immutableXAddress === values.immutableXAddress

  return (
    <form onSubmit={onSubmitForm(handleSubmit)}>
      <Stack gap='sm'>
        <Box>
          <TextInput {...getInputProps('immutableXAddress')} placeholder='Enter your Imx Wallet' />
        </Box>
        <Flex justify='end'>
          <Button
            data-testid='settings-imx-save-button'
            type='submit'
            disabled={isSubmitButtonDisabled}
            loading={isUpdating}
          >
            {t('Save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}

export default ImxForm
