import { TENANT_CONFIG, TENANT_MARKETPLACE } from '@config'

const handleTabs = () => {
  const tabs = []

  // reservoir always has tabs if enabled
  if (TENANT_MARKETPLACE.enabled) {
    tabs.push({
      name: 'NFT Exchange',
      to: '/marketplace'
    })
    tabs.push({
      name: 'NFT Profile',
      to: '/marketplace/profile'
    })
  }

  if (TENANT_MARKETPLACE.enabled && TENANT_CONFIG.api.shopify) {
    tabs.push({
      name: 'Swag Store',
      to: '/marketplace/store'
    })
  }

  return tabs
}

export const MARKETPLACE_TABS = handleTabs()
