import { Countdown } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Image, Modal, Stack, Text, Title } from 'summon-ui/mantine'
import useDailyClaim from './useDailyClaim'

type DailyClaimRewardModalProps = {
  opened: boolean
  onClose: () => void
  diamondsReward: number
}
const DailyClaimRewardModal = ({ opened, onClose, diamondsReward }: DailyClaimRewardModalProps) => {
  const t = useTranslation()
  const { unlockDate } = useDailyClaim()

  return (
    <Modal size='sm' withCloseButton={false} opened={opened} onClose={onClose}>
      <Stack align='center' gap='md'>
        <Title order={3}>{t('Reward')}</Title>
        <Image src='/images/staking/diamonds.png' w='100%' />
        <Title>{diamondsReward}</Title>
        <Text tt='capitalize'>{t('Diamonds received')}</Text>
        <Text>
          {t('Next claim in')}:
          <Countdown
            key={unlockDate}
            from={new Date(unlockDate)}
            render={(humanFormattedCountdown) => humanFormattedCountdown}
          />
        </Text>
        <Button onClick={onClose} variant='outline' mt='lg' fullWidth>
          {t('Close')}
        </Button>
      </Stack>
    </Modal>
  )
}

export default DailyClaimRewardModal
