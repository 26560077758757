import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Card, Group, Title, Text, Image, useMantineTheme, getGradient, Anchor, Stack } from 'summon-ui/mantine'

export interface IStakeInfoCard {
  id?: string
  title: string
  description: string
  image: string
  hasGradient?: boolean
  learnMore?: string
  learnMoreOnClick?: () => void
}

const StakeInfoCard = ({ title, description, image, hasGradient, learnMore, learnMoreOnClick }: IStakeInfoCard) => {
  const t = useTranslation()
  const theme = useMantineTheme()
  const isTablet = useMediaScreen({ type: 'max', breakpoint: 'laptop' })
  const gradient = getGradient({ deg: 93, from: 'skyblue.9', to: 'skyblue.5' }, theme)

  const CARD_HEIGHT = isTablet ? 150 : 170

  return (
    <>
      <Card withBorder radius='md' h={CARD_HEIGHT} bg={hasGradient ? gradient : undefined}>
        <Card.Section>
          <Group wrap='nowrap' gap='0' justify='space-between' w='100%' h='100%'>
            <Stack gap={0} h='100%' px='lg' justify='center'>
              <Title order={4} tt='uppercase' lineClamp={2}>
                {t(title)}
              </Title>
              <Text lineClamp={2}>{t(description)}</Text>
              <Text>
                {learnMore && (
                  <Anchor href={learnMore} target='_blank'>
                    {t('Learn More')}
                  </Anchor>
                )}
                {learnMoreOnClick && <Anchor onClick={learnMoreOnClick}>{t('Learn More')}</Anchor>}
              </Text>
            </Stack>
            <Image src={image} alt='stake info card' h={CARD_HEIGHT} maw={150} />
          </Group>
        </Card.Section>
      </Card>
    </>
  )
}

export default StakeInfoCard
