import { Box, Button, Stack, Flex, TextInput, useForm as useFormMantine } from 'summon-ui/mantine'
import { validateEmail } from '@utils/validators'

export interface EmailFormValues {
  emailAddress?: string
}

interface Props {
  emailAddress?: string
  onSubmit: (values: EmailFormValues) => void
  isUpdating: boolean
}

const Email = ({ emailAddress, onSubmit, isUpdating }: Props) => {
  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useFormMantine<EmailFormValues>({
    initialValues: { emailAddress: emailAddress || '' },
    validate: {
      emailAddress: validateEmail
    }
  })

  const handleSubmit = async (): Promise<void> => {
    onSubmit(values)
  }

  const isSubmitButtonDisabled = emailAddress === values.emailAddress

  return (
    <form onSubmit={onSubmitForm(handleSubmit)}>
      <Stack gap='sm'>
        <Box>
          <TextInput
            data-testid='settings-email-field'
            {...getInputProps('emailAddress')}
            label='Email'
            placeholder='Enter your email'
            autoComplete='off'
          />
        </Box>
        <Flex justify='end'>
          <Button
            data-testid='settings-email-save-button'
            type='submit'
            disabled={isSubmitButtonDisabled}
            loading={isUpdating}
          >
            Save
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}

export default Email
