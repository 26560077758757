import { useMemo, useState } from 'react'
import { Stack } from 'summon-ui/mantine'
import LearnDiamondsModal from '../LearnDiamondsModal/LearnDiamondsModal'
import StakeInfoCard from './StakeInfoCard'
import { INFO_CARDS_DATA } from './data'

type LearnMoreHandlers = {
  [key: string]: () => void
}

const StakeInfoCardsList = () => {
  const [isLearnMoreDiamondsOpen, setIsLearnMoreDiamondsOpen] = useState(false)

  const learnMoreHandlers = useMemo<LearnMoreHandlers>(
    () => ({
      '0': () => setIsLearnMoreDiamondsOpen(true)
    }),
    []
  )

  return (
    <>
      <Stack h='100%' w='100%'>
        {INFO_CARDS_DATA.map(({ id, title, description, image, hasGradient, learnMore }) => (
          <StakeInfoCard
            key={id}
            title={title}
            description={description}
            image={image}
            hasGradient={hasGradient}
            learnMore={learnMore}
            learnMoreOnClick={id ? learnMoreHandlers[id] : undefined}
          />
        ))}
      </Stack>
      <LearnDiamondsModal
        totalDiamondsEarned={2893}
        dailyDiamonds={93}
        opened={isLearnMoreDiamondsOpen}
        onClose={() => {
          setIsLearnMoreDiamondsOpen(false)
        }}
      />
    </>
  )
}

export default StakeInfoCardsList
