import axios from 'axios'
import { GraphQLClient } from 'graphql-request'
import { TENANT_API } from '@config'
import { getJWT } from '@utils/auth'

export const restClient = axios.create({
  baseURL: TENANT_API.rest
})

restClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

restClient.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getJWT()}`

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export const graphqlClient = (): GraphQLClient =>
  new GraphQLClient(
    TENANT_API.graphql,
    getJWT()
      ? {
          headers: { Authorization: `Bearer ${getJWT()}` }
        }
      : undefined
  )
