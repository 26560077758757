import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import clsx from 'clsx'

const NumberInput = ({
  name,
  label,
  className,
  defaultValue,
  min = 1,
  ...rest
}: {
  name: string
  label?: string
  className?: string
  defaultValue?: string | number
  min?: number
} & React.InputHTMLAttributes<HTMLInputElement>) => {
  const { control } = useFormContext()
  const defaultVal = Number(defaultValue ?? min)
  const [value, setValue] = useState(defaultVal)
  const subtractIsDisabled = value <= min

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div>
          {label && (
            <label htmlFor={name} className='leading-6'>
              {label}
            </label>
          )}
          <div className='relative'>
            <button
              type='button'
              className={clsx(
                'absolute left-4 top-[50%] -translate-y-[50%] text-lg text-base-black',
                subtractIsDisabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'
              )}
              disabled={subtractIsDisabled}
              onClick={() => {
                setValue(value - 1)
                field.onChange(value - 1)
              }}
            >
              -
            </button>
            <input
              {...rest}
              min={min}
              type='number'
              className={clsx(
                'px-8 py-3.5 rounded-full border border-gray-300 appearance-none w-full text-center text-base-black',
                className
              )}
              {...field}
              value={value}
              onChange={(e) => {
                setValue(Number(e.target.value))
                field.onChange(Number(e.target.value))
              }}
            />
            <button
              type='button'
              className='absolute right-4 top-[50%] -translate-y-[50%] text-lg text-base-black'
              onClick={() => {
                setValue(value + 1)
                field.onChange(value + 1)
              }}
            >
              +
            </button>
          </div>
        </div>
      )}
    />
  )
}

export default NumberInput
