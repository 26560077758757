import { useTranslation } from 'summon-ui/intl'
import { Button, Flex, Image, Modal, Stack, Text, TextInput, Title, useForm } from 'summon-ui/mantine'
import useReferralModal from '@features/onboarding/hooks/useReferralModal'

const ReferralModal = () => {
  const t = useTranslation()
  const { referralModal, storedReferralCode, addReferral, setReferralModal, referralError, isPending } =
    useReferralModal()

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: { referralCode: storedReferralCode }
  })
  const handleReferralSubmit = (values: { referralCode: string | undefined }) => {
    if (values.referralCode) {
      addReferral(values.referralCode)?.then(() => {
        setReferralModal(false)
      })
    } else {
      setReferralModal(false)
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData('text')

    try {
      const parsedUrl = new URL(pastedValue)
      const code = parsedUrl.searchParams.get('referral_code')
      form.setValues({ referralCode: code || pastedValue })
    } catch (error) {
      // If parsing fails, consider it as a direct code input
      form.setValues({ referralCode: pastedValue })
    }
  }
  return (
    <Modal withCloseButton={false} opened={referralModal} onClose={() => setReferralModal(false)}>
      <form onSubmit={form.onSubmit(handleReferralSubmit)}>
        <Stack align='center'>
          <Image src='/images/credits/referral-bg.jpeg' className='rounded-md' alt='referral background' mah='208' />
          <Stack ta='center' gap='4'>
            <Title order={4}>{t('Referral code')}</Title>
            <Text fz='14'>
              {t("Enter your referral code below. If you don't have one, leave it blank and proceed.")}
            </Text>
          </Stack>
          <TextInput
            data-testid='referral-code-input'
            className='w-full'
            onPaste={handlePaste}
            key={form.key('referralCode')}
            {...{ ...form.getInputProps('referralCode'), error: referralError || form.errors['referralCode'] }}
          />
          <Flex w='100%' gap='md' mt='md'>
            <Button
              data-testid='referral-close-button'
              size='md'
              type='button'
              w='100%'
              variant='outline'
              onClick={() => setReferralModal(false)}
            >
              {t('Close')}
            </Button>
            <Button data-testid='referral-proceed-button' size='md' type='submit' loading={isPending} w='100%'>
              {t('Proceed')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal>
  )
}

export default ReferralModal
