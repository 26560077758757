import { useCallback } from 'react'
import { useNavigate, type NavigateOptions, type To, useSearchParams } from 'react-router-dom'

const useCustomNavigate = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const navless = searchParams.get('layout') === 'navless'

  const customNavigate = useCallback(
    (to: To, options: NavigateOptions = {}) => {
      // Handle when `to` is an object
      if (typeof to === 'object') {
        const toSearchParams = new URLSearchParams(to.search)

        if (navless) {
          toSearchParams.set('layout', 'navless')
        }

        navigate({ ...to, search: toSearchParams.toString() }, options)
      } else {
        // Handle when `to` is a string
        const [pathname, search] = to.split('?')
        const toSearchParams = new URLSearchParams(search)

        if (navless) {
          toSearchParams.set('layout', 'navless')
        }

        navigate(`${pathname}?${toSearchParams.toString()}`, options)
      }
    },
    [navigate, navless]
  )

  return customNavigate
}

export default useCustomNavigate
