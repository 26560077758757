import { useState, type ReactNode } from 'react'
import { Countdown, G7Icon } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { Button, Text } from 'summon-ui/mantine'
import type { BoxDetails } from '@generated/generates'
import { OpenG7BoxFlow } from '../../G7BoxFlows'
import CTABox from './CTABox'
import type { SlotBoxVariantElementsComponent } from './types'

interface Props {
  boxId: string
  boxPrice?: number
  Image: ReactNode
  boxDetails: BoxDetails
  isPlaceholder: boolean
  onUnlockAtOver: () => void
}

const Unlocking = ({
  boxId,
  boxPrice,
  Image,
  boxDetails,
  isPlaceholder,
  onUnlockAtOver
}: Props): SlotBoxVariantElementsComponent => {
  const t = useTranslation()
  const [isBoxOpened, setIsBoxOpened] = useState(false)

  const { unlockAt } = boxDetails

  const handleBoxOpenSuccessful = () => {
    setIsBoxOpened(true)
  }

  return {
    TitleElement: (
      <Countdown
        from={new Date(unlockAt)}
        render={(humanFormattedCountdown) => {
          if (humanFormattedCountdown === '00m:00s') {
            onUnlockAtOver()
          }
          return (
            <Text fw='bold' size='xs' c='skyblue.4'>
              {humanFormattedCountdown}
            </Text>
          )
        }}
      />
    ),
    BodyElement: (
      <>
        {Image}
        {!isPlaceholder && boxPrice && (
          <OpenG7BoxFlow
            boxId={boxId}
            onFlowSuccess={handleBoxOpenSuccessful}
            render={(open: () => void, isOpening: boolean, canOpen: boolean) => {
              return !isBoxOpened ? (
                <CTABox text={t('Unlock now for')} colorPalette='skyblue'>
                  <Button variant='white' onClick={open} loading={isOpening} disabled={!canOpen}>
                    <FormattedMessage
                      id='g7AndAmount'
                      values={{
                        space: <>&nbsp;</>,
                        currencyIcon: <G7Icon name='g7-token-mark-4' pfx='fak' />,
                        currencyAmount: boxPrice
                      }}
                    />
                  </Button>
                </CTABox>
              ) : null
            }}
          />
        )}
      </>
    )
  }
}

export default Unlocking
