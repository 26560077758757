import { useTranslation } from 'summon-ui/intl'
import { Stack, TextInput, Button, useForm, Flex } from 'summon-ui/mantine'
import { useUpdateTelegramId } from '@hooks'

interface TelegramFormValues {
  telegramId?: string
}

interface Props {
  telegramId: string
}

const TelegramForm = ({ telegramId }: Props) => {
  const t = useTranslation()
  const { updateTelegramId, isUpdatingTelegramId } = useUpdateTelegramId()

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<TelegramFormValues>({
    initialValues: { telegramId }
  })

  const handleSubmit = async (data: typeof values) => {
    if (data?.telegramId) {
      updateTelegramId({ id: data.telegramId })
    }
  }

  return (
    <form onSubmit={onSubmitForm(handleSubmit)}>
      <Stack>
        <TextInput {...getInputProps('telegramId')} placeholder={t('Enter Telegram ID')} />
        <Flex justify='end'>
          <Button type='submit' loading={isUpdatingTelegramId}>
            {t('Save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}

export default TelegramForm
