import { G7Icon } from 'summon-ui'
import { Badge, Flex, Image } from 'summon-ui/mantine'

const HARDCODED_BOOMLAND_CAMPAIGN = {
  id: 'boomland',
  title: 'BoomLand LootDrop',
  Badges: (
    <>
      <Badge bg='green.7' p='xs' radius='lg'>
        <Flex w='100%' justify='center' align='center' gap={4}>
          <Image src='/images/lootdrop/boomlandRewards/boomland-star.png' w={18} h={18} radius='sm' />
          100M BOOM BAGS
        </Flex>
      </Badge>
      <Badge
        bg='brand.7'
        p='xs'
        radius='lg'
        style={{
          boxShadow: '0px 0px 16px 0px #F04438'
        }}
      >
        <Flex w='100%' justify='center' align='center' gap={4}>
          <G7Icon name='g7-credits' pfx='fak' />
          500 Credits
        </Flex>
      </Badge>
    </>
  ),
  startsOn: 'November 28, 2024 23:59:59 UTC',
  imageUrl: '/images/hardcoded-boomland-card-to-be-removed.jpg'
}

const useFeaturedCampaign = () => {
  return {
    campaign: HARDCODED_BOOMLAND_CAMPAIGN,
    isLoading: false
  }
}

export default useFeaturedCampaign
