import { useMemo } from 'react'
import { useGqlQuery } from '@api/gql/gqlServices'
import { GetLootDropsDocument, type GetLootDropsQuery, type LootDrop } from '@generated/generates'

interface UseLootdropDetailsResult {
  isPending: boolean
  lootdropsData: {
    title: string
    data: LootDrop[]
    active?: boolean
  }[]
}

const ENABLE_MOCK = false
const useLootdrops = (): UseLootdropDetailsResult => {
  const { data, isPending } = useGqlQuery<GetLootDropsQuery>(GetLootDropsDocument)
  const lootdrops = (ENABLE_MOCK ? mockedLootDrops : data?.getLootDrops) as LootDrop[]

  const lootdropsData = useMemo(
    () => [
      {
        title: 'Active lootDrops',
        data: lootdrops?.filter(
          (lootdrop) => new Date(lootdrop.startTime) < new Date() && new Date(lootdrop.endTime) > new Date()
        ),
        active: true
      },
      {
        title: 'Upcoming lootDrops',
        data: lootdrops?.filter((lootdrop) => new Date(lootdrop.startTime) > new Date())
      },
      {
        title: 'Past lootDrops',
        data: lootdrops?.filter((lootdrop) => new Date(lootdrop.endTime) < new Date())
      }
    ],
    [lootdrops]
  )

  return {
    isPending,
    lootdropsData: lootdropsData.filter((lootdrop) => lootdrop.data?.length)
  }
}

export default useLootdrops

const mockedLootDrops = [
  {
    id: '1',
    coverImage: 'https://pbs.twimg.com/profile_images/1597775748580134914/bLhE1aY1_400x400.jpg',
    description: 'The Avantis Missions program is an inclusive platform designed specifically for...',
    endTime: '2024-06-13T00:00:00Z',
    multiplierPerCategory: {
      category1: 2,
      category2: 3
    },
    multiplierPerLevel: {
      level1: 1.5,
      level2: 2
    },
    name: 'Lorem Ipsum by Mantle',
    participantCount: 120089,
    totalMultiplier: 5,
    startTime: '2024-06-10T00:00:00Z',
    urlSlug: 'lorem-ipsum-by-mantle',
    rewards: [
      {
        title: 'MNT',
        units: 2000000
      },
      {
        title: 'NFT',
        units: 20
      }
    ]
  },
  {
    id: '2',
    coverImage: 'https://pbs.twimg.com/profile_images/1597775748580134914/bLhE1aY1_400x400.jpg',
    description: 'The Avantis Missions program is an inclusive platform designed specifically for...',
    endTime: '2024-06-10T00:00:00Z',
    multiplierPerCategory: {
      category1: 1,
      category2: 4
    },
    multiplierPerLevel: {
      level1: 2,
      level2: 3
    },
    name: 'Lorem Ipsum',
    participantCount: 120089,
    totalMultiplier: 6,
    startTime: '2024-06-9T00:00:00Z',
    urlSlug: 'lorem-ipsum',
    rewards: [
      {
        title: 'MNT',
        units: 2000000
      },
      {
        title: 'NFT',
        units: 20
      }
    ]
  },
  {
    id: '3',
    coverImage: 'https://pbs.twimg.com/profile_images/1597775748580134914/bLhE1aY1_400x400.jpg',
    description: 'The Avantis Missions program is an inclusive platform designed specifically for...',
    endTime: '2024-09-01T00:00:00Z',
    multiplierPerCategory: {
      category1: 1,
      category2: 2
    },
    multiplierPerLevel: {
      level1: 1.2,
      level2: 1.8
    },
    name: 'Lorem Ipsum - Default (Not Started)',
    participantCount: 120089,
    totalMultiplier: 3,
    startTime: '2024-08-01T00:00:00Z',
    urlSlug: 'lorem-ipsum-default-not-started',
    rewards: [
      {
        title: 'MNT',
        units: 2000000
      },
      {
        title: 'NFT',
        units: 20
      }
    ]
  },
  {
    id: '4',
    coverImage: 'https://pbs.twimg.com/profile_images/1597775748580134914/bLhE1aY1_400x400.jpg',
    description: 'The Avantis Missions program is an inclusive platform designed specifically for...',
    endTime: '2024-01-12T00:00:00Z',
    multiplierPerCategory: {
      category1: 2,
      category2: 3
    },
    multiplierPerLevel: {
      level1: 1.5,
      level2: 2
    },
    name: 'Lorem Ipsum - Default (Past)',
    participantCount: 120089,
    totalMultiplier: 4,
    startTime: '2023-08-01T00:00:00Z',
    urlSlug: 'lorem-ipsum-default-past',
    rewards: [
      {
        title: 'MNT',
        units: 2000000
      },
      {
        title: 'NFT',
        units: 20
      }
    ]
  }
]
