import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { useUpdateViewerSettings } from '@hooks'

const useUpdateTelegramId = () => {
  const t = useTranslation()
  const [isUpdatingTelegramId, setIsUpdatingTelegramId] = useState(false)
  const { updateViewerSettings } = useUpdateViewerSettings()

  const updateTelegramId = async ({ id, onSuccess }: { id: string; onSuccess?: () => void }) => {
    setIsUpdatingTelegramId(true)
    await updateViewerSettings(
      { telegramId: id },
      {
        onSuccess: () => {
          showNotification({
            variant: 'success',
            message: t('Telegram ID updated successfully')
          })
          onSuccess && onSuccess()
        },
        onError: (error) => {
          console.error(error)
          showNotification({
            variant: 'danger',
            message: t('There was an error updating the Telegram ID')
          })
        },
        onFinally: () => {
          setIsUpdatingTelegramId(false)
        }
      }
    )
  }

  return {
    updateTelegramId,
    isUpdatingTelegramId
  }
}

export default useUpdateTelegramId
