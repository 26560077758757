import { useState } from 'react'
import { readLocalStorageValue, useDisclosure, useLocalStorage } from 'summon-ui/mantine'

const useDiamondsOnboarding = () => {
  const [slide, setSlide] = useState(0)
  const isLastSlide = slide === 2
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setIsLSDiamondsOnboardingCompleted] = useLocalStorage({
    key: 'diamonds-onboarding-completed',
    defaultValue: false
  })

  const isDiamondsOnboardingCompleted = readLocalStorageValue({ key: 'diamonds-onboarding-completed' })

  const [opened, { close }] = useDisclosure(!isDiamondsOnboardingCompleted)

  const handleClickActionButton = () => {
    if (!isLastSlide) {
      setSlide((prev) => prev + 1)
    } else {
      setIsLSDiamondsOnboardingCompleted(true)
      close()
    }
  }

  return {
    handleClickActionButton,
    slide,
    setSlide,
    isLastSlide,
    isDiamondsOnboardingCompleted: isDiamondsOnboardingCompleted,
    setIsDiamondsOnboardingCompleted: setIsLSDiamondsOnboardingCompleted,
    isDiamondsOnboardingModalOpen: opened,
    onCloseDiamondsOnboardingModal: close
  }
}

export default useDiamondsOnboarding
