import { Link } from 'react-router-dom'
import { useTranslation } from 'summon-ui/intl'
import { BackgroundImage, Badge, Box, Button, Image, Stack, Text, Title } from 'summon-ui/mantine'
import useMigrate from '@hooks/useMigrate'

const MigrationPage = () => {
  const t = useTranslation()
  const { migrate, isLoading } = useMigrate()
  return (
    <BackgroundImage
      src='/images/lootdrop/lootdrop-bg.jpeg'
      h='100vh'
      w='100vw'
      ta='center'
      display='flex'
      className='items-center justify-center'
    >
      <Image h={{ xs: 380, sm: 470 }} pos='absolute' top={0} className='w-auto' src='/images//migrate-logo.png' />
      <Stack pos='relative' pt={80} px='md' className='max-w-3xl'>
        <Title fz={{ xs: 'h1', md: '48px' }}>
          {t('Migrate your G7')}
          <br /> {t('Avatar assets')}
        </Title>
        <Text>
          {t('We’re shifting from zkSync to the brand new Game7 Network for better speed and performance.')}
          <br /> {t('Click below to move your assets smoothly!')}
        </Text>
        <Button onClick={() => migrate({})} loading={isLoading} size='lg' mt='md' className='w-fit mx-auto'>
          {t('Migrate now')}
        </Button>
        {isLoading && (
          <Text className='opacity-35 text-sm'>
            {t('Please wait while we make sure all your assets are migrated!')}
          </Text>
        )}
      </Stack>
      <Box className='fixed bottom-10'>
        <Link to='https://summon.xyz' target='_blank'>
          <Badge variant='outline' color='white' size='lg' fw={200}>
            {t('Powered by Summon')}
          </Badge>
        </Link>
      </Box>
    </BackgroundImage>
  )
}

export default MigrationPage
