import { Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from '@components'
import ProfileView from './views/profile.view'
import SocialView from './views/social.view'
import WalletWiew from './views/wallet.view'

export const SettingsViews = () => {
  return (
    <Routes>
      <Route path='/' index element={<ProfileView />} errorElement={<ErrorBoundary />} />
      <Route path='/social' index element={<SocialView />} errorElement={<ErrorBoundary />} />
      <Route path='/wallet' index element={<WalletWiew />} errorElement={<ErrorBoundary />} />
    </Routes>
  )
}
