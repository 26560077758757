import { Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Stack, Title } from 'summon-ui/mantine'
import useStakingPositions from '../../hooks/useStakingPositions'
import StakingPosition from '../StakingPositions/StakingPosition'

const StakingPositions = () => {
  const t = useTranslation()
  const { isStakingPositionsLoading, stakingPositions } = useStakingPositions()

  if (isStakingPositionsLoading) {
    return <Spinner isFull />
  }

  return (
    <Stack my='md'>
      <Title order={3}>{t('Staking Positions')}</Title>
      <Stack>
        {stakingPositions?.map(({ id, isLockup, stakedTokens, upfrontEarning, dailyEarning, unlockDate }) => (
          <StakingPosition
            key={id}
            isLockup={isLockup}
            stakedTokens={stakedTokens}
            upfrontEarning={upfrontEarning}
            dailyEarning={dailyEarning}
            unlockDate={unlockDate}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default StakingPositions
