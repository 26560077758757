import { useState } from 'react'
import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Title, Text, Box, Stack, Group, Checkbox, Anchor } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import useLootdropTerms from '@features/lootdrops/hooks/useLootdropTerms'

const termsLink = TENANT_CONFIG?.urlLinks?.lootDropTerms

interface Props {
  lootdropId: string
  open: boolean
  onClose: () => void
}

const TermsLootdrop = ({ lootdropId, open, onClose }: Props) => {
  const t = useTranslation()
  const [checked, setChecked] = useState(false)
  const { acceptLootdropTerms, isAcceptingLootdropTerms } = useLootdropTerms({
    lootdropId: lootdropId,
    callback: onClose
  })

  return (
    <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md'>
      <Box p='xs'>
        <Stack gap='0' align='center'>
          <Title order={2}>{t('Terms & Conditions')}</Title>
          <Text ta='center'>
            {t('You must accept the terms and conditions of this LootDrop before you can begin.')}
          </Text>
          <Checkbox
            label={
              <Text fw={700}>
                {t('I agree to the')}{' '}
                <Anchor href={termsLink} target='_blank' inherit>
                  {t('Terms and conditions')}
                </Anchor>
              </Text>
            }
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
            size='md'
            mt='md'
          />
        </Stack>

        <Group justify='space-between' wrap='nowrap' mt='md'>
          <Button
            variant='outline'
            onClick={onClose}
            fullWidth
            loading={isAcceptingLootdropTerms}
            disabled={isAcceptingLootdropTerms}
          >
            {t('Close')}
          </Button>
          <Button fullWidth onClick={acceptLootdropTerms} loading={isAcceptingLootdropTerms} disabled={!checked}>
            {t('Confirm')}
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default TermsLootdrop
