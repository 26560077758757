import { lazy, Suspense } from 'react'
import { Spinner } from 'summon-ui'
import { Cart } from '@features/marketplace/components/Cart'
import { withCart } from '@features/marketplace/hocs/withCart'
import { withFilter } from '@features/marketplace/hocs/withFilter'
import { useCart } from '@features/marketplace/hooks/useCart'

const MarketplaceViews = lazy(() => import('@features/marketplace'))

const MarketplacePage = () => {
  const cart = useCart()

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <MarketplaceViews />
      </Suspense>
      <Cart isOpen={cart.isCartOpen} onClose={() => cart.setIsCartOpen(false)} />
    </>
  )
}

export default withCart(withFilter(MarketplacePage))
