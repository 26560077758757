import { TENANT_CONFIG } from '@config'

export const SETTINGS_TABS = [
  {
    name: 'Profile',
    to: '/settings'
  },
  {
    name: 'Social',
    to: '/settings/social'
  },
  ...(TENANT_CONFIG.features?.walletSettings
    ? [
        {
          name: 'Wallet',
          to: '/settings/wallet'
        }
      ]
    : [])
]
