import { Title, Text, Stack } from 'summon-ui/mantine'
import EmptyImage from '@assets/empty-state.svg?react'

interface Props {
  title: string
  description: string
  testId: string
}

const EmptyState = ({ title, description, testId }: Props) => {
  return (
    <Stack justify='center' align='center' gap='0'>
      <EmptyImage />
      <Title data-testid={`${testId}-empty-title-label`} order={2}>
        {title}
      </Title>
      <Text data-testid={`${testId}-empty-description-label`} maw={450} ta='center'>
        {description}
      </Text>
    </Stack>
  )
}

export default EmptyState
