import type { ReactNode } from 'react'
import { Table, type ResponsiveList, type TableColumn } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Avatar, Badge, Flex, Box, Center, Title } from 'summon-ui/mantine'
import { EmptyState } from '@components'
import useLeaderboard from '../hooks/useLeaderboard'
import type { CampaignKey, LeaderboardTableElement } from '../types'

type TableKeys = GeneralTableKeys | DnDTableKeys

type GeneralTableKeys = 'rank' | 'member' | 'XP'
type DnDTableKeys = 'rank' | 'member' | 'XP'

const MEMBER_COL_W = 400
const XP_COL_W = 150
const RANK_COL_W = 70

const LeaderboardTable = ({ campaignKey }: { campaignKey?: CampaignKey }) => {
  const t = useTranslation()
  const { leaderboard, navigateToPage, currentPage, totalPagesCount, totalUsersCount, isLoading, isUserInLeaderboard } =
    useLeaderboard(50, campaignKey)

  const renderRank = (value: number): ReactNode => <Center>{value || `>${totalUsersCount}`}</Center>

  const renderXpWithBadge = (value: number): ReactNode => (
    <Center>
      <Badge data-testid='leaderboard-table-xp-label' color='green.6' miw={55}>
        {value}
      </Badge>
    </Center>
  )

  const renderNameInfo = (value: LeaderboardTableElement['member']): ReactNode => {
    return (
      <Flex align='center' justify='left' gap='md' h={48}>
        <Flex align='center' justify='left' gap='xl'>
          <Avatar data-testid='leaderboard-table-avatar-image' src={value.avatarUrl} className='shadow-xl size-10' />
          <Box data-testid='leaderboard-table-wallet-label'>{value.wallet}</Box>
        </Flex>
      </Flex>
    )
  }

  const columns: TableColumn<LeaderboardTableElement, TableKeys>[] = [
    {
      name: '#',
      key: 'rank',
      sort: (a, b) => a.rank - b.rank,
      render: (value) => renderRank(value as number),
      width: RANK_COL_W
    },
    {
      name: t('Member'),
      key: 'member',
      render: (value) => renderNameInfo(value as LeaderboardTableElement['member']),
      width: MEMBER_COL_W
    },
    {
      name: t('XP'),
      key: 'XP',
      render: (value) => renderXpWithBadge(value as number),
      sort: (a, b) => a.XP - b.XP,
      width: XP_COL_W
    }
  ]

  const responsiveList: ResponsiveList<LeaderboardTableElement, TableKeys> = {
    member: {
      key: 'member',
      name: 'Member',
      renderElementKey: (value) => (
        <Flex align='center' justify='left' gap='md'>
          <Flex align='center' justify='left' gap='xs'>
            <Avatar src={value.avatarUrl} className='size-10 shadow-xl' />
            <Box>{value.wallet}</Box>
          </Flex>
        </Flex>
      ),
      renderElementValue: () => null
    },
    rank: {
      key: 'rank',
      name: '#',
      renderElementKey: () => <>{t('Rank')}</>,
      renderElementValue: (value) => <>{value}</>
    },
    XP: {
      key: 'XP',
      name: 'XP',
      renderElementKey: () => <>XP</>,
      renderElementValue: (value) => (
        <Center>
          <Badge color='green.6'>
            <Title order={6}>{value}</Title>
          </Badge>
        </Center>
      )
    }
  }

  if (leaderboard === null && !isLoading) {
    return (
      <Center h='100%' w='100%'>
        <EmptyState
          title={t('No Leaderboard Found')}
          description={t('We can’t find any activity, come back later')}
          testId='leaderboard'
        />
      </Center>
    )
  }

  return (
    <Box className='h-[calc(100%-70px)]'>
      <Table<LeaderboardTableElement, TableKeys, TableKeys>
        data={leaderboard}
        dataAlignment={{ rank: 'center', member: 'left', XP: 'center' }}
        columns={columns}
        responsiveList={responsiveList}
        stickyConf={{
          stickyHeader: true,
          stickyHeaderOffset: 0,
          stickyFirstRowOffset: isUserInLeaderboard ? 43 : undefined
        }}
        paginationConf={{
          currentPage,
          onNavigateToPage: navigateToPage,
          totalItemsCount: totalUsersCount,
          totalPagesCount
        }}
        isLoading={isLoading}
      />
    </Box>
  )
}

export default LeaderboardTable
