export enum ArtStyleEnum {
  Art3D,
  Art2D
}

export enum Tenant {
  G7 = 'Game7'
}

export enum Hosts {
  G7 = 'app.game7.io',
  G7Staging = 'staging.summon.xyz',
  G7Develop = 'develop.summon-labs.com'
}

export enum Environment {
  Production = 'production',
  Staging = 'staging',
  Develop = 'develop'
}

export enum AssetsPrefix {
  G7 = 'https://assets.summon.xyz/game7/portal'
}

export enum LevelUpVersion {
  V1,
  V2
}

export enum SlotType {
  Unknown = 0,
  Legwear = 1,
  Eyewear = 2,
  Eyebrows = 3,
  Eyes = 4,
  FacialHair = 5,
  Footwear = 6,
  Hair = 7,
  Headwear = 8,
  Skins = 10,
  Socks = 11,
  Tops = 12,
  Body = 13,
  Hands = 14,
  Mouth = 15,
  Back = 17,
  Outerwear = 18,
  Companion = 19
}
