import { useTranslation } from 'summon-ui/intl'
import { Button } from 'summon-ui/mantine'

const BG_COLOR = 'rgba(18, 141, 141, 0.3)'

const CompletedButton = () => {
  const t = useTranslation()
  return (
    <Button variant='outline' c='green.8' bg={BG_COLOR} className='pointer-events-none' w='100%'>
      {t('Completed')}
    </Button>
  )
}

export default CompletedButton
