import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { useUpdateViewerSettings } from '@hooks'

const useUpdateImxWallet = () => {
  const t = useTranslation()
  const [isUpdatingImxWallet, setIsUpdatingImxWallet] = useState(false)
  const { updateViewerSettings } = useUpdateViewerSettings()

  const updateImxWallet = async ({
    immutableXAddress,
    onSuccess,
    onFinish
  }: {
    immutableXAddress: string
    onSuccess: () => void
    onFinish: () => void
  }) => {
    setIsUpdatingImxWallet(true)
    try {
      await updateViewerSettings({ immutableXAddress })
      showNotification({
        variant: 'success',
        message: t('Immutable zkEVM wallet address updated successfully')
      })
      onSuccess()
    } catch (error) {
      console.error(error)
      showNotification({
        variant: 'danger',
        message: t('There was an error updating the Immutable zkEVM wallet address')
      })
    } finally {
      setIsUpdatingImxWallet(false)
      onFinish()
    }
  }

  return {
    updateImxWallet,
    isUpdatingImxWallet
  }
}

export default useUpdateImxWallet
