import { useSearchParams } from 'react-router-dom'
import { Spinner } from 'summon-ui'
import { BackgroundImage, Box } from 'summon-ui/mantine'
import { withAuth } from '@hocs'
import DashboardLayout from './DashboardLayout/DashboardLayout'
import useCalculateBg from './DashboardLayout/useCalculateBackground'
import NavlessLayout from './NavlessLayout/NavlessLayout'

type Props = {
  isAuthenticating?: boolean
}

const LayoutSwitcher = ({ isAuthenticating }: Props) => {
  const [searchParams] = useSearchParams()
  const bg = useCalculateBg()

  if (isAuthenticating || bg == undefined) {
    return (
      <Box h='100vh'>
        <Spinner />
      </Box>
    )
  }

  const layout = searchParams.get('layout')

  const renderLayout = () => {
    if (layout === 'navless') {
      return <NavlessLayout />
    }

    return <DashboardLayout />
  }

  return (
    <>
      <BackgroundImage src={bg} pos='fixed' h='100%' />
      {renderLayout()}
    </>
  )
}

export default withAuth(LayoutSwitcher)
