import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { alpha, Anchor, Box, Button, Group, Paper, useDisclosure } from 'summon-ui/mantine'
import { useCreditsConversion } from '@hooks/balances'
import ClaimG7Modal from './ClaimG7Modal/ClaimG7Modal'

interface Props {
  userTokensBalance: number
}

const UserAvailableCtas = ({ userTokensBalance }: Props) => {
  const t = useTranslation()
  const navigate = useNavigate()
  const [opened, { close, open }] = useDisclosure(false)
  const { canClaimTokens } = useCreditsConversion()

  const handleGoToOptions = () => {
    navigate('/staking/options', { replace: true })
  }

  const renderNoCreditsAndNoTokensInfo = () => (
    <Paper withBorder className='!border-yellow-5' p='sm' radius='md' bg={alpha('var(--mantine-color-yellow-5)', 0.1)}>
      <FormattedMessage
        id='noTokensOrCreditsBannerId'
        values={{
          clickHereHref: (
            <Anchor
              c='orange.5'
              href='https://www.coingecko.com/en/coins/game7'
              className='font-bold'
              underline='always'
            >
              {t('Click here')}
            </Anchor>
          )
        }}
      />
    </Paper>
  )

  const renderButton = (label: string, onClick: () => void, variant: 'white' | 'filled' = 'filled') => (
    <Button variant={variant} onClick={onClick}>
      {label}
    </Button>
  )

  const userHasNoCreditsOrNotCanClaim = userTokensBalance === 0 && !canClaimTokens
  const userHasTokens = userTokensBalance > 0

  return (
    <Box>
      {userHasNoCreditsOrNotCanClaim ? (
        renderNoCreditsAndNoTokensInfo()
      ) : (
        <Group>
          {canClaimTokens && renderButton(t('Claim $G7 tokens'), open, userHasTokens ? 'white' : 'filled')}
          {userHasTokens && renderButton(t('Stake now'), handleGoToOptions)}
        </Group>
      )}
      <ClaimG7Modal open={opened} onClose={close} />
    </Box>
  )
}

export default UserAvailableCtas
