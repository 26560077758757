import { useState } from 'react'
import { TenantImageEnum, useAssetsContext } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Center, Group, Image, Modal, Stack, Text, Title } from 'summon-ui/mantine'
import { useIsFetching } from '@tanstack/react-query'
import useMintAvatar from '@features/onboarding/hooks/useMintAvatar'
import { Turnstile } from '@marsidev/react-turnstile'
import { isProd } from '@utils/checkEnvironment'

const TURNSTILE_PROD_SITEKEY = '0x4AAAAAAAhkmgwuhAEp2ln_'
// Dummy sitekeys can be used from any domain, this always passes the challenge
const TURNSTILE_TEST_SITEKEY = '1x00000000000000000000AA'
const MintButton = () => {
  const { isMinting, mintAvatarMutation } = useMintAvatar()
  const isFetchingAvatar = useIsFetching({ queryKey: ['GetUser'] })
  const { getTenantImage } = useAssetsContext()
  const mintAvatarImage = getTenantImage(TenantImageEnum.MintAvatar)
  const [opened, setOpened] = useState(false)
  const t = useTranslation()
  const [turnstileStatus, setTurnstileStatus] = useState<string | null>(null)
  const isLoading = isMinting || !!isFetchingAvatar
  return (
    <>
      <Modal withCloseButton={false} opened={opened} onClose={() => setOpened(false)}>
        <Stack p='sm' gap='sm'>
          <Image src={mintAvatarImage} radius='md' h={200} ta='center' />
          <Title order={3}>{t('Prepare for an Epic Journey')}</Title>
          <Center w='100%'>
            <Text>
              {t(
                'Create your free Avatar to unlock exclusive wearables, special features, and unique partner experiences. Your adventure begins now!'
              )}
            </Text>
          </Center>
          <Turnstile
            siteKey={!isProd ? TURNSTILE_TEST_SITEKEY : TURNSTILE_PROD_SITEKEY}
            onSuccess={() => setTurnstileStatus('solved')}
          />
          <Group justify='space-between'>
            <Button miw={120} variant='outline' onClick={() => setOpened(false)}>
              {t('Close')}
            </Button>
            <Button
              data-testid='waiting-room-mint-button'
              disabled={isLoading || turnstileStatus != 'solved'}
              loading={isLoading}
              miw={100}
              onClick={() => mintAvatarMutation().then(() => setOpened(false))}
            >
              {t('Mint')}
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Button
        data-testid='waiting-room-mint-button'
        disabled={isLoading}
        loading={isLoading}
        miw={100}
        onClick={() => setOpened(true)}
      >
        {t('Mint')}
      </Button>
    </>
  )
}

export default MintButton
