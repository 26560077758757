import { showNotification } from 'summon-ui'
import { setJWT, getJWT } from 'utils/auth'
import { useSignMessage } from 'wagmi'
import Web3Token from 'web3-token'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { TENANT_CONFIG, getTenantHost } from '@config'
import { useSignUpOrSignInUserMutation } from '@generated/generates'
import { useLogout } from '@hooks'
import useDisplayMobileSupportWarning from '@pages/auth/useDisplayMobileSupportWarning'
import useMixpanel from './useMixpanel'

const useLogin = () => {
  useDisplayMobileSupportWarning()
  const hasJWT = !!getJWT()
  const { logout } = useLogout()
  const queryClient = useQueryClient()
  const { identify } = useMixpanel()
  const { isPending, signMessageAsync } = useSignMessage()

  const { mutateAsync: signUpOrSignInMutation, isPending: isSignUpOrSignInPending } = useSignUpOrSignInUserMutation(
    graphqlClient(),
    {
      onSuccess(data) {
        const {
          signUpOrSignInUser: { id, token }
        } = data
        setJWT(token)
        queryClient.invalidateQueries({ queryKey: ['GetCategories'] })
        queryClient.invalidateQueries({ queryKey: ['GetCategoryQuests'] })

        identify(id)
        // TODO: We need to pass this info to Mixpanel after login
        // peopleSet({
        //   hasEmailConnected: user?.emailAddress ? true : false
        // })
      },
      onError: (error) => {
        showNotification({
          variant: 'danger',
          message: 'ERROR authenticating user'
        })
        console.error('ERROR authenticating user: ', error)
      }
    }
  )

  const login = async () => {
    if (hasJWT) return

    try {
      const signedMessage = await Web3Token.sign(async (message: string) => await signMessageAsync({ message }), {
        domain: getTenantHost(),
        statement: `Connecting with ${TENANT_CONFIG.name} platform`
      })
      if (signedMessage) {
        signUpOrSignInMutation({ message: signedMessage })
      }
    } catch (error) {
      logout()
      console.error('Error signing message: ', error)
    }
  }

  const isConnectingUser = isPending || isSignUpOrSignInPending

  return {
    isConnecting: isConnectingUser,
    login
  }
}

export default useLogin
