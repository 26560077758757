import { useState, useEffect } from 'react'
import { useDebouncedState } from 'summon-ui/mantine'
import { useGqlQuery } from '@api/gql/gqlServices'
import { GetBoomlandFactionLeaderboardDocument, GetBoomlandFactionLeaderboardCountDocument } from '@generated/generates'
import type {
  GetBoomlandFactionLeaderboardQuery,
  GetBoomlandFactionLeaderboardCountQuery,
  BoomlandFactionLeaderboardItem
} from '@generated/generates'
import { buildUpFactionLeaderboardTableData } from './helpers'

const useFactionsLeaderboard = (pageSize: number) => {
  const [currentPage, setCurrentPage] = useDebouncedState(1, 200)
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0)
  const {
    data: factionLeaderboardData,
    isLoading: isLoadingData,
    status,
    refetch
  } = useGqlQuery<GetBoomlandFactionLeaderboardQuery>(GetBoomlandFactionLeaderboardDocument, {
    limit: pageSize,
    offset: (currentPage - 1) * pageSize
  })

  const { data: totalCountData, isLoading: isLoadingTotal } = useGqlQuery<GetBoomlandFactionLeaderboardCountQuery>(
    GetBoomlandFactionLeaderboardCountDocument,
    {
      limit: pageSize,
      offset: (currentPage - 1) * pageSize
    }
  )

  const total = totalCountData?.boomlandFactionLeaderboardCount

  useEffect(() => {
    if (total && factionLeaderboardData && factionLeaderboardData.boomlandFactionLeaderboard !== undefined) {
      setTotalPagesCount(Math.ceil(totalCountData.boomlandFactionLeaderboardCount / pageSize))
    }
  }, [factionLeaderboardData, pageSize, total])

  const handleRetrievePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    isLoading: isLoadingData || isLoadingTotal,
    status,
    refetch,
    leaderboard:
      status === 'success'
        ? buildUpFactionLeaderboardTableData(
            factionLeaderboardData.boomlandFactionLeaderboard as BoomlandFactionLeaderboardItem[]
          )
        : [],
    currentPage,
    navigateToPage: handleRetrievePage,
    totalPagesCount,
    totalUsersCount: total || 0
  }
}

export default useFactionsLeaderboard
