import type { ReactNode } from 'react'
import { useViewer } from '@hooks'
import { useUserDiamondsBalance } from '@hooks/balances'
import useG7SlotBoxes from '../../../hooks/useG7SlotBoxes'
import { useFlow } from '../FlowProvider'
import PurchaseSlotBoxModal from './PurchaseSlotBoxModal'
import usePurchaseG7BoxSteps from './usePurchaseG7BoxSteps'

interface Props {
  price: number
  slotBoxId: string
  render: (startPurchase: () => void, isPurchasing: boolean, canPurchase: boolean) => ReactNode
  onFlowEnd?: () => void
  onFlowSuccess?: () => void
}

const PurchaseG7BoxFlow = ({ price, slotBoxId, render, onFlowEnd, onFlowSuccess }: Props) => {
  const { isAuthenticated } = useViewer()
  const { canStartFlow, startFlow, endFlow } = useFlow()
  const { refetch: refetchDiamondsBalance } = useUserDiamondsBalance()
  const { isPurchaseModalStep, goToPurchaseModalStep, resetStep } = usePurchaseG7BoxSteps()
  const { refetch: refetchSlotBoxes } = useG7SlotBoxes({ enabled: isAuthenticated })

  const handleStartPurchase = () => {
    if (canStartFlow) {
      startFlow()
      goToPurchaseModalStep()
    }
  }

  const handlePurchaseSuccess = () => {
    onFlowSuccess && onFlowSuccess()
    resetStep()
    refetchDiamondsBalance()
    refetchSlotBoxes()
    endFlow()
  }

  const onModalClose = () => {
    onFlowEnd && onFlowEnd()
    endFlow()
    resetStep()
  }

  return (
    <>
      {render(handleStartPurchase, isPurchaseModalStep, canStartFlow)}
      {isPurchaseModalStep && price && (
        <PurchaseSlotBoxModal
          slotBoxId={slotBoxId}
          price={price}
          open={isPurchaseModalStep}
          onSuccess={handlePurchaseSuccess}
          onClose={onModalClose}
        />
      )}
    </>
  )
}

export default PurchaseG7BoxFlow
