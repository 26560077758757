// https://explorer.walletconnect.com/?type=wallet

export const SummonWallets = {
  metamask: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  walletConnect: 'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',
  trust: '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
  okx: '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
  zerion: 'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18',
  coinbaseWallet: 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
  bybit: '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
  jambo: 'ca3f372788f7eaceda2429be20dd4f9c8c148f0d103bb7ba8576f8138104f18c'
}
