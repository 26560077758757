import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useUpdateViewerSettings, useViewer } from '@hooks'

interface Props {
  callback?: () => void
}

const useCreditsOnboarding = ({ callback }: Props) => {
  const queryClient = useQueryClient()
  const { viewer } = useViewer()
  const [slide, setSlide] = useState(0)
  const isFirstSlide = slide === 0
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [checked, setChecked] = useState(false)
  const refreshUserQuery = () => queryClient.invalidateQueries({ queryKey: ['GetUser'] })
  const [isCompletingOnboarding, setIsCompletingOnboarding] = useState(false)

  const { updateViewerSettings } = useUpdateViewerSettings()

  const completeCreditsOnboarding = async () => {
    setIsCompletingOnboarding(true)
    await updateViewerSettings(
      { isCreditsOnboardingCompleted: true },
      {
        onSuccess: () => {
          refreshUserQuery()
          callback && callback()
        },
        onError: (error) => {
          console.error('Error completing Credits onboarding: ', error)
        },
        onFinally: () => {
          setIsCompletingOnboarding(false)
          refreshUserQuery()
        }
      }
    )
  }

  const handleClickActionButton = () => {
    return isFirstSlide ? setSlide(1) : completeCreditsOnboarding()
  }

  return {
    isCreditsOnboardingCompleted: viewer?.isCreditsOnboardingCompleted,
    handleClickActionButton,
    slide,
    setSlide,
    checked,
    setChecked,
    videoLoaded,
    setVideoLoaded,
    isCompletingOnboarding
  }
}

export default useCreditsOnboarding
