import type { ReactNode } from 'react'
import { createContext, useContext, useState, useCallback } from 'react'

// Define the FlowContext type
interface FlowContextType {
  canStartFlow: boolean
  startFlow: () => void
  endFlow: () => void
}

// Create FlowContext
const FlowContext = createContext<FlowContextType | undefined>(undefined)

// FlowProvider component to wrap around components needing flow state
export const FlowProvider = ({ children }: { children: ReactNode }) => {
  const [isFlowActive, setIsFlowActive] = useState(false)

  const startFlow = useCallback(() => setIsFlowActive(true), [])
  const endFlow = useCallback(() => setIsFlowActive(false), [])

  return (
    <FlowContext.Provider value={{ canStartFlow: !isFlowActive, startFlow, endFlow }}>{children}</FlowContext.Provider>
  )
}

export default FlowProvider

// Hook for accessing flow context
export const useFlow = () => {
  const context = useContext(FlowContext)
  if (!context) {
    throw new Error('useFlow must be used within a FlowProvider')
  }
  return context
}
