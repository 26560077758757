import { useReadContract } from 'wagmi'
import { useQuery } from '@tanstack/react-query'
import { getTokenMetadata } from '@api/client'
import { TENANT_CONTRACTS, TENANT_NETWORK } from '@config'
import { AVATAR_BOUND_ABI } from '@constants/Abi/avatarBoundAbi'
import { useViewer } from '@hooks'

export enum ItemType {
  Dna = 'dna',
  Companion = 'companion',
  Wearable = 'wearable'
}

// TODO: Until the BE update this type in the graph we need to use this one
export interface InventoryItem {
  tokenId: number
  uri: string
  imageUrl: string
  glbUrl: string
  minLevel: number
  soulbound: boolean
  slotType: number
  smartContractAddress: string
  count: number
  isEquipped: boolean
  itemType: ItemType
}

interface AttributeMetadata {
  trait_type: string
  value: string | boolean
}

interface Inventory {
  avatarTokenId: number
  contractAddress: string
  playerAddress: string
  tenantId: string
  type: string
  items: InventoryItem[]
}

interface AvatarMetadata {
  name: string
  description: string
  image: string
  animation_url: string
  attributes: AttributeMetadata[]
  inventory: Inventory
}

const useAvatarMetadata = (props: { enabled?: boolean }) => {
  const { viewer } = useViewer()
  const { data: tokenURI } = useReadContract({
    address: TENANT_CONTRACTS.avatar,
    abi: AVATAR_BOUND_ABI,
    functionName: 'tokenURI',
    chainId: TENANT_NETWORK.id,
    args: [viewer?.tokenId],
    query: { enabled: !!viewer?.tokenId && props.enabled }
  })

  const {
    data: tokenMetadata,
    isLoading,
    refetch: refetchAvatarMetadata
  } = useQuery({
    queryKey: ['tokenMetadata', { tokenURI: tokenURI }],
    queryFn: getTokenMetadata,
    enabled: !!tokenURI && !!props.enabled
  })

  return {
    avatarMetadata: tokenMetadata as AvatarMetadata,
    isAvatarMetadataLoading: isLoading,
    refetchAvatarMetadata
  }
}

export default useAvatarMetadata
