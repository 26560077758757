import { useNavigate, useParams } from 'react-router-dom'
// @ts-ignore
// eslint-disable-next-line import/extensions
import WebGL from 'three/addons/capabilities/WebGL.js'
import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, Carousel, Drawer, Flex, Stack, Title, useMediaQuery } from 'summon-ui/mantine'
import { EmptyState } from '@components'
import { TENANT_CONFIG } from '@config'
import { LevelUpVersion } from '@config/enum'
import Avatar3D from '@modules/avatar'
import { useDecision } from '@optimizely/react-sdk'
import FeaturedCampaign from '../components/FeaturedCampaign'
import RecommendedCategory from '../components/RecommendedCategory'
import InventoryDrawer from '../components/inventory/InventoryDrawer'
import LevelInfo from '../components/levelUp/LevelInfo'
import Rewards from '../components/rewards'

const NO_WEBGL_ENABLED_MESSAGE =
  'It looks like you don’t have WebGL installed in your browser so your avatar can’t be shown. Try switching to a different browser or enabling WebGL to resolve this error.'

enum AvatarViewTabs {
  Inventory = 'inventory',
  Rewards = 'rewards'
}

const AvatarView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const t = useTranslation()
  const [featuredCampaignDecision] = useDecision('featuredCampaign')
  const isTabletOrMobile = useMediaQuery('(max-width: 680px)')
  const isInventoryOpen = id === AvatarViewTabs.Inventory
  const isRewardsOpen = id === AvatarViewTabs.Rewards
  const isMobile = useMediaScreen({ breakpoint: 'mobile' })

  if (WebGL && !WebGL.isWebGL2Available()) {
    return (
      <div className='flex h-[50vh] flex-col items-center justify-center'>
        <EmptyState title='Oh no!' description={NO_WEBGL_ENABLED_MESSAGE} testId='avatar' />
      </div>
    )
  }

  return (
    <Box className='md:min-h-[calc(100vh-110px)] min-h-[calc(100vh-200px)]'>
      <Drawer
        position='right'
        withOverlay={false}
        opened={isRewardsOpen}
        onClose={() => navigate('/')}
        title={<Title order={2}>{t('Rewards')}</Title>}
      >
        <Rewards />
      </Drawer>
      <Drawer
        position={isTabletOrMobile ? 'bottom' : 'right'}
        withOverlay={false}
        radius={isTabletOrMobile ? 20 : 0}
        {...(isTabletOrMobile && { size: 'sm' })}
        opened={isInventoryOpen}
        onClose={() => navigate('/')}
        size={isMobile ? 320 : 'md'}
        className='mt-2'
        title={<Title order={4}>{t('Avatar')}</Title>}
      >
        {isInventoryOpen && <InventoryDrawer />}
      </Drawer>
      <Flex
        justify='space-between'
        direction={{ xs: 'column', md: 'row' }}
        className='bg-no-repeat bg-cover min-h-[inherit]'
      >
        <Avatar3D className='max-h-[70vh] md:max-h-[80vh] w-full' preview={isInventoryOpen} />
        <Stack visibleFrom='md' mr='lg' w={{ md: 600 }}>
          <RecommendedCategory />
          <LevelInfo onChain={TENANT_CONFIG.levelVersion === LevelUpVersion.V2} />
        </Stack>
        <Carousel height={210} slideGap='xs' hiddenFrom='md' controlsOffset='xs' loop withControls={false}>
          {featuredCampaignDecision.enabled && (
            <Carousel.Slide>
              <FeaturedCampaign displayCountdown={false} />
            </Carousel.Slide>
          )}
          <Carousel.Slide>
            <RecommendedCategory />
          </Carousel.Slide>
          <Carousel.Slide>
            <LevelInfo onChain={TENANT_CONFIG.levelVersion === LevelUpVersion.V2} />
          </Carousel.Slide>
        </Carousel>
      </Flex>
    </Box>
  )
}

export default AvatarView
