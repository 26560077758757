import type { ReactNode } from 'react'
import { Table, type ResponsiveList, type TableColumn } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Badge, Box, Center, Title } from 'summon-ui/mantine'
import { EmptyState } from '@components'
import type { FactionLeaderboardTableElement } from './types'
import useFactionsLeaderboard from './useFactionsLeaderboard'

type TableKeys = 'rank' | 'name' | 'points' | 'members'

const NAME_COL_W = 400
const MEMBERS_COL_W = 150
const POINTS_COL_W = 150
const RANK_COL_W = 70

const FactionsLeaderboardTable = () => {
  const t = useTranslation()
  const { leaderboard, navigateToPage, currentPage, totalPagesCount, totalUsersCount, isLoading } =
    useFactionsLeaderboard(50)

  const renderRank = (value: number): ReactNode => <Center>{value || `>${totalUsersCount}`}</Center>

  const renderPoints = (value: number): ReactNode => (
    <Box data-testid='leaderboard-boomland-faction-table-points-label'>{value}</Box>
  )

  const renderMultiplier = (value: number): ReactNode => (
    <Box data-testid='leaderboard-boomland-faction-table-multiplier-label'>{value}</Box>
  )

  const renderNameInfo = (value: FactionLeaderboardTableElement['name']): ReactNode => (
    <Box data-testid='leaderboard-boomland-faction-table-name-label'>{value}</Box>
  )

  const columns: TableColumn<FactionLeaderboardTableElement, TableKeys>[] = [
    {
      name: '#',
      key: 'rank',
      sort: (a, b) => a.rank - b.rank,
      render: (value) => renderRank(value as number),
      width: RANK_COL_W
    },
    {
      name: t('Name'),
      key: 'name',
      render: (value) => renderNameInfo(value as FactionLeaderboardTableElement['name']),
      width: NAME_COL_W
    },
    {
      name: t('Members'),
      key: 'members',
      render: (value) => renderMultiplier(value as number),
      sort: (a, b) => a.points - b.points,
      width: MEMBERS_COL_W
    },
    {
      name: t('Points'),
      key: 'points',
      render: (value) => renderPoints(value as number),
      sort: (a, b) => a.points - b.points,
      width: POINTS_COL_W
    }
  ]

  const responsiveList: ResponsiveList<FactionLeaderboardTableElement, TableKeys> = {
    name: {
      key: 'name',
      name: 'name',
      renderElementKey: (value) => <Box data-testid='leaderboard-boomland-faction-table-name-label'>{value}</Box>,
      renderElementValue: () => null
    },
    rank: {
      key: 'rank',
      name: '#',
      renderElementKey: () => <>{t('Rank')}</>,
      renderElementValue: (value) => <>{value}</>
    },
    points: {
      key: 'points',
      name: 'points',
      renderElementKey: () => <>Points</>,
      renderElementValue: (value) => (
        <Center>
          <Badge color='green.6'>
            <Title order={6}>{value}</Title>
          </Badge>
        </Center>
      )
    },
    members: {
      key: 'members',
      name: 'members',
      renderElementKey: () => <>Members</>,
      renderElementValue: (value) => <Title order={6}>{value}</Title>
    }
  }

  if ((leaderboard === null || leaderboard?.length === 0) && !isLoading) {
    return (
      <Center h='100%' w='100%'>
        <EmptyState
          title={t('No Leaderboard Found')}
          description={t("We can't find any activity, come back later")}
          testId='leaderboard'
        />
      </Center>
    )
  }

  return (
    <Box>
      <Table<FactionLeaderboardTableElement, TableKeys, TableKeys>
        data={leaderboard}
        dataAlignment={{ rank: 'center', name: 'left', points: 'center', members: 'center' }}
        columns={columns}
        responsiveList={responsiveList}
        stickyConf={{
          stickyHeader: true,
          stickyHeaderOffset: 0
        }}
        paginationConf={{
          currentPage,
          onNavigateToPage: navigateToPage,
          totalItemsCount: totalUsersCount,
          totalPagesCount
        }}
        isLoading={isLoading}
      />
    </Box>
  )
}

export default FactionsLeaderboardTable
