import type { ReactNode } from 'react'
import { Spinner, Table, type ResponsiveList, type TableColumn } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Avatar, Badge, Flex, Box, Center, Title } from 'summon-ui/mantine'
import { EmptyState } from '@components'
import useLineaPlayerLeaderboard from './hooks/useLineaPlayerLeaderboard'
import type { LineaPlayerLeaderboardTableElement } from './types'

type TableKeys = 'rank' | 'name' | 'points' | 'multiplier'

const NAME_COL_W = 400
const POINTS_COL_W = 150
const RANK_COL_W = 70

const LineaPlayerLeaderboardTable = () => {
  const t = useTranslation()
  const { leaderboard, navigateToPage, currentPage, totalPagesCount, totalUsersCount, isLoading, isUserInLeaderboard } =
    useLineaPlayerLeaderboard(50)

  const renderRank = (value: number): ReactNode => <Center>{value || `>${totalUsersCount}`}</Center>

  const renderEnemiesKilled = (value: number): ReactNode => (
    <Center>
      <Badge data-testid='linea-leaderboard-table-points-label' color='green.6' miw={55}>
        {value}
      </Badge>
    </Center>
  )

  const renderNameInfo = (value: LineaPlayerLeaderboardTableElement['name']): ReactNode => {
    return (
      <Flex align='center' justify='left' gap='md' h={48}>
        <Flex align='center' justify='left' gap='xl'>
          <Avatar
            data-testid='linea-leaderboard-table-avatar-image'
            src={value.avatarUrl}
            className='shadow-xl size-10'
          />
          <Box data-testid='linea-leaderboard-table-wallet-label'>{value.username}</Box>
        </Flex>
      </Flex>
    )
  }

  const columns: TableColumn<LineaPlayerLeaderboardTableElement, TableKeys>[] = [
    {
      name: '#',
      key: 'rank',
      sort: (a, b) => a.rank - b.rank,
      render: (value) => renderRank(value as number),
      width: RANK_COL_W
    },
    {
      name: t('Name'),
      key: 'name',
      render: (value) => renderNameInfo(value as LineaPlayerLeaderboardTableElement['name']),
      width: NAME_COL_W
    },
    {
      name: t('Multiplier'),
      key: 'multiplier',
      sort: (a, b) => a.multiplier - b.multiplier
    },
    {
      name: t('Awakening Points'),
      key: 'points',
      render: (value) => renderEnemiesKilled(value as number),
      width: POINTS_COL_W
    }
  ]

  const responsiveList: ResponsiveList<LineaPlayerLeaderboardTableElement, TableKeys> = {
    name: {
      key: 'name',
      name: 'Name',
      renderElementKey: (value) => (
        <Flex align='center' justify='left' gap='md'>
          <Flex align='center' justify='left' gap='xs'>
            <Avatar src={value.avatarUrl} className='size-10 shadow-xl' />
            <Box>{value.username}</Box>
          </Flex>
        </Flex>
      ),
      renderElementValue: () => null
    },
    rank: {
      key: 'rank',
      name: '#',
      renderElementKey: () => <>{t('Rank')}</>,
      renderElementValue: (value) => <>{value}</>
    },

    multiplier: {
      key: 'multiplier',
      name: 'Multiplier',
      renderElementKey: () => <>{t('Multiplier')}</>,
      renderElementValue: (value) => value
    },
    points: {
      key: 'points',
      name: 'Awakening Points',
      renderElementKey: () => <>{t('Awakening Points')}</>,
      renderElementValue: (value) => (
        <Center>
          <Badge color='green.6'>
            <Title order={6}>{value}</Title>
          </Badge>
        </Center>
      )
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  if (leaderboard === null || (!leaderboard.length && !isLoading)) {
    return (
      <Center h='100%' w='100%'>
        <EmptyState
          title={t('No Leaderboard Found')}
          description={t('We can’t find any activity, come back later')}
          testId='leaderboard'
        />
      </Center>
    )
  }
  return (
    <Box>
      <Table<LineaPlayerLeaderboardTableElement, TableKeys, TableKeys>
        data={leaderboard}
        dataAlignment={{ rank: 'center', name: 'left', points: 'center', multiplier: 'center' }}
        columns={columns}
        responsiveList={responsiveList}
        stickyConf={{
          stickyHeader: true,
          stickyHeaderOffset: 0,
          stickyFirstRowOffset: isUserInLeaderboard ? 43 : undefined
        }}
        {...(totalPagesCount > 1
          ? {
              paginationConf: {
                currentPage,
                onNavigateToPage: navigateToPage,
                totalItemsCount: totalUsersCount,
                totalPagesCount
              }
            }
          : {})}
        isLoading={isLoading}
      />
    </Box>
  )
}

export default LineaPlayerLeaderboardTable
