import { useEffect } from 'react'
import { graphqlClient } from '@api/config'
import { useCreateReferralCodeMutation } from '@generated/generates'
import { useViewer } from '@hooks'

interface Props {
  enabled: boolean
}

const useCreateReferralCode = ({ enabled = false }: Props) => {
  const { mutateAsync: createReferralCodeMutation } = useCreateReferralCodeMutation(graphqlClient(), {})
  const { refetch: refetchUser } = useViewer()

  useEffect(() => {
    if (!enabled) return

    const createReferralCode = async () => {
      try {
        await createReferralCodeMutation({})
        refetchUser()
      } catch (error) {
        console.error('Error creating Referral Code: ', error)
      }
    }

    createReferralCode()
  }, [enabled, createReferralCodeMutation, refetchUser])
}

export default useCreateReferralCode
