import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { EnhancedPaper, TimeIndicator, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Flex, Text, Stack, Image, Badge, Button } from 'summon-ui/mantine'
import { isXDaysBeforeDate } from '@features/lootdrops/helpers'
import type { Category } from '@generated/generates'

const CARD_WIDTH = 400

interface Props {
  category: Category
  lootdropSlug: string
  isOpen?: boolean
}

const LootdropCategoryCard = ({ category, lootdropSlug, isOpen = false }: Props) => {
  const t = useTranslation()
  const { id, name, description, imageUrl, startTime, endTime, urlSlug, completionPercentage } = category
  const isMaxDesktop = useMediaScreen({ type: 'max', breakpoint: 'desktop' })
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const revealCountdownOnDate = useMemo(() => {
    return !isOpen ? isXDaysBeforeDate(7, startTime) : new Date()
  }, [startTime, isOpen])

  return (
    <Link key={id} to={`/lootdrop/${lootdropSlug}/${urlSlug}`}>
      <EnhancedPaper withBorder p='md' withBg w={isMaxDesktop ? '100%' : CARD_WIDTH} radius='md'>
        <Flex gap='sm' align='center' direction={isMobile ? 'column' : 'row'}>
          <Image src={imageUrl} radius='md' h={100} />
          <Stack gap={4} pos='relative' w='100%'>
            {isOpen && completionPercentage != null && (
              <Badge radius='lg' bg='teal.9' pos='absolute' top={-4} left={-70}>
                {completionPercentage}%
              </Badge>
            )}
            <Text>{name}</Text>
            <Text lineClamp={2} size='sm' c='dark.5' dangerouslySetInnerHTML={{ __html: description || '' }} />
            {isOpen && (completionPercentage === null || completionPercentage === 0) && (
              <Button variant='white' mt={isMobile ? 'xs' : '2'} maw={{ xs: '100%', md: 140 }}>
                {t('Get Started')}
              </Button>
            )}

            {!isOpen && (
              <TimeIndicator
                startTime={new Date(startTime)}
                revealCountdownOn={revealCountdownOnDate}
                endTime={new Date(endTime)}
                render={({ indicator }) => (
                  <Text size='sm' fw='bold' c='success'>
                    {indicator}
                  </Text>
                )}
              />
            )}
          </Stack>
        </Flex>
      </EnhancedPaper>
    </Link>
  )
}

export default LootdropCategoryCard
