import { useTranslation } from 'summon-ui/intl'
import { Box, Button, Card, getGradient, Image, Text, Title, useMantineTheme } from 'summon-ui/mantine'

type LockupBannerProps = {
  onClick: () => void
}
const LockupBanner = ({ onClick }: LockupBannerProps) => {
  const t = useTranslation()
  const theme = useMantineTheme()

  const gradient = getGradient({ deg: 93, from: 'skyblue.9', to: 'skyblue.5' }, theme)

  return (
    <Card bg={gradient} withBorder radius='md' className='relative'>
      <Title tt='uppercase'>{t('Get more diamonds!')}</Title>
      <Text>{t('Lock up tokens for 24 months to get 3x more Diamonds daily.')}</Text>
      <Box mt='md'>
        <Button variant='white' onClick={onClick}>
          {t('Lock-UP NOW')}
        </Button>
      </Box>
      <Image pos='absolute' top='0' right='0' w='400' h='100%' src='/images/staking/diamonds.png' />
    </Card>
  )
}

export default LockupBanner
