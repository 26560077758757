import { Outlet } from 'react-router-dom'
import { Spinner, TenantImageEnum, useAssetsContext } from 'summon-ui'
import { BackgroundImage, Box } from 'summon-ui/mantine'
import useOnboarding from './useOnboarding'

const OnboardingLayout = () => {
  const { isProcessing } = useOnboarding()
  const { getTenantImage } = useAssetsContext()
  const onboardingBg = getTenantImage(TenantImageEnum.OnboardingBg)
  return (
    <BackgroundImage src={onboardingBg || ''} className='h-screen'>
      <Box className='h-full'>{isProcessing ? <Spinner /> : <Outlet />}</Box>
    </BackgroundImage>
  )
}

export default OnboardingLayout
