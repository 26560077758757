import type { PropsWithChildren } from 'react'
import { WagmiProvider as Wagmi } from 'wagmi'
import { TENANT_CONFIG, TENANT_SUPPORTED_CHAINS } from '@config'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

const projectId = TENANT_CONFIG.projectId

const metadata = {
  name: TENANT_CONFIG.name,
  description: TENANT_CONFIG.meta.description,
  url: origin, // origin must match your domain & subdomain
  icons: ['/images/favicon.ico']
}

const config = defaultWagmiConfig({
  chains: TENANT_SUPPORTED_CHAINS,
  projectId,
  pollingInterval: 10000,
  metadata,
  enableCoinbase: true,
  auth: {
    email: false, // default to true,
    socials: []
  }
})

// Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  chainImages: {
    5000: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZWGOcJRjxOB8qFUJE1LvTxAxUJbMptiqIr0KKM9CBNyt0xwOSnv8v_YRSZJ-ffy10f1k&usqp=CAU'
  },
  featuredWalletIds: TENANT_CONFIG.wallets,
  allWallets: 'ONLY_MOBILE',
  enableAnalytics: true // Optional - defaults to your Cloud configuration,
})

const WagmiProvider = ({ children }: PropsWithChildren) => {
  return <Wagmi config={config}>{children}</Wagmi>
}
export default WagmiProvider
