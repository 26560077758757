import { Link } from 'react-router-dom'
import { Spinner } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { alpha, Card, Image, SimpleGrid, Stack, Title } from 'summon-ui/mantine'
import type { LootDrop } from '@generated/generates'
import LootdropCard from '../components/LootdropCard'
import useLootdrops from '../hooks/useLootdrops'

const LootdropsView = () => {
  const { lootdropsData, isPending } = useLootdrops()
  const t = useTranslation()

  return (
    <>
      <Image src='/images/lootdrop/lootdrop-title.png' mx='auto' w='700' mb='xl' />
      {isPending ? (
        <Spinner mt={150} />
      ) : (
        <Card withBorder radius='md' bg={alpha('inherit', 0.4)} p='lg'>
          <Stack gap='lg'>
            {lootdropsData.map(({ title, data, ...props }) => (
              <Stack gap='sm' key={title}>
                <Title order={3}>{t(title)}</Title>
                <SimpleGrid cols={{ base: 1, xl: 2 }}>
                  {data.map((lootdrop: LootDrop) => (
                    <Link to={`/lootdrop/${lootdrop.urlSlug}`} key={lootdrop.urlSlug}>
                      <LootdropCard {...props} {...lootdrop} />
                    </Link>
                  ))}
                </SimpleGrid>
              </Stack>
            ))}
          </Stack>
        </Card>
      )}
    </>
  )
}
export default LootdropsView
