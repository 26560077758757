import { useTranslation } from 'summon-ui/intl'
import { Button } from 'summon-ui/mantine'

interface Props {
  onStartTask: () => void
  isDisabled: boolean
  loading?: boolean
  text?: string
}

const StartButton = ({ onStartTask, isDisabled, loading = false, text }: Props) => {
  const t = useTranslation()

  return (
    <Button
      data-testid='quest-details-task-button'
      onClick={onStartTask}
      miw={145}
      disabled={isDisabled}
      w='100%'
      loading={loading}
    >
      {text || t('Start')}
    </Button>
  )
}

export default StartButton
