if (typeof window !== 'undefined') {
  // Check if window.top is not null and ensure window.self is not the same as window.top
  if (window.top && window.top !== window.self) {
    try {
      // Attempt to redirect the top window to the current window's location
      window.top.location.href = window.self.location.href
    } catch (error) {
      console.error('Redirection failed due to frame restrictions:', error)
    }
  }
}
