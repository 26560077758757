import type { IconsLibrary } from 'summon-ui/awesome-icons'
import {
  faHourglass as faHourglassRegular,
  faTreasureChest as faTreasureChestRegular,
  faHouse as faHouseRegular,
  faSwords as faSwordsRegular,
  faToolbox as faToolboxRegular,
  faCoins as faCoinsRegular,
  faTrophy as faTrophyRegular,
  faCartShopping as faCartShoppingRegular,
  faClockRotateLeft as faClockRotateLeftRegular,
  faGear as faGearRegular,
  faArrowRightFromBracket as faArrowRightFromBracketRegular,
  faCopy as faCopyRegular,
  faCheck as faCheckRegular,
  faArrowLeftFromBracket as faArrowLeftFromBracketRegular,
  faStars as faStarsRegular,
  faLock as faLockRegular,
  faCircleInfo as faCircleInfoRegular,
  faChevronsRight as faChevronsRightRegular
} from 'summon-ui/awesome-icons/regular'

// Add the new icons to the array
const iconsLibrary: IconsLibrary = [
  faHourglassRegular,
  faTreasureChestRegular,
  faHouseRegular,
  faSwordsRegular,
  faToolboxRegular,
  faCoinsRegular,
  faTrophyRegular,
  faCartShoppingRegular,
  faClockRotateLeftRegular,
  faGearRegular,
  faArrowRightFromBracketRegular,
  faCopyRegular,
  faCheckRegular,
  faArrowLeftFromBracketRegular,
  faStarsRegular,
  faLockRegular,
  faCircleInfoRegular,
  faChevronsRightRegular
]

export default iconsLibrary
