import { useEffect, useState } from 'react'
import { useTimeout } from 'summon-ui/mantine'
import type { Task } from '@generated/generates'

const useTaskCompletedAnimation = ({ task }: { task: Task }) => {
  const [showXP, setShowXP] = useState(false)
  const { start, clear } = useTimeout(() => setShowXP(false), 1000)

  const [taskCompleted, setTaskCompleted] = useState(task.isCompleted || false)
  useEffect(() => {
    if (task.isCompleted !== taskCompleted) {
      setShowXP(true)
      start()
      setTaskCompleted(task.isCompleted)
    }
    return clear
  }, [task])

  return {
    showXP
  }
}

export default useTaskCompletedAnimation
