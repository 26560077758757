import { QuestCategoryCard } from 'summon-ui/g7'
import { Box } from 'summon-ui/mantine'
import type { Category } from '@generated/generates'
import { useCustomNavigate } from '@hooks'
import { resolveVariant } from '@utils/questsHelpers'

interface Props {
  category: Category
}

const CategoryCard = ({ category }: Props) => {
  const { id, urlSlug, name, description, imageUrl } = category
  const questVariant = resolveVariant(category?.completionPercentage || 0)
  const navigate = useCustomNavigate()

  const Card = (
    <QuestCategoryCard
      key={id}
      name={name}
      variant={questVariant}
      imgUrl={imageUrl ?? undefined}
      description={description || ''}
      {...(category?.completionPercentage ? { completionPercentage: category?.completionPercentage } : {})}
      {...(category?.totalXP ? { totalXP: category?.totalXP } : {})}
      {...(category?.totalCredits ? { totalCredits: category?.totalCredits } : {})}
    />
  )

  return (
    <Box key={id} onClick={() => navigate(urlSlug || id)}>
      {Card}
    </Box>
  )
}

export default CategoryCard
