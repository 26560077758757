import { Box } from 'summon-ui/mantine'
import { HistoryViews } from '@features/history'
import { withProtectedRoute } from '@hocs'

const HistoryPage = () => {
  return (
    <Box className='h-[calc(100vh-100px)]'>
      <HistoryViews />
    </Box>
  )
}

export default withProtectedRoute(HistoryPage)
