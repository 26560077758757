import type { ReactNode } from 'react'
import { Spoiler } from 'summon-ui/mantine'

interface Props {
  children: ReactNode
  maxHeight?: number
}

const ReadMore = ({ children, maxHeight = 75 }: Props) => {
  return (
    <Spoiler
      className='[&>button]:font-bold [&>button]:text-sm'
      maxHeight={maxHeight}
      showLabel='Show More'
      hideLabel='Show Less'
    >
      {children}
    </Spoiler>
  )
}

export default ReadMore
