import { type Chain } from 'wagmi/chains'

export const GAME7_TESTNET_CHAIN: Chain = {
  id: 13746,
  name: 'Game7 Testnet',
  // @ts-ignore
  network: 'Game7-testnet',
  nativeCurrency: {
    name: 'G7 Testnet Token',
    symbol: 'TG7T',
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ['https://testnet-rpc.game7.io']
    },
    public: {
      http: ['https://testnet-rpc.game7.io']
    }
  },
  blockExplorers: {
    default: {
      name: 'Game7 Testnet Explorer',
      url: 'https://testnet.game7.io'
    }
  }
}

export const GAME7_CHAIN: Chain = {
  id: 2187,
  name: 'Game7',
  // @ts-ignore
  network: 'Game7',
  nativeCurrency: {
    name: 'G7 Token',
    symbol: 'G7',
    decimals: 18
  },
  rpcUrls: {
    default: {
      http: ['https://mainnet-rpc.game7.io']
    },
    public: {
      http: ['https://mainnet-rpc.game7.io']
    }
  },
  blockExplorers: {
    default: {
      name: 'Game7 Explorer',
      url: 'https://mainnet.game7.io'
    }
  }
}

export const MANTLE_TESTNET_CHAIN: Chain = {
  id: 5001,
  name: 'Mantle',
  // @ts-ignore
  network: 'mantle',
  nativeCurrency: {
    decimals: 18,
    name: 'Bit',
    symbol: 'BIT'
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.testnet.mantle.xyz/']
    },
    public: {
      http: ['https://rpc.testnet.mantle.xyz/']
    }
  },
  blockExplorers: {
    default: { name: 'MantleTestnetExplorer', url: 'https://explorer.testnet.mantle.xyz/' }
  },
  testnet: true
}
