import { Box, Image, NumberFormatter, Stack, Text } from 'summon-ui/mantine'
import type { CreditsLeaderboard, ReferralLeaderboard } from '@generated/generates'
import { sliceAddress } from '@utils/strings'

const TopUserBubble = ({
  creditsAmount,
  username,
  avatarUrl,
  firstName,
  walletAddress,
  position,
  ...props
}: CreditsLeaderboard | ReferralLeaderboard) => {
  return (
    <Stack align='center' justify='center' gap='xs' mt={position === 1 ? '-40' : '20'} {...props}>
      <Text tt='capitalize' fw='bold'>
        {username || firstName || sliceAddress(walletAddress!)}
      </Text>
      <Box className={`relative overflow-visible ${position === 1 ? 'size-[156px]' : 'size-[128px]'}`}>
        <Image src={`/images/credits/top-${position}-bg.svg`} className='absolute  top-0' style={{ scale: '1.4' }} />
        <Image src={`/images/credits/top-${position}.svg`} className='bg-contain bg-no-repeat absolute p-4' />
        <Image src={avatarUrl} className='p-8 rounded-full relative' />
      </Box>

      <Stack ta='center' gap='0'>
        <Text size='xl' fw='bold' c='yellow.6'>
          <NumberFormatter thousandSeparator value={Math.floor(creditsAmount || 0)} />
        </Text>
        <Text size='sm' c='yellow.1'>
          Credits Won
        </Text>
      </Stack>
    </Stack>
  )
}

export default TopUserBubble
