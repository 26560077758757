import { useGqlQuery } from '@api/gql/gqlServices'
import { type GetCategoryQuestsQuery, GetCategoryQuestsDocument, type Quest } from '@generated/generates'

interface Props {
  categoryId?: string
}

const useGetCategoryQuests = ({ categoryId }: Props) => {
  const { data: questsData, isLoading: isLoadingQuests } = useGqlQuery<GetCategoryQuestsQuery>(
    GetCategoryQuestsDocument,
    {
      categoryId
    },
    { enabled: !!categoryId }
  )

  return {
    quests: (questsData?.quests as Quest[]) || [],
    isLoadingQuests
  }
}

export default useGetCategoryQuests
