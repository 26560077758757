import { G7Icon, Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  alpha,
  Box,
  Card,
  Divider,
  Flex,
  getGradient,
  NumberFormatter,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme
} from 'summon-ui/mantine'

interface Props {
  totalMultiplier: string
  levelMultiplier: string
  lockupMultiplier: string
  diamonds: string
}

const RewardsInfo = ({ totalMultiplier, levelMultiplier, lockupMultiplier, diamonds }: Props) => {
  const t = useTranslation()
  const theme = useMantineTheme()
  const gradient = getGradient({ deg: 90, from: 'red.6', to: 'blue.3' }, theme)

  const TotalMultiplierTooltip = () => {
    return (
      <>
        <Text size='xs' pb='4'>
          {t('Breakdown')}:
        </Text>
        <Text size='xs'>
          {t('Lockup Multiplier')}: {lockupMultiplier}x
        </Text>
        <Text size='xs'>
          {t('Level Multiplier')}: {levelMultiplier}x
        </Text>
        <Divider my='6' />
        <Text size='xs'>
          {t('Total multiplier')}: {totalMultiplier}x
        </Text>
      </>
    )
  }

  return (
    <Box>
      <Title mb='xs' order={5}>
        {t('Rewards')}
      </Title>
      <Card withBorder bg={gradient} radius='md' p='0'>
        <Stack m='1' p='sm' className='rounded-lg' gap='xs' bg={alpha('white', 0.8)}>
          <Flex justify='space-between'>
            <Flex align='center'>
              <Text>{t('Total Multiplier')}</Text>
              {totalMultiplier != '-' && (
                <Tooltip withArrow multiline label={<TotalMultiplierTooltip />} w={150} position='right'>
                  <Box>
                    <Icon name='InfoCircle' />
                  </Box>
                </Tooltip>
              )}
            </Flex>
            <Text fw='bold' c='skyblue.5'>
              {totalMultiplier}
            </Text>
          </Flex>
          <Flex justify='space-between'>
            <Flex align='center'>
              <Text>{t('Daily Diamonds')}</Text>
            </Flex>
            <Flex gap='xs' align='center' className='text-skyblue-5'>
              <G7Icon name='diamonds' pfx='fak' />
              <NumberFormatter className='font-bold' value={diamonds} thousandSeparator />
            </Flex>
          </Flex>
        </Stack>
      </Card>
    </Box>
  )
}

export default RewardsInfo
