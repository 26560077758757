import { TENANT_CONFIG } from '@config'
import { useAvatarCapture } from '@modules/avatar/context/AvatarCaptureProvider'
import ShareAvatar from './ShareAvatar'
import ShareButton from './ShareButton'
import ShareTweet from './ShareTweet'
import type { SharingItem, SocialNetwork } from './types'

const useShare = (sharingItem: SharingItem) => {
  const { downloadAvatar } = useAvatarCapture()

  const getSharingInfo = () => {
    // Defaults to 'avatar'
    const currentDomain = window?.location?.origin
    let info

    switch (sharingItem) {
      case 'tweet':
        info = {
          Component: <ShareTweet />,
          postText:
            "I just ratified @G7_DAO's Manifesto. If you want to join this growing nation of gamers, become a Founding Citizen now and help build the future of gaming! https://app.game7.io/quests/daily-quests"
        }
        break
      default: // Defaults to 'avatar'
        info = {
          Component: <ShareAvatar />,
          postText: `Flexing my new avatar from ${TENANT_CONFIG.name}. If you want your own, go to ${currentDomain}`,
          downloadSharingImg: downloadAvatar
        }
        break
    }

    return info
  }

  const renderSharingButton = (network: SocialNetwork) => {
    let Component = null
    let url
    switch (network) {
      case 'X':
        url = `https://x.com/intent/post?text=${encodeURIComponent(getSharingInfo().postText)}`
        Component = <ShareButton url={url} />
        break
      default:
        break
    }

    return Component
  }

  return {
    getSharingInfo,
    renderSharingButton
  }
}

export default useShare
