import { NavLink as RouterNavLink, useLocation, useMatch, useResolvedPath } from 'react-router-dom'
import { AwesomeIcon, G7Icon } from 'summon-ui'
import type { G7IconName, IconName, IconPrefix } from 'summon-ui/awesome-icons'
import { useTranslation } from 'summon-ui/intl'
import { Badge, Box, NavLink, Title, useMantineColorScheme } from 'summon-ui/mantine'
import { TutorialIds } from '@hocs/Tutorial/helpers.tutorial'
import { useViewer } from '@hooks'
import { NAVIGATION_MENU } from '@layouts/DashboardLayout/constants'

const getTutorialIdName = (linkItem: string) => {
  if (linkItem === 'Quests') {
    return TutorialIds.Quests
  } else if (linkItem === 'Lootdrop') {
    return TutorialIds.Lootdrop
  } else if (linkItem === 'Credits') {
    return TutorialIds.Credits
  } else if (linkItem === 'Leaderboard') {
    return TutorialIds.Leaderboard
  } else if (linkItem === 'Marketplace') {
    return TutorialIds.Marketplace
  }
  return null
}

const NavigationMenu = ({ isSmall }: { isSmall: boolean }) => {
  const { pathname } = useLocation()
  const t = useTranslation()
  const { colorScheme } = useMantineColorScheme()
  const { isAuthenticated } = useViewer()

  return (
    <>
      {NAVIGATION_MENU.map(({ iconName, soon, isNew, locked = false, pfx, ...link }) => {
        const tutorialSelectorId = !soon && getTutorialIdName(link.name)
        const isLocked = !isAuthenticated && locked
        const resolvedPath = useResolvedPath(link.to)
        const match = useMatch({ path: resolvedPath.pathname, end: true })
        const isActive = (match && !soon && !isLocked) || false

        return (
          <NavLink
            key={link.name}
            component={RouterNavLink}
            to={soon || isLocked ? pathname : link.to}
            data-testid={`navigation-menu-${link.name}-link`}
            leftSection={
              pfx === 'fak' ? (
                <G7Icon name={iconName as G7IconName} pfx={pfx} color={colorScheme === 'light' ? 'gray.7' : 'white'} />
              ) : (
                <AwesomeIcon
                  pfx={pfx as IconPrefix}
                  color={colorScheme === 'light' ? 'gray.7' : 'white'}
                  name={iconName as IconName}
                />
              )
            }
            w={isSmall ? 50 : '100%'}
            active={isActive}
            disabled={soon || isLocked}
            pos='relative'
            {...(isSmall
              ? {}
              : {
                  label: (
                    <Title order={6} tt='uppercase'>
                      {link.name}
                    </Title>
                  )
                })}
            {...(soon && !isSmall ? { rightSection: <Badge size='xs'>{t('Soon')}</Badge> } : {})}
            {...(isNew
              ? {
                  rightSection: !isSmall ? (
                    <Badge size='xs' bg='yellow.1' c='black'>
                      {t('New')}
                    </Badge>
                  ) : (
                    <Box pos='absolute' right={-5} bottom={-5}>
                      <AwesomeIcon name='stars' pfx='far' color='yellow.1' />
                    </Box>
                  )
                }
              : {})}
            {...(isLocked && !isSmall ? { rightSection: <AwesomeIcon name='lock' pfx='far' /> } : {})}
            {...(tutorialSelectorId ? { id: tutorialSelectorId } : {})}
          />
        )
      })}
    </>
  )
}

export default NavigationMenu
