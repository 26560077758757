import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'summon-ui/intl'
import { Modal, Button, Text, Box, Group, Image, Stack, Title, Card } from 'summon-ui/mantine'
import { useCreditsConversion } from '@hooks/balances'

interface Props {
  open: boolean
  onClose: () => void
}

const ConversionSuccessModal = ({ open, onClose }: Props) => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { tokensReceive } = useCreditsConversion()

  const handleGoToStake = () => {
    navigate('/staking/options/stake', { replace: true })
  }

  return (
    <Modal opened={open} onClose={onClose} withCloseButton={false} radius='md'>
      <Box p='xs'>
        <Image src='/images/staking/successful-claim.png' alt='success claim' h='100%' radius='md' />
        <Stack justify='center' align='center' gap='0' py='lg'>
          <Title order={2}>{t('$G7 Claimed')}</Title>
          <Text ta='center'>
            {t('You have successfully claimed')} {tokensReceive} G7
          </Text>
        </Stack>

        <Card
          withBorder
          radius='md'
          style={{
            border: '1px solid #E0F2FE'
          }}
        >
          {t(
            'You could earn 3x+ more Diamonds plus a Diamond Hands SBT to access future airdrops, if you lock up for 180 days.'
          )}
        </Card>

        <Group justify='space-between' wrap='nowrap' mt='xl'>
          <Button fullWidth onClick={handleGoToStake}>
            {t('Stake tokens')}
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default ConversionSuccessModal
