import { TENANT_CONFIG } from '@config'

export const G7LevelUpData = (level: number) =>
  ({
    0: {
      header: 'Level 1',
      subheader: 'Welcome, explorer.',
      description:
        "As dusk settles over the sprawling metropolis, you find yourself at the heart of a bustling Night Market, not merely as a bystander but as a keen observer poised to unravel the city's deepest secrets. Here, an oppressive regime known as the Council of Shadows wields it’s dark magic, controlling the city with an iron grip. It thrives on the unseen and the unsaid, where every whisper holds weight, and every shadow could be friend or foe. Your past is a mystery, but clarity will come in time. At this point in our journey, you find a key and an address that leads you to a simple room stocked with food and a bed roll on the floor. For now, embrace your role as an explorer. Learn all you can, and choose your allies wisely in this treacherous world.",
      audio: `${TENANT_CONFIG.assetsPrefix}/audio/level0.mp3`,
      levelCollection: `${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general-card.jpg`,
      levelCollectionItems: [`${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general.png`],
      levelCollectionName: 'Wearable'
    },
    1: {
      header: 'Level 2',
      subheader: 'A friendly invite',
      description:
        "A boy approaches you. He looks friendly enough and invites you to join him and his friends to a landfill on the outskirts of the city to catch the sunset. You embark up the landfill full of forgotten gaming consoles and other lost technology littering the landscape, while your imagination is lured by tales of ancient magic that once bound our world together. Amidst the rubble, a peculiar piece of hardware catches your eye. Its digital imprint, a ghoulish frog, emits an eerie glow. The moment you grasp it, a surge courses through you, and a chilling realization sets in. The faces of your companions contort into grotesque reflections of the frog's face, a nightmarish experience that vanishes as quickly as it appeared when you drop the artifact. Confused, you tuck the device away, its secrets drawing you in. Safely concealed in your satchel, you wait to return home to explore it further.",
      audio: `${TENANT_CONFIG.assetsPrefix}/audio/level1.mp3`,
      levelCollection: `${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general-card.jpg`,
      levelCollectionItems: [`${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general.png`],
      levelCollectionName: 'Wearable'
    },
    2: {
      header: 'Level 3',
      subheader: 'An unexpected visit',
      description:
        "That night, you retreat to your room, the artifact from the landfill still hidden in your satchel. Its surface bears the faint, lingering traces of the unearthly glow, now dulled and fractured. You wonder whether the bizarre encounter earlier was a figment of your imagination. As you prepare for sleep, the boy and what must be his mother knock on your door. They brought you some homemade dinner featuring the most comforting tacos you've ever tasted. The mother’s expression quickly shifts from surprise to alarm upon seeing the piece of hardware. Recognition flashes across her face, tinged with a hint of apprehension. With urgency in her voice, she implores you to conceal it, and not show anyone. She cautions you about its inherent danger, and she pledges to reveal the truth behind its significance the next day.",
      audio: `${TENANT_CONFIG.assetsPrefix}/audio/level2.mp3`,
      levelCollection: `${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general-card.jpg`,
      levelCollectionItems: [`${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general.png`],
      levelCollectionName: 'Wearable'
    },
    3: {
      header: 'Level 4',
      subheader: 'The underground',
      description:
        "Later the next day, you run into the boy on the street again. He invites you to help with his daily errands. You have nothing else to do, so you oblige. He tells you that his last errand is to bring you to the outskirts of the city. You gather your belongings and set off. There, shrouded in mysterious attire, stands his mother cloaked in a hood and dark clothing. With a hush, she guides you into the depths of the cities catacombs, unveiling a world hidden beneath the city's facade. Here, you meet the PlayMasters which is the first of the Rebel Factions you will encounter. You are greeted by whispers that speak of your arrival as a fulfillment of prophecy. Surrounded by other citizens immersed in various tasks like crafting, experimenting, and strategizing aimed at reclaiming a lost legacy.",
      audio: `${TENANT_CONFIG.assetsPrefix}/audio/level3.mp3`,
      levelCollection: `${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general-card.jpg`,
      levelCollectionItems: [`${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general.png`],
      levelCollectionName: 'Wearable'
    },
    4: {
      header: 'Level 5',
      subheader: 'A strange warning',
      description:
        "That night, as the camp falls into a deep sleep, you find yourself jolted awake in the dead of night, confronted once again by the haunting, ghoulish frog. Its presence fills your tent with an ominous weight, its croak resonating with impending doom, reverberating through the walls around you. Gazing into its eyes, you feel unsettled, as if it peers into the depths of your soul. Its words carry a dire warning, warning you if you go any further down this path, it will lead to nothing but destruction and ruin. “The Cabal will always find you”. As the frog's words echo through the chamber, your surroundings warp and shift, the catacombs fading into the cold air. You now find yourself in a cold wet dungeon. The frog now larger than life fills the space at first, but grows smaller as he hops forward into the darkness, leaving you alone once again.",
      audio: `${TENANT_CONFIG.assetsPrefix}/audio/level4.mp3`,
      levelCollection: `${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general-card.jpg`,
      levelCollectionItems: [`${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general.png`],
      levelCollectionName: 'Wearable'
    },
    5: {
      header: 'Level 6',
      subheader: 'Awake again, maybe',
      description:
        'The darkness fades to the small fire being stoked by the Mother in the catacombs. Was it merely a dream, or did it hold deeper significance? Despite the lingering unease, you bury the memory and don’t tell a soul. As you gather around other citizens for the day, in walks Mother. She tells you how the artifact unlocks a portal into a secondary world. How there are hundreds of these worlds and it’s our job as rebels to enter into them and find the missing keys, massive rewards, and other artifacts that have been lost for thousands of years. The weight of responsibility is heavy as she tells you that your training must start right away, and the factions rely on your success. Your bravery is all that matters now.',
      audio: `${TENANT_CONFIG.assetsPrefix}/audio/level5.mp3`,
      levelCollection: `${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general-card.jpg`,
      levelCollectionItems: [`${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general.png`],
      levelCollectionName: 'Wearable'
    },
    6: {
      header: 'Level 7',
      subheader: 'The Playmasters',
      description:
        'All of a sudden, the catacombs open up, and you see hundreds of people of every age and race. “Welcome back, Helena. You sent word of a new artefact that landed in your procession?” “I didn’t find it. They did. I think there might be something special about our new friend.” “Welcome to the PlayMasters rebel faction. Go ahead and make camp. Dinner will be served shortly.”',
      audio: `${TENANT_CONFIG.assetsPrefix}/audio/level6.mp3`,
      levelCollection: `${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general-card.jpg`,
      levelCollectionItems: [`${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general.png`],
      levelCollectionName: 'Wearable'
    },
    7: {
      header: 'Level 8',
      subheader: 'The Frog and the Cabal',
      description:
        'You set up camp and fall asleep to the sounds of the undercity. Suddenly, you are jolted awake in the dead of night, confronted once again by the haunting ghoulish frog from the landfill. Its presence fills your tent, and its croak resonates with impending doom, reverberating through the walls around you. “RUN. Go back home. This road only leads to death and destruction. The Cabal always finds you.” Your surroundings warp and you now find yourself in a cold, wet dungeon. He hops forward into the darkness, leaving you alone once again.',
      audio: `${TENANT_CONFIG.assetsPrefix}/audio/level7.mp3`,
      levelCollection: `${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general-card.jpg`,
      levelCollectionItems: [`${TENANT_CONFIG.assetsPrefix}/levelUp/reward-general.png`],
      levelCollectionName: 'Wearable'
    }
  })[level]
