import { Html, Loader } from '@react-three/drei'

const AvatarLoader = () => {
  return (
    <Html center>
      <Loader
        containerStyles={{
          marginTop: '-300px'
        }}
      />
    </Html>
  )
}

export default AvatarLoader
