import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { NavlessLayout as SummonUiNavlessLayout } from 'summon-ui/g7'
import { Flex, Paper } from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { ConnectButton } from '@components'
import { useViewer } from '@hooks'
import UserSection from '@layouts/DashboardLayout/UserSection'
import { DEFAULT_AVATAR_IMAGE } from '@layouts/DashboardLayout/constants'

const NavlessLayout = () => {
  const { viewer, isAuthenticated } = useViewer()
  const { address } = useAccount()

  const accountName = viewer?.username || (viewer?.firstName ? viewer?.firstName + ' ' + viewer?.lastName : '')

  const renderMenu = (): ReactNode => {
    return isAuthenticated ? (
      <Flex gap='lg' align='center' w='100%'>
        <Paper withBorder radius='md'>
          <UserSection
            isSmall={false}
            userName={accountName}
            userAvatarUrl={viewer?.avatarUrl || DEFAULT_AVATAR_IMAGE}
            userWalletAddress={address as string}
          />
        </Paper>
      </Flex>
    ) : (
      <ConnectButton isXL={false} />
    )
  }

  return <SummonUiNavlessLayout renderMenu={renderMenu} render={() => <Outlet />} />
}

export default NavlessLayout
