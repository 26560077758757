import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useGqlMutation } from '@api/gql/gqlServices'
import { OpenG7BoxDocument } from '@generated/generates'
import type { BoxReward, OpenG7BoxMutation } from '@generated/generates'

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong'

const useOpenG7Box = () => {
  const [rewards, setRewards] = useState<BoxReward[]>()

  const {
    mutateAsync: openG7Box,
    isError,
    isPending: isOpenMutationPending
  } = useGqlMutation<OpenG7BoxMutation>(OpenG7BoxDocument, undefined)

  const open = async (g7BoxId: string, onSuccess: () => void, onError: () => void) => {
    try {
      const response = await openG7Box({ boxId: g7BoxId })
      setRewards(response.openBox as BoxReward[])
      onSuccess()
    } catch {
      onError()
      showNotification({
        variant: 'danger',
        message: DEFAULT_ERROR_MESSAGE
      })
    }
  }

  return {
    open,
    isError,
    rewards,
    isOpening: isOpenMutationPending
  }
}

export default useOpenG7Box
