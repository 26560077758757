import { sliceAddress } from '@utils/strings'
import type { LineaPlayerLeaderboardTableElement, LineaPlayerLeaderboardUser } from '../types'

const buildUpLineaPlayerLeaderboardTableData = (
  lineaPlayerLeaderboard: Array<LineaPlayerLeaderboardUser>,
  currentUserInfo?: LineaPlayerLeaderboardUser
): Array<LineaPlayerLeaderboardTableElement> => {
  const updatedLineaPlayerLeaderboard = [
    ...(currentUserInfo?.walletAddress
      ? [
          {
            rank: currentUserInfo.position,
            name: {
              avatarUrl: currentUserInfo.avatarUrl,
              username: currentUserInfo.username || sliceAddress(currentUserInfo.walletAddress as string, 5)
            },
            points: currentUserInfo.points,
            multiplier: currentUserInfo.lootDropMultiplier
          }
        ]
      : []),
    ...lineaPlayerLeaderboard.map((user) => ({
      rank: user.position,
      name: {
        avatarUrl: user.avatarUrl,
        username: user.username || sliceAddress(user.walletAddress as string, 5)
      },
      points: user.points,
      multiplier: user.lootDropMultiplier
    }))
  ]

  return updatedLineaPlayerLeaderboard
}

export { buildUpLineaPlayerLeaderboardTableData }
