import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { useUpdateViewerSettings } from '@hooks'

const useUpdateLineaGuild = () => {
  const t = useTranslation()
  const [isUpdatingLineaGuild, setIsUpdatingLineaGuild] = useState(false)
  const { updateViewerSettings } = useUpdateViewerSettings()

  const updateLineaGuild = async ({
    lineaGuild,
    onSuccess,
    onFinish
  }: {
    lineaGuild: string
    onSuccess: () => void
    onFinish: () => void
  }) => {
    setIsUpdatingLineaGuild(true)
    try {
      await updateViewerSettings({ lineaGuild })
      showNotification({
        variant: 'success',
        message: t('Linea guild updated successfully')
      })
      onSuccess()
    } catch (error) {
      console.error(error)
      showNotification({
        variant: 'danger',
        message: t('There was an error updating the Linea guild')
      })
    } finally {
      setIsUpdatingLineaGuild(false)
      onFinish()
    }
  }

  return {
    updateLineaGuild,
    isUpdatingLineaGuild
  }
}

export default useUpdateLineaGuild
