import { Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from '@components'
import { TENANT_CONFIG } from '@config'
import { Tenant } from '@config/enum'
import { Game7OnboardingView } from './views/Game7/Game7Onboarding.view'

export const OnboardingViews = () => {
  const Onboarding = () => {
    switch (TENANT_CONFIG.name) {
      case Tenant.G7:
        return <Game7OnboardingView />
      default:
        return <Game7OnboardingView />
    }
  }
  return (
    <Routes>
      <Route path='/' index element={<Onboarding />} errorElement={<ErrorBoundary />} />
    </Routes>
  )
}
