import { useState, type ReactNode } from 'react'
import { useTranslation } from 'summon-ui/intl'
import { Button, Text } from 'summon-ui/mantine'
import { OpenG7BoxFlow } from '../../G7BoxFlows'
import CTABox from './CTABox'
import type { SlotBoxVariantElementsComponent } from './types'

interface Props {
  boxId: string
  Image: ReactNode
}

const Ready = ({ boxId, Image }: Props): SlotBoxVariantElementsComponent => {
  const t = useTranslation()
  const [isBoxOpened, setIsBoxOpened] = useState(false)

  const handleBoxOpenSuccessful = () => {
    setIsBoxOpened(true)
  }

  return {
    TitleElement: (
      <Text fw='bold' size='xs' c='green.2'>
        {t('Ready to claim')}
      </Text>
    ),
    BodyElement: (
      <>
        {Image}
        <OpenG7BoxFlow
          boxId={boxId}
          onFlowSuccess={handleBoxOpenSuccessful}
          render={(open: () => void, isOpening: boolean, canOpen) => {
            return !isBoxOpened ? (
              <CTABox>
                <Button bg='green' onClick={open} disabled={!canOpen} loading={isOpening}>
                  {t('Open')}
                </Button>
              </CTABox>
            ) : null
          }}
        />
      </>
    )
  }
}

export default Ready
