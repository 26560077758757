import { type QuestCategoryCardVariant } from 'summon-ui/g7'
import type { Quest, Task } from '@generated/generates'

const calculateQuestCompletionInfo = (
  tasks: Task[] | undefined
): { completionPercentage: number; totalXP: number; earnedXP: number } => {
  const totalTasks = tasks?.length ?? 1
  let completedTasksCount = 0
  let totalXP = 0
  let earnedXP = 0

  tasks?.forEach((task: Task) => {
    totalXP += task.earnXP ?? 0
    if (task.isCompleted) {
      completedTasksCount++
      earnedXP += task.earnXP ?? 0
    }
  })

  const completionPercentage = Math.round((completedTasksCount / totalTasks) * 100)

  return {
    completionPercentage,
    totalXP,
    earnedXP
  }
}

const calculateMultipleQuestsCompletionInfo = (
  quests: Quest[] | undefined
): { completionPercentage: number; totalXP: number } => {
  if (!quests || quests.length === 0) {
    return { completionPercentage: 0, totalXP: 0 }
  }

  let totalCompletedTasks = 0
  let totalTasks = 0
  let totalXP = 0

  quests.forEach((quest: Quest) => {
    let questCompletedTasks = 0
    let questTotalTasks = 0

    quest.tasks.forEach((task: Task) => {
      questTotalTasks++
      if (task.isCompleted) {
        questCompletedTasks++
        totalXP += task.earnXP
      }
    })

    totalCompletedTasks += questCompletedTasks
    totalTasks += questTotalTasks
  })

  const completionPercentage = totalTasks === 0 ? 0 : Math.round((totalCompletedTasks / totalTasks) * 100)

  return {
    completionPercentage,
    totalXP
  }
}

const resolveVariant = (completionPercentage: number): QuestCategoryCardVariant => {
  // TODO: We need to revisit the QuestCategoryCard variants now that the approach is different
  const isCompleted = completionPercentage && completionPercentage === 100
  // TODO: Hardcoding category timer and coming soon to null so no card is of this type yet. In the future this might come from back end
  // const isComingSoon = false
  // const timer = null

  if (!isCompleted && completionPercentage === 0) {
    return 'not-started'
  } else if (!isCompleted && completionPercentage && completionPercentage > 0 && completionPercentage < 100) {
    return 'on-going'
  } else if (isCompleted) {
    return 'completed'
  } else {
    return 'coming-soon'
  }
}

type Reward = {
  units?: number
  [key: string]: any // This allows for additional fields
}

const calculateTotalRewardUnits = (rewards: Reward[] | null | undefined): number => {
  let totalUnits = 0

  // Check if the rewards array is defined and not null
  if (Array.isArray(rewards)) {
    // Loop through each reward in the array
    rewards.forEach((reward) => {
      // Add the reward's units to the total (default to 0 if units is undefined)
      totalUnits += reward.units || 0
    })
  }

  // Return the total units
  return totalUnits
}

export {
  calculateQuestCompletionInfo,
  calculateMultipleQuestsCompletionInfo,
  calculateTotalRewardUnits,
  resolveVariant
}
