import type { ReactNode } from 'react'
import { Spinner, useMediaScreen } from 'summon-ui'
import { Box, Center } from 'summon-ui/mantine'

interface Props {
  isLoader?: boolean
  children?: ReactNode
}

const PageCenteredWrapper = ({ children, isLoader }: Props) => {
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })
  const isMini = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const isTablet = useMediaScreen({ type: 'max', breakpoint: 'laptop' })
  const isLaptop = useMediaScreen({ type: 'max', breakpoint: 'desktop' })

  const mapPadding = {
    mobile: 400,
    mini: 500,
    tablet: 600,
    laptop: 650,
    minDesktop: 550
  }

  const getPaddingTop = () => {
    if (isMobile) return mapPadding.mobile
    if (isMini) return mapPadding.mini
    if (isTablet) return mapPadding.tablet
    if (isLaptop) return mapPadding.laptop
    else {
      // isMinDesktop
      return mapPadding.minDesktop
    }
  }

  return (
    <Box h={getPaddingTop()} className='!border-red-4'>
      {isLoader ? <Spinner isFull /> : <Center h='100%'>{children}</Center>}
    </Box>
  )
}

export default PageCenteredWrapper
