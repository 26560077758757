import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { TENANT_CONFIG } from '@config'
import { useChangeAddressLogout, useLogout, useViewer } from '@hooks'

const tenatHasTokenGating = TENANT_CONFIG.features?.tokenGating

const useOnboarding = () => {
  const { logout } = useLogout()
  const navigate = useNavigate()
  const { isConnected } = useAccount()
  const { viewer, status: viewerStatus } = useViewer()
  const [isProcessing, setIsProcessing] = useState(true)

  const userHasCompletedOnboarding = viewer?.isOnboardingCompleted

  // handle redirection
  const { state } = useLocation()
  const redirectPath = state?.redirect || '/'

  useChangeAddressLogout()

  useEffect(() => {
    if (!isConnected || viewer?.isOnboardingCompleted) navigate(redirectPath)
  }, [isConnected])

  useEffect(() => {
    // In this use Effect we check first everything non related to token gating.
    // And if token gating is needed we will trigger the next useEffect
    const isAllInformationAvailable =
      viewer != null && userHasCompletedOnboarding != null && tenatHasTokenGating !== null

    if (viewerStatus === 'error' || (viewerStatus === 'success' && viewer === null)) logout()
    if (isAllInformationAvailable) {
      const mustRedirectToHome = userHasCompletedOnboarding
      const mustLogout = !isConnected

      if (mustRedirectToHome) navigate(redirectPath)
      else if (mustLogout) logout()
      else {
        // We are here it means no redirection applied and no token
        // gating needed so we can render in the onboarding
        setIsProcessing(false)
      }
    }
  }, [isConnected, viewerStatus, viewer, userHasCompletedOnboarding])

  return { isProcessing, logout }
}

export default useOnboarding
