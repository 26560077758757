import { useState } from 'react'

type PurchaseG7BoxStep = 'purchase-modal' | undefined

const usePurchaseG7BoxSteps = () => {
  const [step, setStep] = useState<PurchaseG7BoxStep>()

  const handleResetStep = () => {
    setStep(undefined)
  }
  const handleGoToPurchaseModalStep = () => {
    setStep('purchase-modal')
  }

  return {
    isPurchaseModalStep: step === 'purchase-modal',
    goToPurchaseModalStep: handleGoToPurchaseModalStep,
    resetStep: handleResetStep
  }
}

export default usePurchaseG7BoxSteps
