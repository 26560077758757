import { Suspense, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import type { InventoryItem } from '@generated/generates'
import { ItemType } from '@hooks/inventory/useAvatarMetadata'
import useItems from '@hooks/inventory/useItems'
import { usePreviewItems } from '../store/usePreviewItems.store'
import { filterIncompatibleItems } from '../utils/filterItems'
import AvatarLoader from './AvatarLoader'
import Mesh from './Mesh'

const COMPANION_POSITION = [-0.41, -0.02, 0] as [number, number, number]

const EquipedItems = ({ preview }: { preview?: boolean }) => {
  const { items } = useItems({})
  const { pathname } = useLocation()
  const { previewItems, setPreviewItems } = usePreviewItems()
  const compatibleItems = filterIncompatibleItems(
    //@ts-ignore
    preview ? previewItems : items.filter((item) => item.isEquipped)
  )
  const isOnboarding = pathname.includes('onboarding')
  useEffect(() => {
    if (preview)
      setPreviewItems(
        //@ts-ignore
        items.filter((item) => item.isEquipped),
        isOnboarding
      )
  }, [preview, items])

  // Memoized function to create Mesh components for items
  const createMeshComponent = useCallback((item: InventoryItem, index: number) => {
    const { tokenId, glbUrl, itemType } = item
    const key = `avatar-equipped-item-${itemType === ItemType.Companion ? 'companion-' : ''}${tokenId}-${index}`

    return (
      <Mesh
        key={key}
        uri={glbUrl}
        position={itemType === ItemType.Companion ? COMPANION_POSITION : undefined}
        usePrimitive={itemType === ItemType.Companion}
      />
    )
  }, [])

  return (
    <>
      {compatibleItems.map((item) => (
        <Suspense key={item.tokenId} fallback={<AvatarLoader />}>
          {createMeshComponent(item, 1)}
        </Suspense>
      ))}
    </>
  )
}

export default EquipedItems
