import { useState, useEffect } from 'react'

type StakingPosition = {
  id: string
  isLockup: boolean
  stakedTokens: number
  upfrontEarning: number
  dailyEarning: number
  unlockDate: string | null
}

const MOCKED_STAKING_POSITIONS: StakingPosition[] = [
  {
    id: '1',
    isLockup: true,
    stakedTokens: 2983,
    upfrontEarning: 100000,
    dailyEarning: 2900,
    unlockDate: 'Oct 31, 2024'
  },
  { id: '2', isLockup: false, stakedTokens: 2583, upfrontEarning: 30000, dailyEarning: 2700, unlockDate: null }
]

const useStakingPositions = () => {
  const [stakingPositions, setStakingPositions] = useState<StakingPosition[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate a backend API call with a delay
    const fetchStakingPositions = async () => {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 3000))
      setStakingPositions(MOCKED_STAKING_POSITIONS)
      setLoading(false)
    }

    fetchStakingPositions()
  }, [])

  return {
    stakingPositions,
    isStakingPositionsLoading: loading
  }
}

export default useStakingPositions
