import { AwesomeIcon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Text } from 'summon-ui/mantine'
import type { SlotBoxVariantElementsComponent } from './types'

const ComingSoon = (): SlotBoxVariantElementsComponent => {
  const t = useTranslation()
  return {
    TitleElement: (
      <Text fw='bold' size='xs' c='dark.5'>
        {t('Coming soon')}
      </Text>
    ),
    BodyElement: <AwesomeIcon name='hourglass' pfx='far' size='4x' color='dark.5' />
  }
}

export default ComingSoon
