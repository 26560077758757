const ONBOARDING_CONFIG = {
  avatar: {
    title: 'Create Avatar',
    subtitle:
      'This is your avatar and acts as your digital passport to Game7. It will be minted as an onchain "soul-bound," or non-transferrable, NFT.'
  },
  welcome: {
    subtitle: "You have now entered Game7 Nation. Go to the Quest page to see what's next."
  }
}

export default ONBOARDING_CONFIG
