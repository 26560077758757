import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'summon-ui/intl'
import { Box, Card, Title, Text, Group, Button, NumberFormatter } from 'summon-ui/mantine'

interface Props {
  isLockup: boolean
  stakedTokens: number
  upfrontEarning: number
  dailyEarning: number
  unlockDate: string | null
}

const StakingPositions = ({ isLockup, stakedTokens, unlockDate, upfrontEarning, dailyEarning }: Props) => {
  const t = useTranslation()
  const navigate = useNavigate()

  return (
    <Card withBorder radius='lg'>
      <Group justify='space-between'>
        <Box>
          <Title order={4}>
            {t(isLockup ? 'Locked-up' : 'Staked')}: <NumberFormatter thousandSeparator value={stakedTokens} />
          </Title>
          <Group gap='8'>
            {unlockDate && (
              <>
                <Text>
                  {t('Unlocks')}: {unlockDate}
                </Text>
                |
              </>
            )}
            <Text>
              {t('Total Earnings')}: <NumberFormatter thousandSeparator value={upfrontEarning} /> {t('Upfront and')}{' '}
              <NumberFormatter thousandSeparator value={dailyEarning} /> {t('Daily Diamonds')}
            </Text>
          </Group>
        </Box>
        {/* TODO: Add onClick with expected behaviour here */}
        <Button
          variant='white'
          onClick={() => {
            navigate('/staking/options/unstake')
          }}
        >
          {t('Unstake')}
        </Button>
      </Group>
    </Card>
  )
}

export default StakingPositions
