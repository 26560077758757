import clsx from 'clsx'
import { Spinner, useMediaScreen } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { Paper, Text, Image, Anchor, Flex, Button, Stack, NumberFormatter } from 'summon-ui/mantine'
import { useCreditsConversion, useUserCreditsBalance } from '@hooks/balances'
import styles from './converToTokensBanner.module.css'

const ConvertToTokensBanner = () => {
  const t = useTranslation()
  const isMini = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const { balance: userCreditsBalance, isLoading: isUserCreditsBalanceLoading } = useUserCreditsBalance()
  const { tokensReceive, isTokensReceiveLoading } = useCreditsConversion()

  const renderText = () => (
    <Text
      size='lg'
      style={{
        textShadow: '1px 1px 4px var(--mantine-color-skyblue-3)'
      }}
    >
      <FormattedMessage
        id='creditsToTokensId'
        values={{
          creditsAmount: <NumberFormatter thousandSeparator value={userCreditsBalance} />,
          tokensAmount: <NumberFormatter thousandSeparator value={tokensReceive || ''} />
        }}
      />
    </Text>
  )

  const renderButtonLink = () => (
    <Anchor href='/staking' w={isMini ? '100%' : 'auto'}>
      <Button w='100%'>{t('Convert now')}</Button>
    </Anchor>
  )

  return (
    <Paper radius='md' className={clsx('overflow-hidden', styles.banner)} px='xl' pos='relative'>
      <Flex align='center' justify={isMini ? 'center' : 'start'} h={isMini ? 140 : 110}>
        {isUserCreditsBalanceLoading || isTokensReceiveLoading ? (
          <Spinner />
        ) : (
          <Stack gap='xs' align={isMini ? 'center' : 'start'} justify='center' h='100%'>
            {renderText()}
            {renderButtonLink()}
          </Stack>
        )}
        <Image
          right={0}
          pos='absolute'
          top={0}
          src={
            isMini ? '/images/credits/credits-to-coins-floating-1.png' : '/images/credits/credits-to-coins-floating.png'
          }
          alt='credits-to-tokens'
          h='100%'
          maw={isMini ? '100%' : 470}
          className='-z-10'
        />
      </Flex>
    </Paper>
  )
}

export default ConvertToTokensBanner
