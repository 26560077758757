import {
  alpha,
  Box,
  Button,
  Card,
  CopyButton,
  Flex,
  Image,
  Paper,
  Stack,
  Text,
  Title,
  Tooltip
} from 'summon-ui/mantine'
import { ConnectButton, Breadcrumbs, InfoCard } from '@components'
import { useViewer } from '@hooks'
import CreditsCard from '../components/CreditsCard'
import ReferralsLeaderboardTable from '../components/ReferralsLeaderboardTable'
import ReferralsProgress from '../components/ReferralsProgress'
import useCreateReferralCode from '../hooks/useCreateReferralCode'
import useCreditsStats from '../hooks/useCreditsStats'
import useReferralsLeaderboard from '../hooks/useReferralsLeaderboard'

const ReferralsView = () => {
  const { creditsStats } = useCreditsStats()
  const { viewer } = useViewer()
  useCreateReferralCode({ enabled: !!(viewer && !viewer?.referralCode) })
  const { referralsCount } = useReferralsLeaderboard(50)

  return (
    <Stack gap='lg'>
      <Breadcrumbs lastText='Referrals' />
      <Paper withBorder radius='md' bg={alpha('black', 0.6)} p='lg'>
        <Title order={3}>Your Referral Progress</Title>
        <Box my='lg' py='lg' mr='40'>
          <ReferralsProgress value={Math.min(referralsCount, 100)} />
        </Box>
        <Flex direction={{ xs: 'column', md: 'row' }} gap='lg' py='lg'>
          <InfoCard
            info={creditsStats?.amountReferralCredits.toString() || 0}
            bg='transparent'
            description='Referral Credits Earned'
            tooltipInfo={{
              w: '300',
              label:
                ' You win bonus credits equal to 20% of the credits each person you refer wins throughout the campaign. So if you refer 10 friends, who each win 100 credits, you’ll get 200 bonus credits.'
            }}
          />
          <InfoCard
            tooltipInfo={{
              w: '300',
              label: 'These are friends who used your referral link or code when joining the G7 platform.'
            }}
            info={referralsCount.toString()}
            bg='transparent'
            description='Referred Friends '
          />
        </Flex>
      </Paper>
      <CreditsCard w='100%' py='xs' px='lg' from='#46340B' to='#FFA72B'>
        <Flex align='center' direction={{ xs: 'column', lg: 'row' }} pos='relative' justify='space-between'>
          <Flex align='center' gap='lg'>
            <Image visibleFrom='md' src='/images/credits/credit-referrals.png' w='100' />
            <Text maw='350'>
              Share your custom referral link and win credits each time a friend onboards to Game7 using your link.
              You’ll win a bonus equal to 20% of the credits they earn.
            </Text>
          </Flex>
          <Flex align='center' direction={{ xs: 'column', lg: 'row' }} gap='lg'>
            {viewer && (
              <Title tt='none' c='yellow.1'>
                {viewer?.referralCode}
              </Title>
            )}
            {viewer ? (
              <CopyButton value={`https://app.game7.io/?referral_code=${viewer?.referralCode}`} timeout={1000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position='bottom'>
                    <Button px='40' variant='white' c='black' onClick={copy}>
                      {copied ? 'COPIED' : 'COPY LINK'}
                    </Button>
                  </Tooltip>
                )}
              </CopyButton>
            ) : (
              <ConnectButton />
            )}
          </Flex>
        </Flex>
      </CreditsCard>
      <Stack className='h-full overflow-hidden' gap={0}>
        <Card p='lg'>
          <Flex align='center' justify='space-between' h='100%'>
            <Title data-testid='history-title-label' order={4}>
              Top Referrers
            </Title>
          </Flex>
        </Card>

        <Box className='h-[calc(100%-70px)]'>
          <ReferralsLeaderboardTable />
        </Box>
      </Stack>
    </Stack>
  )
}

export default ReferralsView
