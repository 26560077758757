import { Route, Routes } from 'react-router-dom'
import { ErrorBoundary, CategoryUrlRouterHandler } from '@components'
import AllQuestsCategoriesView from './views/AllQuestsCategories/AllQuestsCategories.view'
import QuestCategoryDetailsView from './views/QuestCategoryDetails.view'

export const QuestsViews = () => {
  return (
    <Routes>
      <Route path='/' index element={<AllQuestsCategoriesView />} errorElement={<ErrorBoundary />} />
      <Route
        path='/:categoryIdOrSlug'
        element={
          <CategoryUrlRouterHandler
            renderUrlSlugCase={(urlSlug) => {
              return <QuestCategoryDetailsView urlSlug={urlSlug} />
            }}
          />
        }
        errorElement={<ErrorBoundary />}
      />
    </Routes>
  )
}
