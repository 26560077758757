import { useEffect, useRef } from 'react'
import { CameraControls } from '@react-three/drei'
import { useCameraStorage } from '../store/camera.store'
import annotations from '../utils/annotations.json'

export const DEFAULT_CAMERA_POSITION = [0, 0, 6]
export const DEFAULT_CAMERA_TARGET = [0, 0, 0]

export const CameraManager = ({ preview }: { preview?: boolean }) => {
  const controls = useRef<CameraControls>()
  const cameraMode = useCameraStorage((state) => state.cameraMode)
  useEffect(() => {
    if (cameraMode && preview) {
      // @ts-ignore
      controls.current?.setLookAt(...annotations[cameraMode].camPos, ...annotations[cameraMode].lookAt, true)
    } else {
      // @ts-ignore
      controls.current?.setLookAt(...DEFAULT_CAMERA_POSITION, ...DEFAULT_CAMERA_TARGET, true)
    }
  }, [cameraMode, preview])

  return (
    <CameraControls
      // @ts-ignore
      ref={controls}
      minPolarAngle={Math.PI / 4}
      maxPolarAngle={Math.PI / 1.4}
      minDistance={5.5}
      maxDistance={7}
    />
  )
}
