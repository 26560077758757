import { Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from '@components'
import HistoryView from './views/History.view'

export const HistoryViews = () => {
  return (
    <Routes>
      <Route path='/' index element={<HistoryView />} errorElement={<ErrorBoundary />} />
    </Routes>
  )
}
