import { useState } from 'react'
import { Icon } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Center, ColorInput, Flex, Stack, useMantineColorScheme } from 'summon-ui/mantine'
import Avatar3D from '@modules/avatar'
import { useAvatarCapture } from '@modules/avatar/context/AvatarCaptureProvider'

const DEFAULT_BG_COLOR_DARK_MODE = '#212325'
const DEFAULT_BG_COLOR_LIGHT_MODE = '#FFF'

type AvatarPofileSettingsProps = {
  isOpen: boolean
  close: () => void
}

const AvatarEditor = ({ close }: AvatarPofileSettingsProps) => {
  const t = useTranslation()
  const { colorScheme } = useMantineColorScheme()
  const { downloadAvatar, captureAvatarFace, isSavingAvatarImage } = useAvatarCapture()
  const [background, setBackground] = useState(
    colorScheme === 'dark' ? DEFAULT_BG_COLOR_DARK_MODE : DEFAULT_BG_COLOR_LIGHT_MODE
  )

  return (
    <Stack p='lg' h='100%'>
      <Stack gap='xs' h='100%'>
        <Avatar3D
          className='border rounded-full overflow-hidden'
          background={background}
          centerProps={{ zoom: 12, position: [0, -1.2, 0] }}
        />

        <ColorInput placeholder={t('Background Color')} value={background} onChange={setBackground} format='hex' />
        <Center>
          <Button
            variant='transparent'
            c='inherit'
            rightSection={<Icon name='ArrowDown' />}
            onClick={() => downloadAvatar()}
          >
            {t('Download')}
          </Button>
        </Center>
      </Stack>
      <Flex justify='space-between'>
        <Button onClick={close} variant='outline' w={128}>
          {t('Back')}
        </Button>
        <Button loading={isSavingAvatarImage} w={128} onClick={() => captureAvatarFace(true).then(close)}>
          {t('Save')}
        </Button>
      </Flex>
    </Stack>
  )
}

export default AvatarEditor
