import type { MantineColor, TitleProps } from 'summon-ui/mantine'
import { getThemeColor, Title, useMantineTheme } from 'summon-ui/mantine'

type Props = {
  borderColor: MantineColor // A  mantine variable or a #color code,
  bordeWidth?: number
} & TitleProps

const BorderedTitle = ({ borderColor, bordeWidth = 1, ...titleProps }: Props) => {
  const theme = useMantineTheme()
  const parsedBorderColor = getThemeColor(borderColor, theme)

  return (
    <Title
      style={{
        textShadow: `-${bordeWidth}px -${bordeWidth}px 0 ${parsedBorderColor}, ${bordeWidth}px -${bordeWidth}px 0 ${parsedBorderColor}, ${bordeWidth}px ${bordeWidth}px 0 ${parsedBorderColor},
        ${bordeWidth}px ${bordeWidth}px 0 ${parsedBorderColor}`
      }}
      {...titleProps}
    />
  )
}

export default BorderedTitle
