import { useState, type ReactNode } from 'react'
import { G7Icon } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { Button, Text } from 'summon-ui/mantine'
import { PurchaseG7BoxFlow } from '../../G7BoxFlows'
import CTABox from './CTABox'
import type { SlotBoxVariantElementsComponent } from './types'

interface Props {
  slotBoxId: string
  Image: ReactNode
  price?: number
  isPlaceholder: boolean
}

const Empty = ({ slotBoxId, Image, price, isPlaceholder }: Props): SlotBoxVariantElementsComponent => {
  const t = useTranslation()
  const [isBoxPurchased, setIsBoxPurchased] = useState(false)

  const handleBoxPurchaseSuccessful = () => {
    setIsBoxPurchased(true)
  }

  return {
    TitleElement: (
      <Text fw='bold' size='xs' c='yellow.2'>
        {t('Empty slot')}
      </Text>
    ),
    BodyElement: (
      <>
        {Image}
        {!isPlaceholder && price && (
          <PurchaseG7BoxFlow
            price={price}
            slotBoxId={slotBoxId}
            onFlowSuccess={handleBoxPurchaseSuccessful}
            render={(purchase: () => void, isPurchasing: boolean, canOpen: boolean) => {
              return !isBoxPurchased ? (
                <CTABox text={t('Buy box for')} colorPalette='yellow'>
                  <Button loading={isPurchasing} disabled={!canOpen} variant='white' onClick={purchase}>
                    <FormattedMessage
                      id='diamondAndAmount'
                      values={{
                        space: <>&nbsp;</>,
                        currencyIcon: <G7Icon name='diamonds' pfx='fak' />,
                        currencyAmount: price
                      }}
                    />
                  </Button>
                </CTABox>
              ) : null
            }}
          />
        )}
      </>
    )
  }
}

export default Empty
