import { useEffect } from 'react'
import { useAccount } from 'wagmi'
import useLogout from './signing/useLogout'
import useViewer from './viewer/useViewer'

const useChangeAddressLogout = () => {
  const { viewer } = useViewer()
  const { address } = useAccount()
  const { logout } = useLogout()
  useEffect(() => {
    if (viewer?.walletAddress && address && viewer?.walletAddress != address?.toLowerCase()) {
      logout()
    }
  }, [address, viewer?.walletAddress])
}

export default useChangeAddressLogout
