import type { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { useViewer } from '@hooks'

const withProtectedRoute = (WrappedComponent: FC) => {
  const NewComponent: FC = () => {
    const { isAuthenticated } = useViewer()
    return !isAuthenticated ? <Navigate to='/' /> : <WrappedComponent />
  }

  NewComponent.displayName = 'withProtectedRoute'

  return NewComponent
}

export default withProtectedRoute
