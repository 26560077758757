import { useTranslation } from 'summon-ui/intl'
import { Title, Image, Text, Stack, Paper, Center, alpha } from 'summon-ui/mantine'
import type { DiamondsInfoData } from './types'

const InfoCard = ({ title, description, bgImgUrl }: DiamondsInfoData) => {
  const t = useTranslation()

  return (
    <>
      <Paper
        radius='md'
        className='overflow-hidden'
        style={{
          boxShadow: `0px 15px 77px 5px ${alpha('var(--mantine-color-skyblue-8)', 0.6)}`
        }}
      >
        <Paper
          w='100%'
          withBorder
          h='100%'
          style={{
            boxShadow: `inset 0 10px 25px -10px ${alpha('var(--mantine-color-skyblue-8)', 0.6)}`
          }}
        >
          <Center px='xl' py='md'>
            <Stack gap='xs' h='120px'>
              <Title ta='center' w='100%' order={4}>
                {t(title)}
              </Title>
              <Text ta='center' w='100%' size='sm'>
                {description}
              </Text>
            </Stack>
          </Center>
          <Image src={bgImgUrl} />
        </Paper>
      </Paper>
    </>
  )
}

export default InfoCard
