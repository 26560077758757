import { useTranslation } from 'summon-ui/intl'
import { Modal, Stack, Text, TextInput, Group, Button, useForm } from 'summon-ui/mantine'
import useCheckTaskCompletion from '@features/quests/hooks/useCheckTaskCompletion'

export interface ScidFormValues {
  scid?: string
}

interface Props {
  opened: boolean
  onClose: () => void
  featureId: string
}

const ActionSCIDModal = ({ opened, onClose, featureId }: Props) => {
  const t = useTranslation()
  const { updateUserPartner, isSavingPartnerUser } = useCheckTaskCompletion({})

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<ScidFormValues>({
    initialValues: { scid: '' }
  })

  const handleSubmit = async (data: typeof values) => {
    try {
      updateUserPartner(data?.scid || '', featureId)
    } catch (error) {
      console.error('ERROR Saving partner user Id: ', error)
    } finally {
      setTimeout(() => {
        onClose()
      }, 3000)
    }
  }

  return (
    <Modal title='Summon ID' opened={opened} onClose={onClose} maw={430}>
      <form onSubmit={onSubmitForm(handleSubmit)}>
        <Stack>
          <Text>{t('Enter your Summon ID to track your achievements and unlock rewards')}</Text>

          <TextInput {...getInputProps('scid')} label='ID' placeholder={t('Enter your Summon ID')} />

          <Group justify='space-between' w='100%' mt='sm'>
            <Button onClick={onClose} variant='outline' miw={115}>
              {t('Cancel')}
            </Button>
            <Button type='submit' loading={isSavingPartnerUser} disabled={isSavingPartnerUser} miw={115}>
              {t('Submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default ActionSCIDModal
