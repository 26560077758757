import contracts from './contracts.config'
import marketplace from './marketplace.config'
import onboarding from './onboarding.config'
import config from './tenant.config'

export default {
  config,
  marketplace,
  onboarding,
  contracts
}
