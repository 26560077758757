import { EnhancedPaper } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, Flex, Title } from 'summon-ui/mantine'
import LeaderboardTable from '../components/LeaderboardTable'
import styles from './leaderboard.module.css'

export const TITLE = 'General'

const LeaderboardView = () => {
  const t = useTranslation()

  return (
    <Box className='h-full overflow-hidden'>
      <EnhancedPaper withBg classNames={{ root: styles.campaignsLeaderboardDescription }} p='lg'>
        <Flex align='center' justify='space-between' h='100%'>
          <Title data-testid='history-title-label' order={4}>
            {t(TITLE)}
          </Title>
        </Flex>
      </EnhancedPaper>
      <LeaderboardTable />
    </Box>
  )
}

export default LeaderboardView
