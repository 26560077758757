import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { useQueryClient } from '@tanstack/react-query'
import { useGqlMutation } from '@api/gql/gqlServices'
import { RedeemRewardDocument, type RedeemRewardMutation } from '@generated/generates'
import { useAddOnchainHistoryLog } from '@hooks'
import useAvatarMetadata from '@hooks/inventory/useAvatarMetadata'
import { isErrorResponse } from '@utils/errors'

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong'
export const SUCCESS_CLAIM_MESSAGE = 'Rewards claimed successfully!'

interface Props {
  redeemableId: string
  handleCloseMintModal?: () => void
  isSponsored?: boolean
}

const useRedeem = ({ redeemableId, handleCloseMintModal }: Props) => {
  const t = useTranslation()
  const queryClient = useQueryClient()
  const { addOnchainHistoryLog } = useAddOnchainHistoryLog()
  const [isRedeeming, setIsRedeeming] = useState(false)
  const { refetchAvatarMetadata } = useAvatarMetadata({})

  const invalidateGetRewardsRedeemedQuery = () => queryClient.invalidateQueries({ queryKey: ['GetRewardsRedeemed'] })

  const { mutateAsync: redeemMutation, isPending: isRedeemMutationLoading } = useGqlMutation<RedeemRewardMutation>(
    RedeemRewardDocument,
    undefined, // No variables are required for this mutation
    {
      onSuccess: () => {
        refetchAvatarMetadata()
      }
    }
  )
  const redeem = async () => {
    try {
      setIsRedeeming(true)
      const response = await redeemMutation({ input: { rewardsRedeemedId: redeemableId } })
      const mintReceipt = response?.redeemReward?.mintReceipt

      if (mintReceipt) {
        await addOnchainHistoryLog({
          txAddress: mintReceipt?.tx,
          contractAddress: mintReceipt?.contractAddress,
          description: t('Reward Claimed'),
          explorerLink: mintReceipt?.explorerLink
        })
        showNotification({
          variant: 'success',
          message: SUCCESS_CLAIM_MESSAGE
        })
      }
    } catch (error: unknown) {
      let errorMessage = DEFAULT_ERROR_MESSAGE
      if (isErrorResponse(error)) {
        {
          errorMessage = error.response?.errors?.[0]?.message || t(DEFAULT_ERROR_MESSAGE)
        }
        showNotification({
          variant: 'danger',
          message: errorMessage
        })
      }
    } finally {
      setIsRedeeming(false)
      handleCloseMintModal && handleCloseMintModal()
      invalidateGetRewardsRedeemedQuery()
    }
  }

  return {
    redeemMutation,
    isRedeemMutationLoading,
    redeem,
    isRedeeming
  }
}

export default useRedeem
