import { Outlet } from 'react-router-dom'
import { DashboardLayout as SummonUiDashboardLayout } from 'summon-ui/g7'
import { Box, Center, Stack, useDisclosure } from 'summon-ui/mantine'
import { useAccount } from 'wagmi'
import { ConnectButton, ProfileMenu } from '@components'
import { useViewer } from '@hooks'
import BottomNavigationMenu from './BottomNavigationMenu'
import NavigationMenu from './NavigationMenu'
import UserSection from './UserSection'
import { DEFAULT_AVATAR_IMAGE } from './constants'
import useErrorCode from './useErrorCode'
import useGetLayoutHeaderConf from './useGetLayoutHeaderConf'
import useStartTutorial from './useStartTutorial'

const DashboardLayout = () => {
  useErrorCode()

  const { address } = useAccount()
  const { viewer, isAuthenticated } = useViewer()
  const layoutHeaderConf = useGetLayoutHeaderConf()
  const [isProfileMenuOpen, { toggle: toggleProfileMenu }] = useDisclosure(false)
  useStartTutorial(viewer?.hasCompletedTutorial, viewer?.isOnboardingCompleted)

  const accountName = viewer?.username || (viewer?.firstName ? viewer?.firstName + ' ' + viewer?.lastName : '')

  const renderNavigationMenu = ({
    isMinimal,
    closeMobileMenu
  }: {
    isMinimal: boolean
    closeMobileMenu: () => void
  }) => (
    <Stack gap='sm' align='center' w='100%' onClick={closeMobileMenu}>
      <NavigationMenu isSmall={isMinimal} />
    </Stack>
  )

  const renderBottomMenu = ({ isMinimal, closeMobileMenu }: { isMinimal: boolean; closeMobileMenu: () => void }) => {
    return isAuthenticated ? (
      <>
        <ProfileMenu isOpen={isProfileMenuOpen} close={toggleProfileMenu} />
        <Stack gap='xl' align='center' w='100%'>
          <Center onClick={closeMobileMenu} w='100%'>
            <BottomNavigationMenu isSmall={isMinimal} />
          </Center>
          <UserSection
            toggleProfileMenu={toggleProfileMenu}
            isSmall={isMinimal}
            userName={accountName}
            userAvatarUrl={viewer?.avatarUrl || DEFAULT_AVATAR_IMAGE}
            userWalletAddress={address as string}
          />
        </Stack>
      </>
    ) : (
      <Center>
        <ConnectButton isMinimal={isMinimal} isXL={false} />
      </Center>
    )
  }
  return (
    <Box h='100%' mih='100vh' w='100%' className='overflow-y-scroll'>
      <SummonUiDashboardLayout
        renderNavigationMenu={renderNavigationMenu}
        renderBottomMenu={renderBottomMenu}
        appVersion={APP_VERSION}
        render={({ offset }) => <Outlet context={{ offset, isAuthenticated }} />}
        layoutHeaderConf={layoutHeaderConf}
      />
    </Box>
  )
}

export default DashboardLayout
