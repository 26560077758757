import { useEffect } from 'react'
import { Spinner } from 'summon-ui'
import { Box, Carousel, Center, Flex, ScrollArea, Tabs } from 'summon-ui/mantine'
import InventorySlot from '@components/Inventory/InventorySlot'
import { SlotType } from '@config/enum'
import useItems, { ItemTypes } from '@hooks/inventory/useItems'
import { usePreviewItems } from '@modules/avatar/store/usePreviewItems.store'
import { useOnboardingStorage } from '../onboarding.store'

type OnboardingInventoryProps = {
  isDna?: boolean
}

const TRAITS_SLOTS = [SlotType.Eyes, SlotType.Eyebrows, SlotType.Hair, SlotType.FacialHair]
const OnboardingInventory = ({ isDna = false }: OnboardingInventoryProps) => {
  const { groupedItems, items, isLoading } = useItems({
    itemsType: isDna ? ItemTypes.DNA : ItemTypes.Traits,
    preload: isDna
  })
  const { setSelectedOnboardingSkin, selectedOnboardingSkin } = useOnboardingStorage()
  const { addOrRemovePreviewItem, previewItems } = usePreviewItems()
  useEffect(() => {
    if (items.length && isDna) setSelectedOnboardingSkin(items[0] as any)
  }, [items])

  if (isLoading) {
    return (
      <Center mt='lg'>
        <Spinner />
      </Center>
    )
  }
  return (
    <Box mt='md'>
      {isDna ? (
        <ScrollArea h={{ xs: 'auto', sm: '60vh' }}>
          <Flex wrap='wrap' gap='sm'>
            {items?.map((item) => (
              <InventorySlot
                key={item.tokenId}
                isLoading={isLoading}
                isActive={selectedOnboardingSkin?.tokenId === item.tokenId}
                image={item.imageUrl}
                onClick={() => setSelectedOnboardingSkin(item as any)}
              />
            ))}
          </Flex>
        </ScrollArea>
      ) : (
        <Tabs keepMounted={false} defaultValue={String(SlotType.Eyes)}>
          <Tabs.List my='md'>
            {TRAITS_SLOTS.map((key) => (
              <Tabs.Tab value={String(key)} key={key}>
                {SlotType[key]}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {Object.entries(groupedItems).map(([key, value]) => (
            <Tabs.Panel value={key} key={key}>
              <Carousel
                hiddenFrom='md'
                withControls={false}
                dragFree
                maw='100vw'
                slideSize={{ xs: '33%', sm: '20%' }}
                slideGap='xs'
                slidesToScroll='auto'
              >
                {value?.map((item) => (
                  <Carousel.Slide key={item.tokenId}>
                    <InventorySlot
                      isLoading={isLoading}
                      isActive={!!previewItems.find((previewItem) => previewItem.tokenId === item.tokenId)}
                      image={item.imageUrl}
                      onClick={() => addOrRemovePreviewItem(item)}
                    />
                  </Carousel.Slide>
                ))}
              </Carousel>
              <ScrollArea visibleFrom='md' maw='100vw' h={{ xs: '100px', md: '60vh' }}>
                <Flex wrap='wrap' gap='sm'>
                  {value?.map((item) => (
                    <InventorySlot
                      key={item.tokenId}
                      isLoading={isLoading}
                      isActive={!!previewItems.find((previewItem) => previewItem.tokenId === item.tokenId)}
                      image={item.imageUrl}
                      onClick={() => addOrRemovePreviewItem(item)}
                    />
                  ))}
                </Flex>
              </ScrollArea>
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </Box>
  )
}

export default OnboardingInventory
