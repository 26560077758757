import { Suspense } from 'react'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import { useViewer } from '@hooks'
import AvatarLoader from './AvatarLoader'
import Mesh from './Mesh'

const BaseAvatar = () => {
  const { selectedOnboardingSkin } = useOnboardingStorage()
  const { viewer } = useViewer()
  const skin = viewer?.skinId || selectedOnboardingSkin?.glbUrl

  if (!skin) {
    return <AvatarLoader />
  }
  return (
    <Suspense fallback={<AvatarLoader />}>
      <Mesh uri={skin} renderOrder={0} />
    </Suspense>
  )
}

export default BaseAvatar
