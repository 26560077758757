import { useState, useEffect } from 'react'
import { useDebouncedState } from 'summon-ui/mantine'
import { useGqlQuery } from '@api/gql/gqlServices'
import { GetLineaGuildLeaderboardDocument, type GetLineaGuildLeaderboardQuery } from '@generated/generates'
import type { LineaGuildLeaderboardUser } from '../types'
import { buildUpLineaGuildLeaderboardTableData } from './helpers'

const useLineaGuildLeaderboard = (pageSize: number) => {
  const [currentPage, setCurrentPage] = useDebouncedState(1, 200)
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0)
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0)
  const { data, isLoading, status, refetch } = useGqlQuery<GetLineaGuildLeaderboardQuery>(
    GetLineaGuildLeaderboardDocument,
    {
      limit: pageSize,
      offset: (currentPage - 1) * pageSize
    }
  )

  useEffect(() => {
    if (data?.lineaGuildLeaderboardCount) {
      setTotalUsersCount(data.lineaGuildLeaderboardCount)
      setTotalPagesCount(Math.ceil(data.lineaGuildLeaderboardCount / pageSize))
    }
  }, [data, pageSize])

  const handleRetrievePage = (page: number) => {
    setCurrentPage(page)
  }

  return {
    isLoading,
    status,
    refetch,
    leaderboard:
      status === 'success'
        ? buildUpLineaGuildLeaderboardTableData(data?.lineaGuildLeaderboard as LineaGuildLeaderboardUser[])
        : [],
    currentPage,
    navigateToPage: handleRetrievePage,
    totalPagesCount,
    totalUsersCount,
    isUserInLeaderboard: false
  }
}

export default useLineaGuildLeaderboard
