import { CartProvider } from '@features/marketplace/hooks/useCart'

export const withCart = (WrappedComponent: React.ComponentType) =>
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  (function withCart(props: any) {
    return (
      <CartProvider>
        <WrappedComponent {...props} />
      </CartProvider>
    )
  })
