import type { ReactNode } from 'react'
import { Icon } from 'summon-ui'
import {
  alpha,
  Box,
  Center,
  getThemeColor,
  Paper,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme
} from 'summon-ui/mantine'
import type { MantineColor, PaperProps } from 'summon-ui/mantine'

interface TootipInfo {
  label: string
  w?: string | number
}
type InfoCardProps = {
  info: string | number | ReactNode
  description: string
  tooltipInfo?: TootipInfo
  color?: MantineColor
  size?: 'small' | 'medium' | 'large'
} & PaperProps

const InfoCard = ({ info, description, color, tooltipInfo, size = 'medium', ...props }: InfoCardProps) => {
  const paddingY = {
    small: '4',
    medium: 'lg',
    large: 'xl'
  }[size]
  const theme = useMantineTheme()
  const titleColor = color ? getThemeColor(color, theme) : theme.colors.yellow[5]
  const borderColor = alpha(titleColor, 0.6)
  const backgroundColor = alpha(titleColor, 0.1)
  const textColor = 'yellow.1'
  return (
    <Paper
      withBorder
      style={{
        backgroundColor: backgroundColor,
        border: `1px solid ${borderColor}`
      }}
      className='text-center'
      px='xs'
      py={paddingY}
      w='100%'
      radius='md'
      {...props}
    >
      <Stack h='100%' justify='center' gap={0}>
        {typeof info === 'string' || typeof info === 'number' ? (
          <Title order={3} c={titleColor}>
            {info}
          </Title>
        ) : (
          info
        )}
        <Center h='24px'>
          <Text size='xs' c={textColor} pl={tooltipInfo ? '6' : '0'}>
            {description}
          </Text>
          {tooltipInfo && (
            <Tooltip multiline withArrow label={tooltipInfo.label} w={tooltipInfo.w}>
              <Box>
                <Icon name='InfoCircle' c={textColor} />
              </Box>
            </Tooltip>
          )}
        </Center>
      </Stack>
    </Paper>
  )
}

export default InfoCard
