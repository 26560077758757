import { useEffect, useRef, useState } from 'react'
import { Icon, useAssetsContext } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { BackgroundImage, Button, Divider, Group, Paper, Stack, Tabs, Text, Title } from 'summon-ui/mantine'
import { G7LevelUpData } from '@features/home/components/levelUp/data'
import useMintLevelUp from '@features/home/hooks/useMintLevelUp'

enum OnchainLevelTabsOptions {
  Chronicles = 'Chronicles',
  Rewards = 'Rewards'
}

type LevelUpModalProps = {
  open: boolean
  close: () => void
  currentLevel: number
}

const LevelUpModal = ({ currentLevel, close }: LevelUpModalProps) => {
  const t = useTranslation()
  const { getTenantImage } = useAssetsContext()
  const levelCardImage = getTenantImage(`level-${currentLevel + 1}-card`)
  const [step, setStep] = useState(OnchainLevelTabsOptions.Chronicles)
  const { mint, isMinting, disabled: isMintDisabled } = useMintLevelUp()
  const levelData = G7LevelUpData(Number(currentLevel))

  const audio = useRef<HTMLAudioElement>()
  // start the audio (using the .current property of the ref we just created) when the component mounts using the useEffect hook
  useEffect(() => {
    audio.current = new Audio(levelData?.audio)
    audio.current.volume = 0.2
    return () => {
      audio.current?.pause()
    }
  }, [])

  const closeLevelModal = () => {
    setStep(OnchainLevelTabsOptions.Chronicles)
    close()
  }

  const claim = async () => {
    await mint(
      {},
      {
        onSettled: closeLevelModal
      }
    )
  }

  const TabsList = () => (
    <Tabs.List grow mb='md' bg='gray.8'>
      {Object.values(OnchainLevelTabsOptions).map((tab) => (
        <Tabs.Tab key={tab} value={tab}>
          {tab}
        </Tabs.Tab>
      ))}
    </Tabs.List>
  )

  return (
    <>
      <Stack p='md' gap='sm'>
        <div>
          <Title order={3}>{levelData?.header}</Title>
          <Text>{levelData?.subheader}</Text>
        </div>

        <Tabs
          value={step}
          onChange={(value) => setStep(value as OnchainLevelTabsOptions)}
          defaultValue={OnchainLevelTabsOptions.Chronicles}
          keepMounted={false}
          mt='md'
        >
          <TabsList />
          <Tabs.Panel value={OnchainLevelTabsOptions.Chronicles}>
            <BackgroundImage
              src={levelCardImage as string}
              radius='md'
              h={310}
              onClick={() => (audio.current?.paused ? audio.current?.play() : audio.current?.pause())}
              className='cursor-pointer'
            >
              {audio.current?.paused && <Icon name='PlayCircle' variant='light' size='xl' c='white' />}
            </BackgroundImage>

            <Title order={4} size='sm' mt='md'>
              {levelData?.description}
            </Title>
          </Tabs.Panel>
          <Tabs.Panel value={OnchainLevelTabsOptions.Rewards}>
            <BackgroundImage
              src={levelData?.levelCollection as string}
              radius='md'
              h={310}
              onClick={() => (audio.current?.paused ? audio.current?.play() : audio.current?.pause())}
            />
            <Title order={4} my='xs'>
              {t('Items Earned')}{' '}
            </Title>
            <Group gap='xs'>
              {[...Array(1).keys()].map((item) => (
                <Paper key={item} withBorder radius='md' maw={150} p='xs'>
                  <img className='h-full opacity-50' src={levelData?.levelCollectionItems[item]} alt='Mystery Box' />
                  <Text size='xs' fw={500} mt='xs' ta='center'>
                    {levelData?.levelCollectionName}
                  </Text>
                </Paper>
              ))}
            </Group>
          </Tabs.Panel>
        </Tabs>
      </Stack>

      <Divider />

      <Group justify='space-between' w='100%' p='sm'>
        <Button variant='outline' miw={100} onClick={closeLevelModal}>
          {t('Cancel')}
        </Button>
        {step === OnchainLevelTabsOptions.Rewards ? (
          <Group>
            <Group gap='sm' wrap='nowrap'>
              <Button miw={100} disabled={isMintDisabled} onClick={claim} loading={isMinting}>
                {t('Claim')}
              </Button>
            </Group>
          </Group>
        ) : (
          <Button
            miw={100}
            onClick={() => {
              setStep(OnchainLevelTabsOptions.Rewards)
            }}
          >
            {t('Proceed')}
          </Button>
        )}
      </Group>
    </>
  )
}

export default LevelUpModal
