import { TENANT_CONFIG } from '@config'
import type { InventoryItem } from '@generated/generates'

// Function to filter incompatible items
export const filterIncompatibleItems = (items: InventoryItem[]): InventoryItem[] => {
  const filteredItems: InventoryItem[] = []

  items.forEach((item) => {
    const isIncompatible = TENANT_CONFIG.incompatibleSlots?.some(([a, b]) =>
      filteredItems.some(
        (filteredItem) =>
          (item.slotType === a && filteredItem.slotType === b) || (item.slotType === b && filteredItem.slotType === a)
      )
    )
    if (isIncompatible) {
      // If an incompatible equipped item exists, replace it with the non-equipped one
      const incompatibleIndex = filteredItems.findIndex((filteredItem) =>
        TENANT_CONFIG.incompatibleSlots?.some(
          ([a, b]) =>
            (item.slotType === a && filteredItem.slotType === b) || (item.slotType === b && filteredItem.slotType === a)
        )
      )

      const incompatibleItem = filteredItems[incompatibleIndex]

      // If the current item is not a "dna" item, replace the incompatible "dna" item
      if (incompatibleItem.itemType === 'dna' && item.itemType !== 'dna') {
        filteredItems.splice(incompatibleIndex, 1, item)
      }
    } else {
      // If there's no incompatibility, just add the item
      filteredItems.push(item)
    }
  })

  return filteredItems
}
