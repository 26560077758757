import clsx from 'clsx'
import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import type { MantineSize } from 'summon-ui/mantine'
import { Avatar, Box, Card, Center, Divider, Flex, Image, Paper, Stack, Text, Title } from 'summon-ui/mantine'
import styles from './stakeInfoStepper.module.css'

export type Step = {
  title: string
  description: string
  image: string
}

type StakeInfoStepperProps = {
  steps: Step[]
}

const StakeInfoStepper = ({ steps }: StakeInfoStepperProps) => {
  const t = useTranslation()
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'mini' })

  const renderStepCircle = (size: MantineSize, content: number) => (
    <Paper pos='relative' bg='transparent' radius='100%' className='z-10'>
      <Center w='100%' h='100%' className={clsx('rounded-full', styles.stepGlowEffect)} pos='absolute'>
        <Title ta='center' order={4}>
          {content}
        </Title>
      </Center>
      <Avatar variant='transparent' src='/images/staking/stepper/step-icon.svg' className='-z-10' size={size} />
    </Paper>
  )

  const renderMobileStepper = () => {
    return (
      <>
        {steps.map((step, index) => (
          <Stack key={index} ta='center' align='center' gap='xs'>
            <Box>{renderStepCircle('lg', index + 1)}</Box>
            <Image mx='auto' className='size-[165px]' src={step.image} />
            <Box>
              <Title order={4}>{t(step.title)}</Title>
              <Text size='sm' mih='80'>
                {t(step.description)}
              </Text>
            </Box>
          </Stack>
        ))}
      </>
    )
  }

  const renderStepper = () => {
    return (
      <Stack w='100%' gap='md'>
        <Flex>
          {steps.map((step, index) => (
            <Image key={index} mx='auto' className='size-[165px]' src={step.image} />
          ))}
        </Flex>
        <Flex w='100%'>
          {steps.map((step, index) => (
            <Flex key={index} w='100%' justify='center' align='center'>
              <Divider size={4} w='100%' className={clsx(styles.dividerGlowEffect, { invisible: index === 0 })} />
              {renderStepCircle('lg', index + 1)}
              <Divider
                size={4}
                w='100%'
                className={clsx(styles.dividerGlowEffect, { invisible: index === steps.length - 1 })}
              />
            </Flex>
          ))}
        </Flex>
        <Flex w='100%'>
          {steps.map((step, index) => (
            <Box key={index} ta='center' w='100%' p='xs'>
              <Title order={4}>{t(step.title)}</Title>
              <Text size='sm'>{t(step.description)}</Text>
            </Box>
          ))}
        </Flex>
      </Stack>
    )
  }

  return (
    <Card withBorder radius='md' bg='black' p='md'>
      <Flex direction={{ xs: 'column', md: 'row' }} justify='center' align='center' gap='xl'>
        {isMobile ? renderMobileStepper() : renderStepper()}
      </Flex>
    </Card>
  )
}

export default StakeInfoStepper
