import { useNavigate } from 'react-router-dom'
import { G7Icon, Spinner, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center, Flex, NumberFormatter, Stack, Text, Title } from 'summon-ui/mantine'
import { BorderedTitle, ConnectButton, InfoCard } from '@components'
import { useViewer } from '@hooks'
import {
  useUserCreditsBalance,
  useUserTokensBalance,
  useUserDiamondsBalance,
  useUserStakedTokens,
  useCreditsConversion
} from '@hooks/balances'
import LockupBanner from '../components/LockupBanner'
import StakeInfoStepper from '../components/StakeInfoStepper/StakeInfoStepper'
import StakingPositions from '../components/StakingPositions'
import UserAvailableCtas from '../components/UserAvailableCtas'

const STEPS = [
  {
    title: 'Convert Credits',
    image: '/images/staking/stepper/g7-cards.png',
    description: 'Your credits will convert directly to G7 tokens on the new G7 chain.'
  },
  {
    title: 'Lockup or Stake',
    image: '/images/staking/stepper/red-g7-coin.png',
    description:
      'You can either stake your tokens for minimal Diamond rewards or lock them up for 12 or 24 months for big multipliers on your Diamonds.'
  },
  {
    title: 'Earn Diamonds',
    image: '/images/staking/stepper/blue-g7-diamond.png',
    description:
      'Diamonds are a new in-app currency that will allow you to buy Loot Boxes filled with prizes worth over $1 million in aggregate.'
  }
]

const StakingView = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const { viewer } = useViewer()
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const { balance: userStakedTokens, isLoading: isLoadingUserStakedTokens } = useUserStakedTokens()
  const { balance: userCreditsBalance, isLoading: isLoadingUserCreditsBalance } = useUserCreditsBalance()
  const { balance: userDiamondsBalance, isLoading: isLoadingUserDiamondsBalance } = useUserDiamondsBalance()
  const { balance: userTokensBalance, isLoading: isLoadingUserTokensBalance } = useUserTokensBalance()
  const { canClaimTokens } = useCreditsConversion()

  const handleGoToOptions = () => {
    navigate('/staking/options', { replace: true })
  }

  const renderCtas = () => (
    <>{!isLoadingUserTokensBalance && <UserAvailableCtas userTokensBalance={userTokensBalance} />}</>
  )

  return (
    <>
      <BorderedTitle fz={{ xs: 'h1', md: 71, lg: 100.5 }} tt='uppercase' bordeWidth={2} borderColor='brand.6'>
        {t('Lockup & Staking')}
      </BorderedTitle>
      <Text maw='640'>
        {t(
          'Stake or lockup your tokens to earn Daily Diamond rewards. The more Diamonds you earn, the more G7 Boxes and rewards you can win.'
        )}
      </Text>
      <Stack gap='lg' mt='lg'>
        <Flex>
          <Center>{viewer ? renderCtas() : <ConnectButton isMinimal={false} isXL={false} />}</Center>
        </Flex>
        <Flex gap='lg' direction={isMobile ? 'column' : 'row'}>
          {viewer && canClaimTokens ? (
            <InfoCard
              info={
                isLoadingUserCreditsBalance ? (
                  <Spinner size='xs' />
                ) : (
                  <Flex gap='xs' w='100%' justify='center'>
                    <G7Icon name='g7-credits' pfx='fak' color='yellow.3' size='2x' />
                    <Title order={3} c='yellow.3'>
                      <NumberFormatter thousandSeparator value={userCreditsBalance} />
                    </Title>
                  </Flex>
                )
              }
              description={t('Available Credits')}
            />
          ) : !viewer ? (
            <InfoCard
              info={
                <Flex gap='xs' w='100%' justify='center'>
                  <G7Icon name='g7-credits' pfx='fak' color='yellow.3' size='2x' />
                  <Title order={3} c='yellow.3'>
                    <NumberFormatter thousandSeparator value={0} />
                  </Title>
                </Flex>
              }
              description={t('Available Credits')}
            />
          ) : null}
          <InfoCard
            color='red.5'
            info={
              isLoadingUserStakedTokens ? (
                <Spinner size='xs' />
              ) : (
                <Flex gap='xs' w='100%' justify='center'>
                  <G7Icon name='g7-token-mark' pfx='fak' color='red.5' size='2x' />
                  <Title order={3} c='red.5'>
                    <NumberFormatter thousandSeparator value={viewer ? userStakedTokens : 0} />
                  </Title>
                </Flex>
              )
            }
            description={t('My Staked Tokens')}
          />
          <InfoCard
            color='blue.6'
            info={
              isLoadingUserDiamondsBalance ? (
                <Spinner size='xs' />
              ) : (
                <Flex gap='xs' w='100%' justify='center'>
                  <G7Icon name='diamonds' pfx='fak' color='skyblue.7' size='2x' />
                  <Title order={3} c='skyblue.7'>
                    <NumberFormatter thousandSeparator value={viewer ? userDiamondsBalance : 0} />
                  </Title>
                </Flex>
              )
            }
            description={t('My Diamonds Accumulated')}
          />
        </Flex>
        <StakingPositions />
        <LockupBanner onClick={handleGoToOptions} />
        <StakeInfoStepper steps={STEPS} />
      </Stack>
    </>
  )
}
export default StakingView
