import { useGqlQuery } from '@api/gql/gqlServices'
import { GetCreditsStatsDocument, type GetCreditsStatsQuery } from '@generated/generates'
import { useViewer } from '@hooks'

const useCreditsStats = () => {
  const { viewer } = useViewer()

  const { data: creditsStats, isPending } = useGqlQuery<GetCreditsStatsQuery>(
    GetCreditsStatsDocument,
    {},
    {
      enabled: !!viewer
    }
  )
  return {
    creditsStats: creditsStats?.getCreditsStats,
    creditsStatsLoading: !viewer ? false : isPending
  }
}

export default useCreditsStats
