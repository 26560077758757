import { useTranslation } from 'summon-ui/intl'
import { Modal, Stack, Text, TextInput, Group, Button, useForm } from 'summon-ui/mantine'
import useCheckTaskCompletion from '@features/quests/hooks/useCheckTaskCompletion'
import { useViewer } from '@hooks'
import { validateEmail } from '@utils/validators'

export interface EmailFormValues {
  emailAddress?: string
}

interface Props {
  opened: boolean
  onClose: () => void
}

const ActionEmailModal = ({ opened, onClose }: Props) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const { updateUserSettings, isUpdatingUserSettings } = useCheckTaskCompletion({})

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<EmailFormValues>({
    initialValues: { emailAddress: viewer?.emailAddress || '' },
    validate: {
      emailAddress: validateEmail
    }
  })

  const handleSubmit = async (data: typeof values) => {
    try {
      updateUserSettings({ emailAddress: data?.emailAddress || '' })
    } finally {
      setTimeout(() => {
        onClose()
      }, 3000)
    }
  }

  return (
    <Modal title='Enter Email' opened={opened} onClose={onClose} maw={430}>
      <form onSubmit={onSubmitForm(handleSubmit)}>
        <Stack>
          <Text>{t('Sync your email to enhance your quest experience and unlock exclusive rewards')}</Text>
          <TextInput {...getInputProps('emailAddress')} label='Email' placeholder='Enter your email' type='email' />
          <Group justify='space-between' w='100%' mt='sm'>
            <Button onClick={onClose} variant='outline' miw={115}>
              {t('Cancel')}
            </Button>
            <Button type='submit' loading={isUpdatingUserSettings} disabled={isUpdatingUserSettings} miw={115}>
              {t('Submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default ActionEmailModal
