import { useMutation } from '@tanstack/react-query'
import { shopifyClient } from '@api/modules/shopify'
import type { UpdateLine } from '@api/modules/shopify/types'

async function updateCart({ cartId, lines }: { cartId: string; lines: UpdateLine[] }) {
  const data = await shopifyClient({
    query: `
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            lines(first: 100) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      product {
                        title
                      }
                    }
                  }
                }
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `,
    variables: { cartId, lines }
  })

  return data.cartLinesUpdate
}

export const useUpdateCartMutation = () => {
  return useMutation({
    mutationKey: ['updateCart'],
    mutationFn: (args: { cartId: string; lines: UpdateLine[] }) => updateCart(args)
  })
}
