import { Image } from 'summon-ui/mantine'
import type { BoxSlot as SlotBoxType, BoxDetails, Maybe } from '@generated/generates'
import type { SlotBoxUIDisplayVariant } from '../../types'
import ComingSoon from './variants/ComingSoon'
import Empty from './variants/Empty'
import Ready from './variants/Ready'
import Unlocking from './variants/Unlocking'
import type { SlotBoxVariantElementsComponent } from './variants/types'

const G7_BOX_WIDTH = 170
const G7_BOX_WIDTH_SMALL = 120

const getSlotBoxUIDisplayVariant = (slotBox: SlotBoxType) => {
  const { boxDetails } = slotBox
  return !boxDetails
    ? 'empty'
    : boxDetails && boxDetails.unlockAt && new Date(boxDetails.unlockAt) < new Date()
      ? 'ready'
      : 'unlocking'
}

const getVariantColor = (variant: SlotBoxUIDisplayVariant) => {
  switch (variant) {
    case 'empty':
      return 'yellow'
    case 'ready':
      return 'green'
    case 'unlocking':
      return 'skyblue'
    default: //coming-soon
      return 'dark'
  }
}

type GetElementsProps = {
  displayVariant: SlotBoxUIDisplayVariant
  slotBox: Maybe<SlotBoxType>
  onUnlockAtOver?: () => void
  isPlaceholder: boolean
  isComingSoon?: boolean
  isSmall: boolean
}

const getElementsSlotBoxVariantRenderElements = ({
  displayVariant,
  slotBox,
  onUnlockAtOver,
  isPlaceholder,
  isComingSoon,
  isSmall
}: GetElementsProps): SlotBoxVariantElementsComponent => {
  const renderSlotBoxImage = () => (
    <Image w={isSmall ? G7_BOX_WIDTH_SMALL : G7_BOX_WIDTH} src='/images/diamonds/box.png' />
  )
  const renderEmptyElements = (id: string, price?: number): SlotBoxVariantElementsComponent => {
    return Empty({
      slotBoxId: id,
      Image: renderSlotBoxImage(),
      price,
      isPlaceholder
    })
  }

  const renderUnlockingElements = (
    boxId: string,
    boxDetails: BoxDetails,
    onUnlockAtOver: () => void,
    price?: number
  ) => {
    return Unlocking({
      boxId,
      Image: renderSlotBoxImage(),
      boxDetails,
      boxPrice: price,
      isPlaceholder,
      onUnlockAtOver
    })
  }

  const renderReadyElements = (boxId: string) => {
    return Ready({ boxId, Image: renderSlotBoxImage() })
  }

  if (isComingSoon || !slotBox) {
    return ComingSoon()
  }

  switch (displayVariant) {
    case 'ready':
      if (slotBox.boxDetails) {
        return renderReadyElements(slotBox.boxDetails.id)
      }
      return ComingSoon()

    case 'empty':
      return renderEmptyElements(slotBox.id, slotBox.boxPrice || undefined)

    case 'unlocking':
      if (slotBox.boxDetails && onUnlockAtOver) {
        return renderUnlockingElements(
          slotBox.boxDetails.id,
          slotBox.boxDetails,
          onUnlockAtOver,
          slotBox.boxPrice || undefined
        )
      }
      return ComingSoon()

    default:
      return ComingSoon()
  }
}

export { getSlotBoxUIDisplayVariant, getVariantColor, getElementsSlotBoxVariantRenderElements }
